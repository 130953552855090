import { IS_DEV, IS_PROD } from 'src/app/apiexec/utils'

//set this to true if you want to specifcally test some alerting logic i..e how the final alert shows up in slack/newrelic
//can be nosy
const SEND_DEV_ALERTS_TO_INT = false

export type EventSubjectName =
  | 'app_error_boundary'
  | 'search_results_data'
  | 'saving_report_data'
  | 'coupons'
  | 'pharmacy'
  | 'search_results_ui'
  | 'bookmarks'
  | 'registration'
  | 'preregistration'
  | 'generic_error'
  | 'dependents'
  | 'sso'
  | 'login'
  | 'drug_profile'
  | 'provider_portal'
  | 'my_meds'
  | 'translations'
  | 'policy_and_terms'
  | 'salesiq'
  | 'mobile_force_upgrade'

export interface CustomUiEvent {
  subject: EventSubjectName
  message: string
  memberId: number
  clientId: number
  additionalData?: string
  scriptaErrorCode?: number
  scriptaCause?: string
  scriptaCorrelationId?: string
}

//this is for lambda - it adds level to the payload
interface CustomUiEventWithLevel extends CustomUiEvent {
  level: 'ERROR' | 'WARN' //only these two for now in alerts. NOTE: info is not showing up in new relic? todo...
}

let INT_URL =
  'https://ii7ljcpp2rczd3efzrbqdsv5fq0nxwlt.lambda-url.us-east-1.on.aws/'
//for now same as int
let PROD_URL =
  'https://vkluc2xmplcjivxzwji4qawi2a0gadps.lambda-url.us-east-1.on.aws/'

export class LOG {
  public static initMember(memberId: number, tenantId: number) {
    try {
      ;(window as any).scripta_logger_member_id = memberId
      ;(window as any).scripta_logger_tenant_id = tenantId
    } catch (e) {
      console.error('Failed to set member id for logging')
    }
  }

  public static warn(eventSubject: EventSubjectName, msg: string, data?: any) {
    //wrap in try catch just in case we dont want this to break anything else
    try {
      this.sendAlert('WARN', eventSubject, msg, data)
    } catch (e) {
      console.error('Failed to send member app alert log', e)
    }
  }

  public static error(eventSubject: EventSubjectName, msg: string, data?: any) {
    try {
      this.sendAlert('ERROR', eventSubject, msg, data)
    } catch (e) {
      console.error('Failed to send member app alert log', e)
    }
  }

  private static async sendAlert(
    level: 'ERROR' | 'WARN',
    eventSubject: EventSubjectName,
    message: string,
    data?: any,
  ) {
    //disable alerts if inisde CI for now
    // if (process.env.CI) {
    //   console.log('CI - skipping alert', eventSubject, message)
    //   return
    // }
    //this is some util code - bc we often want to pass existing response object as additional data
    //i..e the response object from the api call that failed
    //sometimes that needs to be await json()ed first
    try {
      let scriptaErrorCode, scriptaCause, scriptaCorrelationId

      //this is some util code - bc we often want to pass existing response object as additional data
      //i..e the response object from the api call that failed
      //sometimes that needs to be await json()ed first
      try {
        let resolvedData = data
        if (data instanceof Response) {
          try {
            // Clone the response before attempting to read its body
            const clonedResponse = data.clone()
            resolvedData = await clonedResponse.json()
          } catch (jsonError) {
            // If JSON parsing fails, try to get the response text
            try {
              resolvedData = await data.text()
            } catch (textError) {
              console.warn('Failed to get response text', textError)
              resolvedData = 'Unable to read response body'
            }
          }
        } else if (
          data &&
          typeof data === 'object' &&
          'json' in data &&
          typeof data.json === 'function'
        ) {
          // Handle other objects with a json method (e.g., fetch-mock responses)
          try {
            resolvedData = await data.json()
          } catch (jsonError) {
            console.warn('Failed to parse object as JSON', jsonError)
          }
        }

        // Extract specific fields if they exist
        if (resolvedData && typeof resolvedData === 'object') {
          if (
            'scriptaErrorCode' in resolvedData &&
            resolvedData.scriptaErrorCode !== undefined
          ) {
            scriptaErrorCode = resolvedData.scriptaErrorCode
          }
          if ('cause' in resolvedData && resolvedData.cause !== undefined) {
            scriptaCause = resolvedData.cause
          }
          if (
            'correlationId' in resolvedData &&
            resolvedData.correlationId !== undefined
          ) {
            scriptaCorrelationId = resolvedData.correlationId
          }
        }

        let payload: CustomUiEventWithLevel = {
          memberId: (window as any).scripta_logger_member_id,
          clientId: (window as any).scripta_logger_tenant_id,
          subject: eventSubject,
          message: safeText(message),
          level: level,
          //do not send these if undefined
          ...(resolvedData && {
            additionalData: safeStringify(resolvedData),
          }),
          ...(scriptaErrorCode && { scriptaErrorCode }),
          ...(scriptaCause && { scriptaCause }),
          ...(scriptaCorrelationId && { scriptaCorrelationId }),
        }

        let url = INT_URL
        if (IS_PROD()) {
          url = PROD_URL
        }

        //reduce production noise for translations
        if (eventSubject === 'translations') {
          if (IS_PROD()) {
            payload.message = '(PROD translation)' + payload.message
            url = INT_URL
          }
        }

        //check if in dev and we should pipe to integration
        if (IS_DEV()) {
          if (!SEND_DEV_ALERTS_TO_INT) {
            console.log(
              '-------DEV environment and SENT_DEV_ALERTS_TO_INT is false - will just console.log error. Update the applog.ts if you wanna test some alert logic being pushed ',
            )
            console.log('payload', payload)
            return
          }
        }

        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
        })

        if (!response.ok) {
          console.error('Failed to send member app alert log')
        }
      } catch (e) {
        console.error('Failed to send member app alert log', e)
      }
    } catch (e) {
      console.error('Failed to send member app alert log', e)
    }
  }
}
//safe text - remove any non printable characters just in case and cut off after 2000 characters as thats what we can support so far
function safeText(str: string) {
  if (!str) return ''
  if (typeof str !== 'string') {
    return 'not-a-string'
  }
  str = str.replace(/[^ -~]+/g, '')
  if (str.length > 2000) {
    str = str.substring(0, 2000) + '-TRUNCATED-'
  }
  return str
}
//safe strinigify and cut off after 2000 characters as thats what we can support so far
function safeStringify(obj: any) {
  if (obj === undefined) {
    return 'undefined'
  }
  if (obj === null) {
    return 'null'
  }
  try {
    let str = JSON.stringify(obj)

    if (str.length > 2000) {
      str = str.substring(0, 2000) + '-TRUNCATED-'
    }
    return str
  } catch (e) {
    return 'Error: could not even stringify the object'
  }
}
