import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, Slide } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import React, { useCallback, useContext, useState } from 'react'
import uuid from 'uuid'
import STypography from '../system/customcomponents/STypography'
import { useSColors } from '../styles/scripta-theme'
// import { COLORS } from '../system/theme2'

export type ToastContextType = {
  //   toastItems: ToastItem[]
  singleToast?: ToastItem
  addToast: (toast: ToastItem) => void
  addErrorMsg: (msg: string) => void
  addSuccessMsg: (msg: string) => void
}

const AUTO_HIDE_DURATION = 8000

export interface ToastItem {
  uuid: string
  msg: any
  variant: 'success' | 'error' | 'warning' | 'info'
  content?: any
}
export const ToastContext = React.createContext<ToastContextType>({
  singleToast: {} as any,
  addToast: {} as any,
  addErrorMsg: {} as any,
  addSuccessMsg: {} as any,
})
//TODO - multi toast support, eitehr stack like notistack or per mui expire old one and show one by one
export function ToastProvider({ children }: { children: any }) {
  //   const [toastItems, setToastItems] = useState<ToastItem[]>([])
  const [singleToast, setSingleToast] = useState<ToastItem>()
  const addToast = useCallback(
    (toast: ToastItem) => {
      setSingleToast(toast)
      //   if (toastItems.map((n) => n.uuid).indexOf(toast.uuid) === -1) {
      //     setToastItems((items) => [...items, toast])
      //   } else {
      //     // 
      //   }
      //   
    },
    [singleToast],
  )
  const addErrorMsg = useCallback(
    (msg: string) => {
      setSingleToast({ msg: msg, variant: 'error', uuid: uuid.v4() })
    },
    [singleToast],
  )
  const addSuccessMsg = useCallback(
    (msg: string) => {
      setSingleToast({ msg: msg, variant: 'success', uuid: uuid.v4() })
    },
    [singleToast],
  )
  const hideToast = useCallback(
    (toast: ToastItem) => {
      setSingleToast(undefined)
    },
    [singleToast],
  )
  return (
    <ToastContext.Provider
      value={{ singleToast, addToast, addSuccessMsg, addErrorMsg }}
    >
      {children}
      {singleToast && (
        <ToastRenderer toast={singleToast} key={singleToast.uuid} />
      )}
    </ToastContext.Provider>
  )
}
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})
function TransitionLeft(props: any) {
  return <Slide {...props} direction="left" />
}
function ToastRenderer({ toast }: { toast: ToastItem }) {
  const [open, setOpen] = React.useState(true)
  const { COLORS } = useSColors()

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const imageStyle = {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: `left 0.25px bottom 0.25px`,
    backgroundRepeat: 'no-repeat',
    transform: 'scale(2.2)',
  }

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={handleClose}
        TransitionComponent={TransitionLeft}
        transitionDuration={500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ bottom: { md: 70 } }}
      >
        <Box
          style={{
            width: '100%',
            color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '4px',
            borderRadius: '4px',
            background: COLORS.BACKGROUND_GREY_DARKER,
            boxShadow:
              '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
            maxWidth: 400,
          }}
        >
          <Box sx={{ padding: '10px 16px' }}>
            <STypography variant="bodysmall_regular">{toast.msg}</STypography>
          </Box>
          <IconButton aria-label="Close" onClick={handleClose}>
            <FontAwesomeIcon
              icon={faXmark}
              color="white"
              style={{ fontSize: '16px', padding: '8px' }}
            />
          </IconButton>
        </Box>
      </Snackbar>
    </Stack>
  )
}

export function useToastContext() {
  return useContext(ToastContext)
}
