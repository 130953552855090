import { Button, Grid, useTheme, useMediaQuery } from '@mui/material'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  isServerErrorResponse,
  isValidResponse,
  PasswordResetData,
} from '../../types'
import { useCallback, useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useNotifier } from '../hooks/useNotify'
import { PasswordHints } from './PasswordHints'
import { useRegistration } from './RegistrationContext'
import {
  REG_ROUTE_ERROR_HANDLER,
  REG_ROUTE_FORGOT_PASSWORD,
  REG_ROUTE_LOGIN,
  REG_ROUTE_SERVER_ERROR,
} from './RegistrationSubRoutes'
import { RegistrationToolbar } from './RegistrationToolbar'
import { useRegistrationService } from './useRegistrationService'
import { GAService } from '../application/ga/GAService'
import { LOG } from 'src/app/v2/applog'

//need a bit more details to properly handle same error response code indifferent flows
//i.e. registration vs forgot password
export const UI_RESPONSE_CODE_DECORATION_INVALID_OTP_RESET_PWD = 'resetpwdcode'

export function ResetPassword() {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const gaService = new GAService()
  const { history, setApiResponse, pwdResetData, setPwdResetData } =
    useRegistration()
  const service = useRegistrationService()
  const { t } = useSTranslate(['register', 'settings'])
  const title = t('enter4DigitCode')

  // const [newPassword, setNewPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  //start with true just to avoid showing the warning message by default
  //will update once user clicks next
  const [newPassMeetsStandard, setNewPassMeetsStandard] = useState(true)

  const { sendMsg } = useNotifier()
  useEffect(() => {
    //only process comparison if both have some values
    if (pwdResetData && pwdResetData.newPassword && passwordConfirm) {
      if (pwdResetData.newPassword === passwordConfirm) {
        setPasswordsMatch(true)
      } else {
        setPasswordsMatch(false)
      }
    }
  }, [pwdResetData, passwordConfirm])

  useEffect(() => {
    document.title = 'Reset Password - Scripta Insights'
  })

  // useEffect(() => {
  //   setPwdResetData({
  //     ...pwdResetData,
  //     newPassword: newPassword,
  //   })
  // }, [passwordConfirm])

  async function asyncResetPassword(resetData: PasswordResetData) {
    try {
      const resp = await service.resetPasswordApi(resetData)

      if (isServerErrorResponse<PasswordResetData>(resp)) {
        LOG.error(
          'registration',
          'Invalid server response in reset password flow',
          resp,
        )
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<PasswordResetData>(resp)) {
        sendMsg(t('resetPasswordSuccess'), 'success')
        history.push(REG_ROUTE_LOGIN)
      } else {
        LOG.error(
          'registration',
          'Invalid API response from reset password attempt',
          resp,
        )
        //console.error('not a valid response, full resp = ', resp)
        // because backend responds with the same code as used during registration,
        // we need a bit more decoration so tryagain logic willw ork in case of error
        const decoratedResp = {
          ...(resp as any),
          uiResponseDecoration:
            UI_RESPONSE_CODE_DECORATION_INVALID_OTP_RESET_PWD,
        }
        setApiResponse(decoratedResp)
        history.push(REG_ROUTE_ERROR_HANDLER)
      }
    } catch (e) {
      LOG.error(
        'registration',
        'Network level exception in reset password API - request timed out / failed to reach the API server.',
        e,
      )
      //handle in generic page
      throw e
    }
  }

  const isResetPasswordDisabled =
    !pwdResetData.oneTimePassCode ||
    !pwdResetData.newPassword ||
    !passwordConfirm ||
    !passwordsMatch

  const doResetPassword = useCallback(() => {
    asyncResetPassword(pwdResetData)
  }, [pwdResetData])

  return (
    <>
      <Grid
        container
        gap={isSmall ? '16px' : '32px'}
        style={{ padding: '30px' }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            variant="outlined"
            id="otp"
            name="otp"
            fullWidth
            placeholder={title}
            label={title}
            value={
              pwdResetData.oneTimePassCode ? pwdResetData.oneTimePassCode : ''
            }
            onChange={(e) => {
              setPwdResetData({
                ...pwdResetData,
                oneTimePassCode: e.target.value,
              })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            variant="outlined"
            fullWidth
            type="password"
            id="newPassword"
            name="newPassword"
            placeholder={t('settings:newPassword')}
            label={
              passwordsMatch
                ? t('settings:newPassword')
                : t('settings:doNotMatch')
            }
            value={pwdResetData.newPassword ? pwdResetData.newPassword : ''}
            onChange={(e) => {
              setPwdResetData({
                ...pwdResetData,
                newPassword: e.target.value,
              })
              //if it was previously false and now its true, mark it in the ui appropriatelly
              //so the error dissapears once the user provides a valid one
              if (
                !newPassMeetsStandard &&
                validatePasswordMeetsStandards(e.target.value)
              ) {
                setNewPassMeetsStandard(true)
              }
            }}
            error={!passwordsMatch}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            variant="outlined"
            type="password"
            fullWidth
            id="passwordConfirm"
            name="passwordConfirm"
            placeholder={t('settings:confirmPassword')}
            label={
              passwordsMatch
                ? t('settings:confirmPassword')
                : t('settings:doNotMatch')
            }
            value={passwordConfirm}
            onChange={(e) => {
              setPasswordConfirm(e.target.value)
            }}
            error={!passwordsMatch}
          />
        </Grid>
        {!newPassMeetsStandard && (
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography variant="caption" style={{ color: 'red' }}>
                {t('login:resetPasswordWarning')}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} style={{ marginTop: '-20px' }}>
          <PasswordHints />
        </Grid>

        <Grid item xs={12} style={{ marginTop: '-30px' }}>
          <RegistrationToolbar
            customNextLabel={t('resetPassword')}
            onClickNext={() => {
              if (validatePasswordMeetsStandards(pwdResetData.newPassword)) {
                setNewPassMeetsStandard(true)
                doResetPassword()
                gaService.trackEvent(
                  gaService.eventMap.forgot_password_submit_final,
                )
              } else {
                setNewPassMeetsStandard(false)
              }
            }}
            nextDisabled={isResetPasswordDisabled}
            onClickCancel={() => history.push(REG_ROUTE_LOGIN)}
            sidePadding={isSmall ? undefined : '20%'}
          />
        </Grid>
      </Grid>
      {/* <div>{JSON.stringify(apiResponse)}</div> */}
    </>
  )
}

function validatePasswordMeetsStandards(password?: string) {
  if (!password) {
    return false
  }
  var re =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/
  if (password.length > 20) {
    return false
  }
  return re.test(password)
}
