import {
  LogoThemeParams,
  MuiThemeParams,
  createMemberPortalTheme,
  getSharedColorsBetwenAmazonAndScriptaOrMaybeOtherThemes,
  SHARED_BLACK,
  SHARED_ERROR_RED,
} from './scripta-theme'
import { CustomColors } from './theme-extender'

const NAME = 'scripta'
const PRIMARY_BRAND = '#4D008C'
const PRIMARY_10 = '#EDE5F3'
const PRIMARY_20 = '#DBCCE8'
const PRIMARY_20_ILLUSTRATIONS = '#D9C6F4'
const PRIMARY_40 = '#B899D1'

const SECONDARY_BRAND = '#2D8DFF'
const SECONDARY_BRAND_10 = '#EAF4FF'
//THIS IS AKA SECONDARY_90
const SECONDARY_BRAND_ACTIVE = '#006EF5'
//AKA SECONDARY_90 in table

// TODO: Update the mobile app code gradient to match this before using this
// const GRADIENT_LONG = `
// linear-gradient(
//   90deg, #6D0B94 0%, #4D008C 49.5%, #9C1DA5 100%
// )`

export const GRADIENT_LONG_SCRIPTA = `linear-gradient(
  to right,
  #9e1fa7 0%,
  #81149b 10%,
  #6a0b91 21%,
  #59048a 32%,
  #500186 45%,
  #4d0085 62%,
  #510186 75%,
  #5f068c 90%,
  #6d0b91 100%
)`

const FONT_NAMES = ['Proxima Nova', 'Roboto', 'Sans-Serif']

export function getScriptaTheme() {
  const customColors: CustomColors = {
    PRIMARY_BRAND: PRIMARY_BRAND,
    PRIMARY_10: PRIMARY_10,
    PRIMARY_20: PRIMARY_20,
    PRIMARY_20_ILLUSTRATIONS: PRIMARY_20_ILLUSTRATIONS,
    PRIMARY_40: PRIMARY_40,

    DESKTOP_RIGHT_CONTENT_PRIMARY_TO_USE: PRIMARY_BRAND,

    RING_SAVINGS_BACKGROUND: PRIMARY_40,
    RING_SAVINGS_BORDER: PRIMARY_40,
    RING_SAVINGS_TEXT: PRIMARY_BRAND,

    DESKTOP_LEFT_CONTAINER_BG: PRIMARY_20,

    DESKTOP_LEFT_MENU_BG: PRIMARY_10,
    DESKTOP_LEFT_MENU_TEXT: PRIMARY_BRAND,
    //dedicated for right desktop menu
    DESKTOP_LEFT_MENU_ACTIVE_BG: PRIMARY_BRAND,
    DESKTOP_LEFT_MENU_ACTIVE_TEXT: '#FFFFFF',

    BORDER_BRAND: PRIMARY_BRAND,

    BACKGROUND_SECONDARY: SECONDARY_BRAND,
    BACKGROUND_SECONDARY_10: SECONDARY_BRAND_10,

    BACKGROUND_SECONDARY_ACTIVE: SECONDARY_BRAND_ACTIVE,

    //dont get confused - the 'primary' scripta button is blue i.e. secondary
    BUTTON_PRIMARY_BACKGROUND: SECONDARY_BRAND,
    BUTTON_PRIMARY_BACKGROUND_ACTIVE: SECONDARY_BRAND_ACTIVE,
    BUTTON_PRIMARY_TEXT: '#FFFFFF',
    BUTTON_PRIMARY_TEXT_ACTIVE: '#FFFFFF',

    //this is a secondary button - white with blue borders (Things like Cancel etc)
    BUTTON_SECONDARY_BACKGROUND: '#FFFFFF',
    BUTTON_SECONDARY_BACKGROUND_ACTIVE: '#FFFFFF',
    BUTTON_SECONDARY_TEXT: SECONDARY_BRAND_ACTIVE,
    BUTTON_SECONDARY_TEXT_ACTIVE: SECONDARY_BRAND_ACTIVE,

    TEXT_BRAND: PRIMARY_BRAND,
    TEXT_SECONDARY: SECONDARY_BRAND,

    BACKGROUND_GRADIENT: GRADIENT_LONG_SCRIPTA,
    ...getSharedColorsBetwenAmazonAndScriptaOrMaybeOtherThemes(),
  }
  const muiParams: MuiThemeParams = {
    colors: {
      PRIMARY_BRAND: PRIMARY_BRAND,
      SECONDARY_BRAND: SECONDARY_BRAND,
      SECONDARY_BRAND_ACTIVE: SECONDARY_BRAND_ACTIVE,
      TEXT: SHARED_BLACK,
      ERROR: SHARED_ERROR_RED,
      //TODO - do we use this mui info color?
      INFO: SHARED_BLACK,
    },
    fontNames: FONT_NAMES,
  }

  const logoParams: LogoThemeParams = {
    whiteOnDarkLogoUrl:
      'https://scripta-public-assets.s3.amazonaws.com/memberapp/themed-logos/scripta_logo_white.svg',
    darkOnWhiteLogoUrl:
      'https://scripta-public-assets.s3.amazonaws.com/memberapp/themed-logos/scripta_logo_dark.svg',
  }

  return createMemberPortalTheme(NAME, muiParams, customColors, logoParams)
}
