import React, { Component, ErrorInfo, ReactNode } from 'react'
import { GenericErrorPage } from './GenericErrorPage'
import { LOG } from '../v2/applog'
import { IS_DEV } from 'src/app/apiexec/utils'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  additionalDetails: string
}

class ErrorBoundaryComponentLevel extends Component<Props, State> {
  public state: State = {
    hasError: false,
    additionalDetails: '',
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, additionalDetails: '' }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    //do not do this in local dev as its triggered often and we sent these logs to integration
    LOG.error(
      'app_error_boundary',
      'Generic App Erorr Boundary component, message=' + error.message,
      error.stack,
    )

    this.setState({ hasError: true, additionalDetails: error.message })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <GenericErrorPage additionalDetails={this.state.additionalDetails} />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundaryComponentLevel
