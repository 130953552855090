import { Box, Grid, Link, Typography } from '@mui/material'
import { CernerLinkWithDialog } from '../drugprofile/CernerTermsDialog'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { PrivacyOrTermsLink } from '../widgets/PrivacyOrTermsLink'

export function All3LicensesAgreement({
  customFontSize,
  shouldShowUnderline = false,
}: {
  customFontSize?: string
  shouldShowUnderline?: boolean
}) {
  const { t } = useSTranslate('prereg')

  const fontSize = customFontSize ? customFontSize : '0.7rem'

  return (
    <Grid container alignItems="baseline">
      <Grid item sx={{ paddingRight: '5px' }}>
        <Typography
          component="div"
          sx={{
            fontSize: fontSize,
            whiteSpace: 'nowrap',
            // paddingRight: '3px',
          }}
        >
          {t('agreedLink')}
        </Typography>
      </Grid>
      <Grid item>
        <PrivacyOrTermsLink type="terms" isUnderlined customSx={{ fontSize }} />
      </Grid>
      <Grid item sx={{ paddingRight: '5px' }}>
        <Typography>{', '}</Typography>
      </Grid>
      <Grid item>
        <PrivacyOrTermsLink
          type="privacy"
          isUnderlined
          customSx={{ fontSize }}
        />
      </Grid>
      <Grid item sx={{ paddingLeft: '5px', paddingRight: '5px' }}>
        <Typography
          sx={{
            fontSize,
            // paddingLeft: '3px',
            // paddingRight: '3px',
          }}
        >
          {t('and')}
        </Typography>
      </Grid>
      <Grid item>
        <CernerLinkWithDialog
          cernerDialogType={'userAgreement'}
          shortenedTitle={true}
          customLinkSx={{
            fontSize: customFontSize ?? undefined,
            textDecoration: shouldShowUnderline ? 'underline' : undefined,
          }}
        />
      </Grid>
    </Grid>
  )
}
