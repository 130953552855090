import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { NewPortalDialogDesign } from '../application/NewDialogs'
import {
  toDigitsOnly,
  toDisplayFriendlyString,
} from '../member/phone-field-utils'

import { SMSTermsOfUseContent } from '../registration/SMSTermsOfUseContent'
import { CustomTextField } from '../registration/components/CustomTextField'
import { SsoAdditionalInfo } from './SsoCompleteFirstTimeAccessForm'
import { isCellvalid } from './ssoutils'

export function SsoSmsOptInSection({
  initAgreed,
  initAcceptSignup,
  onSmsSignupChanged,
  ssoAdditionalInfo,
  setSsoAdditionalInfo,
  phoneAlreadyUsed,
  onPhoneNumberChanged,
}: {
  initAgreed: boolean
  initAcceptSignup: 'yes' | 'no'
  onSmsSignupChanged: (chosenSmsOptIn: 'yes' | 'no', agreed: boolean) => void
  ssoAdditionalInfo: SsoAdditionalInfo
  setSsoAdditionalInfo: (ssoInfo: SsoAdditionalInfo) => void
  phoneAlreadyUsed: boolean
  onPhoneNumberChanged: (phone: string) => void
}) {
  const { t } = useSTranslate('sso')

  // const styles = useRegistrationStyles()
  const [acceptTextSignup, setAcceptTextSignUp] = useState<'yes' | 'no'>(
    initAcceptSignup,
  )
  const [agreed, setAgreed] = useState(initAgreed)

  const [showTermsDialog, setShowTermsDialog] = useState(false)
  const [invalidPhoneMsg, setInvalidPhoneMsg] = useState('')
  const [isPhoneTouched, setIsPhoneTouched] = useState(false)

  useEffect(() => {
    setAgreed(initAgreed)
    setAcceptTextSignUp(initAcceptSignup)
  }, [])

  useEffect(() => {
    onSmsSignupChanged(acceptTextSignup, agreed)
  }, [acceptTextSignup, agreed])

  const handleEnterPressed = (event: any) => {
    if (event.key === 'Enter') {
      setAgreed(!event.target.checked)
    }
  }
  useEffect(() => {
    if (phoneAlreadyUsed) {
      setInvalidPhoneMsg(t('errorCellPhoneAlreadyUsed'))
      return
    }
    if (!isCellvalid(ssoAdditionalInfo.mobileNumber)) {
      setInvalidPhoneMsg(t('errorCellPhoneInvalid'))
      return
    }

    setInvalidPhoneMsg('')
  }, [phoneAlreadyUsed, ssoAdditionalInfo])

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 700, fontSize: '1.125rem' }}>
              {t('smsOptinFieldHeader')}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '16px', fontSize: '1rem' }}>
            <Typography>{t('smsOptInFieldDescription')}</Typography>
          </Grid>
          <Box style={{ display: 'flex', marginTop: 12 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTextSignup === 'yes'}
                  onChange={(e) => {
                    setAcceptTextSignUp(e.target.checked ? 'yes' : 'no')
                  }}
                  name="checkbox-button"
                  color="primary"
                  // onKeyPress={handleEnterPressed} // Add this if needed
                />
              }
              label={
                <Typography
                  // className={styles.checkboxLabel} // Add this if you have a specific style
                  onClick={() => {
                    setAcceptTextSignUp(
                      acceptTextSignup === 'yes' ? 'no' : 'yes',
                    )
                  }}
                  style={{ cursor: 'pointer' }} // Adjust style as needed
                >
                  {t('smsSignMeUpRadioLabel')}
                </Typography>
              }
            />
          </Box>
          <Grid item xs={12}>
            <Box style={{ display: 'flex', marginTop: 12 }}>
              {acceptTextSignup === 'yes' && (
                <CustomTextField
                  sx={{ maxWidth: '300px', width: '300px' }}
                  // inputProps={{
                  //   style: {
                  //     padding: '10px 10px',
                  //   },
                  // }}
                  // fullWidth
                  label={t('smsOptinFieldLabel')}
                  placeholder={t('smsOptinFieldLabel')}
                  value={ssoAdditionalInfo.mobileNumber}
                  onChange={(e) => {
                    setIsPhoneTouched(true)
                    //process and format input to display friendly form
                    //up to 10 digits
                    if (toDigitsOnly(e.target.value).length <= 10) {
                      let displayNumberValue = toDisplayFriendlyString(
                        e.target.value,
                      )
                      setSsoAdditionalInfo({
                        ...ssoAdditionalInfo,
                        mobileNumber: displayNumberValue,
                      })
                      onPhoneNumberChanged(e.target.value)
                    }
                  }}
                  error={
                    (isPhoneTouched && invalidPhoneMsg) ||
                    (ssoAdditionalInfo.mobileNumber &&
                      !isCellvalid(ssoAdditionalInfo.mobileNumber))
                      ? true
                      : false
                  }
                  helperText={
                    (isPhoneTouched && invalidPhoneMsg) ||
                    (ssoAdditionalInfo.mobileNumber &&
                      !isCellvalid(ssoAdditionalInfo.mobileNumber))
                      ? invalidPhoneMsg
                      : ''
                  }
                />
              )}
            </Box>
          </Grid>
        </FormControl>
      </Grid>

      {acceptTextSignup === 'yes' && (
        <Box style={{ marginTop: '32px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreed}
                onChange={(e: any) => {
                  setAgreed(e.target.checked)
                }}
                name="checkedB"
                color="primary"
                onKeyPress={handleEnterPressed}
              />
            }
            label={
              <Typography>
                <span>{t('agreeToSmsTermsPrefix')} </span>
                <span>
                  <Link
                    variant="body2"
                    onClick={(e: any) => {
                      setShowTermsDialog(true)
                    }}
                    sx={{
                      cursor: 'pointer',
                      fontSize: '1rem',
                      paddingTop: '10px',
                    }}
                    // className={styles.radioLabels}
                    style={{ textDecoration: 'underline' }}
                  >
                    {t('smsTermsLinkText')}
                  </Link>
                  <NewPortalDialogDesign
                    open={showTermsDialog}
                    onClose={() => {
                      setShowTermsDialog(false)
                    }}
                    title={t('smsTermsDialogTitle')}
                  >
                    <SMSTermsOfUseContent />
                  </NewPortalDialogDesign>
                </span>
              </Typography>
            }
          />
        </Box>
      )}
    </Grid>
  )
}
