import { GRADIENT_LONG_SCRIPTA } from 'src/app/styles/theme-config-scripta'
import {
  LogoThemeParams,
  MuiThemeParams,
  createMemberPortalTheme,
  getSharedColorsBetwenAmazonAndScriptaOrMaybeOtherThemes,
  SHARED_BLACK,
  SHARED_ERROR_RED,
} from './scripta-theme'
import { CustomColors } from './theme-extender'

const NAME = 'amazon-blue'
const PRIMARY_BRAND = '#1768C9'
const PRIMARY_40 = '#A2C3E9'
const PRIMARY_20 = '#D1E1F4'
const PRIMARY_10 = '#E8F0FA'
const PRIMARY_20_ILLUSTRATIONS = '#D1E1F4'

const SECONDARY_BRAND = '#2D8DFF'

const SECONDARY_BRAND_10 = '#EAF4FF'
//this is 90 in the design system table
const SECONDARY_BRAND_ACTIVE = '#006EF5'

const FONT_NAMES = ['Proxima Nova', 'Roboto', 'Sans-Serif']

const BLUE_BUTTONS = {
  //dont get confused - the 'primary' scripta button is blue i.e. secondary
  BUTTON_PRIMARY_BACKGROUND: SECONDARY_BRAND,
  BUTTON_PRIMARY_BACKGROUND_ACTIVE: SECONDARY_BRAND_ACTIVE,
  BUTTON_PRIMARY_TEXT: '#FFFFFF',
  BUTTON_PRIMARY_TEXT_ACTIVE: '#FFFFFF',

  //this is a secondary button - white with blue borders (Things like Cancel etc)
  BUTTON_SECONDARY_BACKGROUND: '#FFFFFF',
  BUTTON_SECONDARY_BACKGROUND_ACTIVE: '#FFFFFF',
  BUTTON_SECONDARY_TEXT: SECONDARY_BRAND,
  BUTTON_SECONDARY_TEXT_ACTIVE: SECONDARY_BRAND,
}

export function getAmazonBlueTheme() {
  const muiParams: MuiThemeParams = {
    colors: {
      PRIMARY_BRAND: PRIMARY_BRAND,
      SECONDARY_BRAND: SECONDARY_BRAND,
      SECONDARY_BRAND_ACTIVE: SECONDARY_BRAND_ACTIVE,
      TEXT: SHARED_BLACK,
      ERROR: SHARED_ERROR_RED,
      //TODO - do we use this mui info color?
      INFO: SHARED_BLACK,
    },
    fontNames: FONT_NAMES,
  }

  const customColors: CustomColors = {
    PRIMARY_BRAND: PRIMARY_BRAND,
    PRIMARY_10: PRIMARY_10,
    PRIMARY_20: PRIMARY_20,
    PRIMARY_20_ILLUSTRATIONS: PRIMARY_20_ILLUSTRATIONS,
    PRIMARY_40: PRIMARY_40,

    //these are from scripta as we are cobranding with amazon

    DESKTOP_RIGHT_CONTENT_PRIMARY_TO_USE: '#4D008C',

    RING_SAVINGS_BACKGROUND: '#B899D1',
    RING_SAVINGS_BORDER: '#B899D1',
    RING_SAVINGS_TEXT: '#4D008C',

    DESKTOP_LEFT_CONTAINER_BG: '#DBCCE8',

    DESKTOP_LEFT_MENU_BG: '#EDE5F3',
    DESKTOP_LEFT_MENU_TEXT: '#4D008C',

    //dedicated for right desktop menu
    DESKTOP_LEFT_MENU_ACTIVE_BG: '#4D008C',
    DESKTOP_LEFT_MENU_ACTIVE_TEXT: '#FFFFFF',

    BORDER_BRAND: PRIMARY_BRAND,
    ...BLUE_BUTTONS,

    BACKGROUND_SECONDARY: SECONDARY_BRAND,
    BACKGROUND_SECONDARY_10: SECONDARY_BRAND_10,

    BACKGROUND_SECONDARY_ACTIVE: SECONDARY_BRAND_ACTIVE,

    TEXT_BRAND: PRIMARY_BRAND,
    TEXT_SECONDARY: SECONDARY_BRAND,

    BACKGROUND_GRADIENT: GRADIENT_LONG_SCRIPTA,
    ...getSharedColorsBetwenAmazonAndScriptaOrMaybeOtherThemes(),
  }
  const logoParams: LogoThemeParams = {
    whiteOnDarkLogoUrl:
      'https://scripta-public-assets.s3.amazonaws.com/memberapp/amazon_rx_aid_logo_white2.svg',
    darkOnWhiteLogoUrl:
      'https://scripta-public-assets.s3.amazonaws.com/memberapp/amazon_rx_aid_logo_dark2.svg',
  }

  return createMemberPortalTheme(NAME, muiParams, customColors, logoParams)
}
