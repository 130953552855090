import { Grid, Link, Button, useMediaQuery, useTheme } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  isServerErrorResponse,
  isValidResponse,
  QuickLinkFindAcccountReponse,
  RegistrationData,
  Tenant,
} from '../../types'
import React, { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { getUrlParameter } from '../apiexec/utils'
import { URL_PARAM_QUICKLINK_CODE } from '../app-root-types'
import { useMemberAppContext } from '../MemberAppContext'
import { useCommonPortalStyles } from '../styles/common-portal-styles'
import { useRegistration } from './RegistrationContext'
import {
  // REG_ROUTE_EDIT_EMAIL_CONTACT,
  REG_ROUTE_IDENTITY_VALIDATION,
  REG_ROUTE_LOGIN,
  REG_ROUTE_SERVER_ERROR,
  REG_ROUTE_FIND_TENANT,
  REG_ROUTE_EDIT_CONTACT_INFO,
} from './RegistrationSubRoutes'
import { RegistrationToolbar } from './RegistrationToolbar'
import { useRegistrationService } from './useRegistrationService'
import { MemberNoLongerActive } from './MemberNoLongerActive'
import PhoneLink from '../widgets/PhoneLink'
import EmailLink from '../widgets/EmailLink'
import { LOG } from 'src/app/v2/applog'

export type FindTenantSearchType = 'companyName' | 'email'

export const RESPONSE_CODE_QUICKLINK_INVALID_OTP = 283

export const RESPONSE_CODE_QUICKLINK_ALREADY_REGISTERED = 245

//rare case where we sent the link, then in the meantime plan member got deactivated...
export const RESPONSE_CODE_QUICKLINK_NO_MATCHED_MEMBER_ANYMORE = 285

//all these 3 allow the user to register manually
export const RESPONSE_CODE_QUICKLINK_NOT_FOUND = 281
export const RESPONSE_CODE_QUICKLINK_EXPIRED = 282
export const RESPONSE_CODE_QUICKLINK_DEACTIVATED = 286

export function isExpiredOrInactiveLink(errorStatusCode: number) {
  if (
    errorStatusCode === RESPONSE_CODE_QUICKLINK_NOT_FOUND ||
    errorStatusCode === RESPONSE_CODE_QUICKLINK_EXPIRED ||
    errorStatusCode === RESPONSE_CODE_QUICKLINK_DEACTIVATED
  ) {
    return true
  }
}
export function isAlreadyRegistered(errorStatusCode: number) {
  if (errorStatusCode === RESPONSE_CODE_QUICKLINK_ALREADY_REGISTERED)
    return true
}

export function isNoMatchedMemberAnymore(errorStatusCode: number) {
  if (errorStatusCode === RESPONSE_CODE_QUICKLINK_NO_MATCHED_MEMBER_ANYMORE)
    return true
}

export function FindAccountFromQuickLink() {
  const [isProcessingTicket, setIsProcessingTicket] = useState(true)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMsgString, setErrorMsgString] = useState<string | undefined>()
  const [errorStatusCode, setErrorStatusCode] = useState<number | undefined>()
  const { setQuickLinkCode, quickLinkCode } = useMemberAppContext()

  //for otp extension where a link also is paired with an otp
  const [hasPairedOtp, setHasPairedOtp] = useState(false)
  const [quickLinkOtp, setQuickLinkOtp] = useState<string | undefined>()
  const [invalidOtpProvided, setInvalidOtpProvided] = useState<boolean>(false)
  const [isLinkExpiredOrInvalid, setIsLinkExpiredOrInvalid] = useState(false)

  //besides a wrong OTP, there can be additional errors returned from backend
  //i.e. after OTP is valid but something else is wrong
  const [isOtherOtpProcessingError, setIsOtherOtpProcessingError] =
    useState<boolean>(false)

  //need an additional error handler when process

  const [renderMemberSupport, setRenderMemberSupport] = useState(false)

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  const {
    setApiResponse,
    setMatchedTenant,
    setRegistrationData,
    member,
    setMember,
    history,
    setRegistrationJwt,
  } = useRegistration()

  const service = useRegistrationService()

  //Added Translation
  const { t } = useSTranslate(['common', 'register'])

  useEffect(() => {
    document.title = 'Scripta Insights - Member Registration'
    const quickLinkCode = getUrlParameter(URL_PARAM_QUICKLINK_CODE)
    setQuickLinkCode(quickLinkCode)
  }, [])

  async function asyncLoadFromQuickLink(quickLinkCode: string) {
    try {
      const resp = await service.findTenantMemberFromQuickLinkApi(quickLinkCode)

      if (isServerErrorResponse<Tenant>(resp)) {
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<QuickLinkFindAcccountReponse>(resp)) {
        setApiResponse(resp)
        setMatchedTenant(resp.tenant)

        setMember(resp.member)

        //init the log now that we know who it is
        LOG.initMember(resp?.member?.id, resp?.tenant?.id)

        setHasPairedOtp(resp.hasPairedOtp)
        const regData: RegistrationData = {
          memberId: resp.member?.id.toString(),
          tenantId: resp.tenant?.id.toString(),
        }
        setRegistrationData(regData)
        setIsProcessingTicket(false)
      } else {
        //lets not use the shared error handler - because that one has "Try Again" - which doesnt make sense in this case
        // history.push(REG_ROUTE_ERROR_HANDLER)
        setShowErrorMessage(true)
        setErrorMsgString(resp.description)
        setErrorStatusCode(resp.code)

        if (isExpiredOrInactiveLink(resp?.code)) {
          setIsLinkExpiredOrInvalid(true)
        }
        setRenderMemberSupport(true)
        setIsProcessingTicket(false)
      }
    } catch (e) {
      setIsProcessingTicket(false)
      setErrorMsgString(t('errorProcessing'))
      setRenderMemberSupport(true)
    }
  }

  async function asyncValidateQuickLinkOtp(
    quickLinkCode: string,
    quickLinkOtp: string,
  ) {
    try {
      const resp = await service.validateQuickLinkOtpApi(
        quickLinkCode,
        quickLinkOtp,
      )

      if (isServerErrorResponse<QuickLinkFindAcccountReponse>(resp)) {
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<QuickLinkFindAcccountReponse>(resp)) {
        setInvalidOtpProvided(false)
        setIsProcessingTicket(false)
        //SIS-1720 need to extract registation JWT for the last step
        const registrationJwt = resp.registrationJwt
        if (registrationJwt) {
          setRegistrationJwt(registrationJwt)
        }
        //skip directly to editing of email (step after identity validation)
        history.push(REG_ROUTE_EDIT_CONTACT_INFO)
      } else {
        if (resp.code === RESPONSE_CODE_QUICKLINK_INVALID_OTP) {
          setInvalidOtpProvided(true)
        } else {
          //this is some other backend error, unrelated to OTP code
          setInvalidOtpProvided(false)
          setIsOtherOtpProcessingError(true)
        }

        setIsProcessingTicket(false)
        setRenderMemberSupport(true)
        setErrorStatusCode(resp.code)
        setErrorMsgString(resp.description)
      }
    } catch (e) {
      // this is just a massive error not even recognizable (i.e. backend api down.)
      setIsProcessingTicket(false)
      setErrorMsgString(t('errorProcessing'))
      setRenderMemberSupport(true)
    }
  }

  useEffect(() => {
    if (quickLinkCode) {
      asyncLoadFromQuickLink(quickLinkCode)
    }
  }, [quickLinkCode])

  //disable next if loading, if error, if we have otp but otp not yet entered
  const isNextDisabled =
    isProcessingTicket || showErrorMessage || (hasPairedOtp && !quickLinkOtp)

  if (isLinkExpiredOrInvalid) {
    return (
      <QuickLinkContainer>
        <ExpiredOrInvalidLink />
      </QuickLinkContainer>
    )
  }

  if (errorStatusCode && isAlreadyRegistered(errorStatusCode)) {
    return (
      <QuickLinkContainer>
        <AlreadyRegistered />
      </QuickLinkContainer>
    )
  }
  if (errorStatusCode && isNoMatchedMemberAnymore(errorStatusCode)) {
    return (
      <QuickLinkContainer>
        <MemberNoLongerActive />
      </QuickLinkContainer>
    )
  }

  return (
    <Grid container spacing={2} style={{ padding: '30px', paddingBottom: 20 }}>
      <Grid item xs={12}>
        <Typography
          fontWeight={700}
          fontSize={isSmall ? '1.125rem' : '1.5rem'}
          textAlign={'center'}
          lineHeight={'21.92px'}
        >
          {t('register:memberRegistrationHeading')}
        </Typography>
      </Grid>
      <LoadingIndicator isProcessingTicket={isProcessingTicket} />
      <ErrorHandler
        isProcessingTicket={isProcessingTicket}
        showErrorMessage={showErrorMessage}
        errorMsgString={errorMsgString}
        errorStatusCode={errorStatusCode}
      />

      {!isProcessingTicket && !showErrorMessage && (
        <>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Typography>{t('register:confirmDetailsAndContinue')}</Typography>
          </Grid>
          <Grid item xs={12} container style={{ marginTop: 10 }}>
            <Typography>
              <span>{t('common:name')}</span>{' '}
              <span
                style={{ fontWeight: 600 }}
                data-testid="quicklink-member-name-greeting"
              >
                {member?.firstName} {member?.lastName}
              </span>{' '}
            </Typography>
          </Grid>
        </>
      )}
      {hasPairedOtp && (
        <OtpExtension
          quickLinkOtp={quickLinkOtp}
          setQuickLinkOtp={setQuickLinkOtp}
          setRenderMemberSupport={setRenderMemberSupport}
          invalidOtpProvided={invalidOtpProvided}
          isOtherOtpProcessingError={isOtherOtpProcessingError}
          otherOtpErrorDetails={{
            code: errorStatusCode,
            description: errorMsgString,
          }}
        />
      )}
      <Grid item xs={12}>
        <RegistrationToolbar
          onClickNext={() => {
            if (hasPairedOtp && quickLinkCode && quickLinkOtp) {
              asyncValidateQuickLinkOtp(quickLinkCode, quickLinkOtp)
            } else {
              history.push(REG_ROUTE_IDENTITY_VALIDATION)
            }
          }}
          nextDisabled={isNextDisabled}
          backHidden={true}
        />
      </Grid>
    </Grid>
  )
}

function QuickLinkContainer({ children }: { children: any }) {
  return <>{children}</>
}

function LoadingIndicator({
  isProcessingTicket,
}: {
  isProcessingTicket: boolean
}) {
  //Added Translation
  const { t } = useSTranslate(['common', 'register'])
  if (!isProcessingTicket) {
    return null
  }

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={6}>
        <CircularProgress />
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {t('register:waitWhileProcessingRegistartionTicket')}
        </Typography>
      </Grid>
    </Grid>
  )
}

function ErrorHandler({
  isProcessingTicket,
  showErrorMessage,
  errorMsgString,
  errorStatusCode,
}: {
  isProcessingTicket: boolean
  showErrorMessage: boolean
  errorMsgString?: string
  errorStatusCode?: number
}) {
  const baseClasses = useCommonPortalStyles()
  const { history } = useRegistration()
  //Added Translation
  const { t } = useSTranslate(['common', 'register'])

  if (isProcessingTicket || !showErrorMessage) {
    return null
  }

  return (
    <Grid item container spacing={2} xs={12}>
      <Grid item xs={12}>
        <Typography>{errorMsgString}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Link
          component="button"
          variant="body2"
          className={baseClasses.baseLink}
          onClick={() => {
            history.push(REG_ROUTE_LOGIN)
          }}
        >
          {t('register:goToLogin')}
        </Link>
      </Grid>
    </Grid>
  )
}

export function OtpExtension({
  quickLinkOtp,
  setQuickLinkOtp,
  setRenderMemberSupport,
  invalidOtpProvided,
  isOtherOtpProcessingError = false,
  otherOtpErrorDetails,
}: {
  quickLinkOtp?: string
  setQuickLinkOtp: any
  setRenderMemberSupport: any
  invalidOtpProvided: boolean
  isOtherOtpProcessingError: boolean
  otherOtpErrorDetails?: { code: any; description?: string }
}) {
  //Added Translation
  const { t } = useSTranslate(['common', 'register', 'register'])
  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <Typography>{t('register:otpIdentificationMessage')}</Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          data-testid="quicklink-otp-input"
          autoComplete="off"
          variant="outlined"
          id="quicklink_otp"
          name="quicklink_otp"
          fullWidth
          placeholder={t('register:enterSecondaryCode')}
          helperText={invalidOtpProvided ? t('register:invalidOTP') : ''}
          error={invalidOtpProvided}
          // label={title}
          value={quickLinkOtp ? quickLinkOtp : ''}
          onChange={(e) => {
            setQuickLinkOtp(e.target.value)
          }}
        />
      </Grid>
      {/* no need to show this if they entered an invalid code, support screen is
        alreayd visible in that case */}
      {!invalidOtpProvided && (
        <Grid item xs={12} style={{ marginTop: '10px' }}>
          <Typography variant="caption">
            {t('register:issueLocatingSecondaryCode')}
          </Typography>{' '}
          <Typography variant="caption">{t('common:supportHelp')}</Typography>{' '}
          <Typography variant="caption">
            <span style={{ whiteSpace: 'nowrap' }}>
              <PhoneLink shouldShowUnderline={true} />
            </span>{' '}
            or{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              <EmailLink shouldShowUnderline={true} />
            </span>
          </Typography>
        </Grid>
      )}
      {isOtherOtpProcessingError && (
        <Grid item xs={12} style={{ marginTop: '10px' }}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {t('register:unableToRegister')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{t('register:contactMemberSupport')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{otherOtpErrorDetails?.description}</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export function ExpiredOrInvalidLink() {
  const { setQuickLinkCode } = useMemberAppContext()
  const { history } = useRegistration()
  const { t } = useSTranslate(['common', 'register', 'register'])

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ padding: 30, paddingBottom: 20 }}
    >
      <Grid item xs={12} style={{ textAlign: 'center', fontWeight: 600 }}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          {t('register:linkExpired')}
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography>{t('register:registerByBasicInfo')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <RegistrationToolbar
          customNextLabel={t('register:regstr')}
          onClickNext={() => {
            setQuickLinkCode(undefined)
            history.push(REG_ROUTE_FIND_TENANT)
          }}
          nextDisabled={false}
          backHidden={true}
        />
      </Grid>
    </Grid>
  )
}

export function AlreadyRegistered() {
  const { history } = useRegistration()
  const { t } = useSTranslate(['register', 'register', 'register'])
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ padding: 30, paddingBottom: 20 }}
    >
      <Grid item xs={12} style={{ textAlign: 'center', fontWeight: 600 }}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          {t('register:alreadyRegistered')}
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography>
          {t('register:loginUsingExistingUsernameAndPassword')}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography>{t('register:forgotPassword')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <RegistrationToolbar
          customNextLabel="Login"
          onClickNext={() => {
            history.push(REG_ROUTE_LOGIN)
          }}
          nextDisabled={false}
          backHidden={true}
        />
      </Grid>
    </Grid>
  )
}
