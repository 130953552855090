import { createRoot } from 'react-dom/client'
import './index.css'
import './fonts.css'
import App from './app/App'
import * as serviceWorker from './serviceWorker'
import './i18n-init'
// import { UnderMaintain } from './app/application/UnderMaintain'

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
//comment out <App and replace with below to show system maintancne component
// root.render(<UnderMaintain />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
