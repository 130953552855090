import uuid from 'uuid'
import {
  ToastItem,
  useToastContext,
} from '../application/ScriptaSnackbarProvider'
export interface PortalNotifyParams {
  autoHideDuration?: number
}

export function useNotifier(notifierParams?: PortalNotifyParams) {
  const { addToast } = useToastContext()
  // const { enqueueSnackbar } = useSnackbar()
  const { autoHideDuration = 4000 } = notifierParams ? notifierParams : {}

  const sendMsg = (
    msg: React.ReactNode,
    variant: 'success' | 'error' | 'warning' | 'info',
    customAutoHideDuration?: number,
  ) => {
    const newToast: ToastItem = {
      msg: msg,
      variant: variant,
      uuid: uuid.v4(),
    }
    addToast(newToast)
    // if (variant === 'success') {
    //   enqueueSnackbar(msg, {
    //     variant: variant,
    //     autoHideDuration: customAutoHideDuration
    //       ? customAutoHideDuration
    //       : autoHideDuration,
    //   })
    // } else {
    //   //dont auto hide for errors
    //   enqueueSnackbar(msg, {
    //     variant: variant,
    //     autoHideDuration: 20000,
    //   })
    // }
  }

  return { sendMsg }
}
