import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  Radio,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import animePreRegister from '../../images/login_anime_preregister_cropped.gif'
import animeRegister from '../../images/login_anime_register_cropped.gif'

import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  Member,
  RegistrationData,
  RequestIdentificationData,
} from '../../types'
import { useMemberAppContext } from '../MemberAppContext'
import { ROUTE_COMPLETE_LOGIN } from '../PortalRoutes'
import {
  IS_BLUE_PROD,
  IS_DEV,
  IS_INTEGRATION,
  IS_NATIVE,
  IS_PROD,
  isQuickLinkRegistration,
  nativeApi_setBackButtonVisible,
  nativeApi_setCanGoBack,
} from '../apiexec/utils'

import { useLocation } from 'react-router'
import { getUrlParameter } from '../apiexec/utils'
import { URL_PARAM_MOBILE_APP_VERSION } from '../application/ForceUpgradeDialog'
import { GAService } from '../application/ga/GAService'
import { PreregMemberLoginInfoResp } from '../member/useMemberService'
import { COMMON_BOLD_WEIGHT, useSColors } from '../styles/scripta-theme'
import SButton from '../system/customcomponents/SButton'
import { LearnMoreBtn, PreReg } from './PreReg'
import { useRegistration } from './RegistrationContext'
import {
  RESPONSE_CODE_PASSWORDS_DONT_MATCH,
  RESPONSE_CODE_PREREGISTRATION_IN_PROCESS,
  RESPONSE_CODE_PREREGISTRATION_IN_PROCESS_KNOWN_MEMBER,
  RESPONSE_CODE_SECURITY_VALIDATION_NEEDED_NEW_CODE,
  RESPONSE_CODE_V1_PASSWORD_DONT_MATCH,
} from './RegistrationErrorHandler'
import {
  REG_ROUTE_ERROR_HANDLER,
  REG_ROUTE_FIND_TENANT,
  REG_ROUTE_FORGOT_PASSWORD,
  REG_ROUTE_FORGOT_USERNAME,
  REG_ROUTE_IDENTITY_VALIDATION,
} from './RegistrationSubRoutes'
import { CustomTextField } from './components/CustomTextField'
import { useRegistrationService } from './useRegistrationService'
import { LOG } from '../v2/applog'
import STypography from 'src/app/system/customcomponents/STypography'
import { SPACING } from 'src/app/system/theme2'

export function LoginOrRegister() {
  const { COLORS } = useSColors()
  const { t } = useSTranslate(['login', 'common'])
  const theme = useTheme()
  const { history, setApiResponse, setProgressBarState } = useRegistration()
  const { logoutMsg, setLogoutMsg, setJwt, isSsoLogin } = useMemberAppContext()
  const [useQuickLinkRegistration, setUseQuickLinkRegistration] =
    useState(false)

  const [renderPreReg, setRenderPreReg] = useState(false)

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  //main login vs register
  const [selectedTab, setSelectedTab] = useState('login')

  //if on register, is it register or preregister
  const [selectedRegType, setSelectedRegType] = useState<
    'register' | 'preregister'
  >('register')

  //check if there is anything in router state (i.e. clicking Cancel from Registeration)
  const location = useLocation()
  useEffect(() => {
    const state = history.location.state
    if (state && (state as any).previouslySelectedTab != undefined) {
      setSelectedTab((state as any).previouslySelectedTab)
      const newState = { ...(state as any), previouslySelectedTab: undefined }
      //then remove it?
      history.replace({ state: newState })
    }
  }, [location.state])

  //clean up progress bar state, if any
  useEffect(() => {
    setProgressBarState({
      type: 'registration',
      disabled: true,
      currentStep: 0,
    })
  }, [])

  useEffect(() => {
    if (isQuickLinkRegistration()) {
      setUseQuickLinkRegistration(true)
    }
  }, [])

  useEffect(() => {
    document.title = 'Login or Register - Scripta Insights'
  })

  useEffect(() => {
    if (IS_NATIVE()) {
      // TODO: the back button is visible by default on the mobile app level
      // remove the back button from mobile app code and remove the need for
      // nativeApi_setBackButtonVisible
      nativeApi_setBackButtonVisible(false)
      nativeApi_setCanGoBack(false)

      return () => {
        nativeApi_setCanGoBack(true)
      }
    }
  }, [])

  //new effect for custom logout message for SSO users
  if (logoutMsg && isSsoLogin) {
    return (
      <Grid container sx={{ padding: SPACING._25 }}>
        <Grid item xs={12}>
          <STypography sx={{ textAlign: 'center' }} variant="body_regular">
            This SSO session has expired.
          </STypography>
        </Grid>
        <Grid item xs={12}>
          <STypography sx={{ textAlign: 'center' }} variant="body_regular">
            Please login again via your benefits provider.
          </STypography>
        </Grid>
      </Grid>
    )
  }
  if (renderPreReg) {
    return (
      <PreReg
        onClickCancel={() => {
          setRenderPreReg(false)
          //remove progress bar
          setProgressBarState({
            type: 'registration',
            disabled: true,
            currentStep: 0,
          })
        }}
      />
    )
  }

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        paddingTop: '0px',
        // paddingLeft: '30px',
        // paddingRight: '30px',
        // paddingBottom: '30px',
      }}
    >
      <Grid item xs={12} container>
        <Grid
          item
          xs={12}
          // md={8}
          sx={{
            //MF - removing this ridicilous 3MB file from the entry page due to performance issues as per lighthouse report
            // backgroundImage: "url('/images/login-vid-sample_medium.gif')",
            borderRadius: '15px 15px 0px 0px',
            backgroundPosition: 'center',
            minHeight: isSmall ? '136px' : '166px',
            minWidth: '100%',
            backgroundSize: 'cover',
            // backgroundBlendMode: 'screen',
            backgroundColor: COLORS.PRIMARY_BRAND,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={'column'}
            sx={{ height: '100%' }}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '1.375rem',
                  fontWeight: 700,
                  color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
                }}
              >
                {t('welcomeToScripta')}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
                  textAlign: 'center',
                  padding: isSmall ? '0px 30px 0px 30px' : '0px 20px 0px 20px',
                  // paddingTop: '15px',
                  fontSize: '1rem',
                }}
              >
                {t('weCannotWait')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        sx={
          {
            // minHeight: '80px',
          }
        }
      >
        <TabSelector
          onTabSelected={(tabId) => {
            setSelectedTab(tabId)
          }}
          selected={selectedTab}
        />
      </Grid>

      <Grid item xs={12} container>
        <Box
          sx={(theme) => ({
            width: '100%',
            //need etra care for very small screens (below iphone 12pro of 390)
            [theme.breakpoints.down(390)]: {
              padding: '5px',
            },

            [theme.breakpoints.up(390)]: {
              padding: '0px 20px 20px 20px',
            },
            [theme.breakpoints.up(500)]: {
              padding: '30px',
            },
          })}
        >
          {selectedTab === 'register' && (
            <RegOrPreregTabContent
              selectedRegType={selectedRegType}
              setSelectedRegType={setSelectedRegType}
              onContinueRegister={() => {
                history.push(REG_ROUTE_FIND_TENANT)
              }}
              onContinuePreRegister={() => {
                setRenderPreReg(true)
                // history.push(REG_ROUTE_FIND_TENANT_MEMBER_FROM_QR)
              }}
            />
          )}

          {logoutMsg && (
            <Grid
              item
              xs={12}
              style={{ marginTop: '0px', marginBottom: '20px' }}
            >
              <Typography style={{ fontWeight: 500 }}>{logoutMsg}</Typography>
            </Grid>
          )}
          {selectedTab === 'login' && <SingInTabContent />}
        </Box>
      </Grid>
    </Grid>
  )
}

function PreregInProgressDialog({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: any
}) {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useSTranslate('prereg')

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: '20px',
          // padding: '20px',
          padding: isSmall ? '0px' : '20px',
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: 'center', fontSize: '1.4rem', fontWeight: 600 }}
            >
              {t('preregAlready')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: 'center', marginTop: '20px', fontSize: '1rem' }}
            >
              {t('weWillReachOut')}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
            <Typography>{t('toLearn')}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '20px' }}>
            <Divider />
          </Grid>
          <Grid
            item
            container
            sx={{ marginTop: '25px' }}
            justifyContent={'flex-end'}
          >
            <Grid item>
              <SButton
                onClick={() => {
                  setOpen(false)
                }}
                size="small"
                sx={{ marginRight: '20px' }}
                variant="outlined"
              >
                {t('closeBtn')}
              </SButton>
            </Grid>
            <Grid item>
              <LearnMoreBtn />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export function TabSelector({
  selected,
  onTabSelected,
}: {
  selected: string
  onTabSelected: (tab: string) => void
}) {
  const { t } = useSTranslate('login')
  const { COLORS } = useSColors()

  function UnderlinePill({ selected }: { selected: boolean }) {
    if (!selected) return null
    return (
      <Box
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          color: COLORS.PRIMARY_BRAND,
          textAlign: 'center',
          minHeight: '8px',
          maxHeight: '8px',
          width: '46px',
          maxWidth: '46px',
          backgroundColor: COLORS.PRIMARY_BRAND,
          borderRadius: '5px',
          // marginTop: '10px',
        }}
      ></Box>
    )
  }
  return (
    <Grid
      container
      sx={{
        // borderBottom: '2px solid',
        // borderColor: PORTAL_COLOR_YET_ANOTHER_GREY_FROM_DESIGNER,
        // backgroundColor: 'red',
        padding: '15px 5px 15px 5px',
      }}
    >
      <Grid item xs={6} sx={{ textAlign: 'center' }}>
        <CustomTab
          dataTestId="signin-tab"
          label={t('singInTab')}
          selected={selected === 'login' ? true : false}
          onClick={() => {
            onTabSelected('login')
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ textAlign: 'center' }}>
        <CustomTab
          dataTestId="register-tab"
          label={t('registerTab')}
          selected={selected === 'register' ? true : false}
          onClick={() => {
            onTabSelected('register')
          }}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item container xs={6} sx={{ marginTop: '-13px' }}>
          <UnderlinePill selected={selected === 'login' ? true : false} />
        </Grid>
        <Grid item container xs={6} sx={{ marginTop: '-13px' }}>
          <UnderlinePill selected={selected === 'register' ? true : false} />
        </Grid>
      </Grid>
    </Grid>
  )
}
function CustomTab({
  label,
  selected,
  onClick,
  dataTestId,
}: {
  label: string
  selected: boolean
  onClick: () => void
  dataTestId: string
}) {
  const { COLORS } = useSColors()
  return (
    <Grid container justifyItems={'center'}>
      <Grid item xs={12}>
        <Typography
          sx={{
            cursor: 'pointer',
            fontSize: '1rem',
            fontWeight: 600,
            color: COLORS.PRIMARY_BRAND,
            opacity: selected ? 1 : 0.65,
            // borderBottom: selected ? '2px solid' : 'none',
          }}
          data-testid={dataTestId}
          onClick={onClick}
        >
          {label}
        </Typography>
      </Grid>
      {/* <Grid item xs={12} container>
        <Box
          sx={{
            margin: 'auto',
            backgroundColor: PORTAL_COLOR_MAIN_PURPLE,
            height: '10px',
            minHeight: '10px',
            width: '60px',
            marginTop: '30px',
          }}
        ></Box>
      </Grid> */}
    </Grid>
  )
}

function SingInTabContent() {
  const { COLORS } = useSColors()
  const gaService = new GAService()
  const { t } = useSTranslate(['login', 'common'])
  const theme = useTheme()

  const { history, setApiResponse, setMember, setRegistrationData } =
    useRegistration()
  const {
    setClientUuid,
    setIsFirstTimePreregMemberAccess,
    setPreRegCredentials,
    setRenderPreregFirstAccessWelcomeDialog,
  } = useMemberAppContext()
  const { logoutMsg, setLogoutMsg, setJwt } = useMemberAppContext()

  const [loginApiErrorMsg, setLoginApiErrorMsg] = useState<string | undefined>(
    undefined,
  )

  const [useQuickLinkRegistration, setUseQuickLinkRegistration] =
    useState(false)
  const [userLoginName, setUserLoginName] = useState(
    //IS_DEV() ? 'plante_int_6120' : '', //no savings
    //TODO - review this locked account plante_int_3969
    // IS_DEV() ? 'uat_jps_4100_coupon' : '',
    // IS_DEV() ? 'uat_jps_11147' : '',
    // IS_DEV() ? 'uat_jps_12735_coupona' : '',
    IS_DEV() ? 'auto-193-' : '',
    // IS_DEV() ? 'intjps10246' : '',
    // IS_DEV() ? 'uat_jps_dbtest' : '',
    // IS_DEV() ? 'sprint_demo_coupon' : '',
    // IS_DEV() ? 'Test' : '',
    // IS_DEV() ? 'localdevuser1' : '',
  )

  const [password, setPassword] = useState(IS_DEV() ? 'Password9$$' : '')

  const [isLoading, setIsLoading] = useState(false)
  const regService = useRegistrationService()

  //stay on this screen if the error is invalid username or password
  const [isInvalidCredentials, setIsInvalidCredentials] = useState(false)
  const [isPreregInProgress, setIsPreregInProgress] = useState(false)

  const [reqIdData, setReqIdData] = useState<RequestIdentificationData>(
    {} as any,
  )

  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const isTabletMode: boolean = isMedium
  const location = useLocation()

  //check if auto logim (coming from registration where we just created the new user account)
  useEffect(() => {
    const autoLoginState = history.location.state
    if (autoLoginState && (autoLoginState as any).autoLogin) {
      const userNameTouse = (autoLoginState as any).autoLogin
        .justRegisteredUserName
      const userPassToUse = (autoLoginState as any).autoLogin
        .justRegisteredPassword

      asyncLogin(userNameTouse, userPassToUse)
    }
  }, [location])

  useEffect(() => {
    if (isQuickLinkRegistration()) {
      setUseQuickLinkRegistration(true)
    }
  }, [])

  useEffect(() => {
    setReqIdData({ password: password, userName: userLoginName } as any)
  }, [userLoginName, password])

  async function asyncLogin(userName: string, password: string) {
    try {
      setIsLoading(true)
      const resp = await regService.authenticateMemberApi(
        userName,
        password,
        'initToContext',
      )
      setIsLoading(false)
      if (isPreregistrationInProgress(resp)) {
        // let maskedUserName = ''
        // if (userName.length > 4) {
        //   const middleStart = Math.max(0, Math.floor((userName.length - 4) / 2))
        //   const middleEnd = Math.min(userName.length, middleStart + 4)
        //   maskedUserName =
        //     userName.slice(0, middleStart) +
        //     '*'.repeat(middleEnd - middleStart) +
        //     userName.slice(middleEnd)
        // }

        // LOG.warn(
        //   'preregistration',
        //   'Preregistered user tried to login but they have not been synchronized yet',
        //   maskedUserName,
        // )
        setIsPreregInProgress(true)
      } else if (isPrereigstrationUserThatNeedsSecurityClearance(resp)) {
        if (resp && (resp as any).memberLoginInfo) {
          let typedResp = resp as PreregMemberLoginInfoResp
          if (
            !typedResp.memberLoginInfo.memberId ||
            !typedResp.memberLoginInfo.tenantId
          ) {
            LOG.error(
              'preregistration',
              'annot continue with pre-registered member verification - unknown member id or tenant id,auth resp=',
              resp,
            )

            throw new Error(
              'Cannot continue with pre-registered member verification - unknown member id or tenant id',
            )
          }

          //need to simulate these two as if we came thru normal registration steps
          const inMemoryMemberData: Partial<Member> = {
            enrollmentEmail: typedResp.memberLoginInfo.enrollmentEmail,
            enrollmentMobilePhone:
              typedResp.memberLoginInfo.enrollmentMobilePhone,
            id: typedResp.memberLoginInfo.memberId,
            tenantId: typedResp.memberLoginInfo.tenantId,
            // memberId: typedResp.memberLoginInfo.memberId,
            // firstName: 'na',
            // lastName: 'na',
          }
          setMember(inMemoryMemberData)

          //need registraion data as part of  the sending otp
          const inMemberoyRegData: RegistrationData = {
            memberId: typedResp.memberLoginInfo.memberId.toString(),
            tenantId: typedResp.memberLoginInfo.memberId.toString(),
          }
          setRegistrationData(inMemberoyRegData)

          //prepare the custom values in context so we can appropriatelly intercept in security validation
          setIsFirstTimePreregMemberAccess(true)
          setPreRegCredentials({ userName, password })
          //to show custom dialog upon login
          setRenderPreregFirstAccessWelcomeDialog(true)

          history.push(REG_ROUTE_IDENTITY_VALIDATION)
        }
        //TODO - error handling for this case
      } else if (isValidLogginResponse(resp)) {
        //
        nativeApi_setBackButtonVisible(false)
        setIsInvalidCredentials(false)
        setLogoutMsg(undefined)

        if (getUrlParameter(URL_PARAM_MOBILE_APP_VERSION)) {
          history.push(
            ROUTE_COMPLETE_LOGIN +
              '?' +
              URL_PARAM_MOBILE_APP_VERSION +
              '=' +
              getUrlParameter(URL_PARAM_MOBILE_APP_VERSION),
          )
        } else {
          history.push(ROUTE_COMPLETE_LOGIN)
        }
      } else {
        //now the code and descriptions are within in case of errors (i.e. wrong pass etc),

        const errorResponseCode = (resp as any).statusCode
        if (errorResponseCode) {
          setApiResponse(errorResponseCode)
          if (
            errorResponseCode?.code == RESPONSE_CODE_PASSWORDS_DONT_MATCH ||
            errorResponseCode?.code == RESPONSE_CODE_V1_PASSWORD_DONT_MATCH
          ) {
            //now same error handler
            setIsInvalidCredentials(true)
            // setOpen(true)
          } else {
            //MF - small cleanup in case of 1010 (legacy v1 migrated users)
            //to sanitize their username / email as it was returned

            let sanitizedResp = resp
            if (sanitizedResp && (sanitizedResp as any).memberLoginInfo) {
              delete (sanitizedResp as any).memberLoginInfo.password
              delete (sanitizedResp as any).memberLoginInfo.userName
              delete (sanitizedResp as any).memberLoginInfo.email
            }

            LOG.error(
              'login',
              'Error status code in login component from authenticate API - not one of the inline status codes - will use general error handler',
              sanitizedResp,
            )
            history.push(REG_ROUTE_ERROR_HANDLER)
          }
        }
      }
    } catch (e) {
      console.log('error', e)
      let maskedUserName = ''
      if (userName.length > 4) {
        const middleStart = Math.max(0, Math.floor((userName.length - 4) / 2))
        const middleEnd = Math.min(userName.length, middleStart + 4)
        maskedUserName =
          userName.slice(0, middleStart) +
          '*'.repeat(middleEnd - middleStart) +
          userName.slice(middleEnd)
      }

      LOG.error(
        'login',
        'Network level exception in authenticate API - request timed out / failed to reach the API server, maskedUserName=' +
          maskedUserName,
        e,
      )
      //maybe a generic error message, at least try again
      setLoginApiErrorMsg(
        "We're having trouble logging you in right now. Please check your internet connection and try again. If the problem persists, please contact our member support.",
      )
      setIsLoading(false)
    }

    setIsLoading(false)
  }

  function isPreregistrationInProgress<T>(arg: any): arg is T {
    return (
      arg !== null &&
      arg.status !== 400 &&
      arg.status !== 404 &&
      arg.statusCode &&
      (arg.statusCode.code === RESPONSE_CODE_PREREGISTRATION_IN_PROCESS ||
        arg.statusCode.code ===
          RESPONSE_CODE_PREREGISTRATION_IN_PROCESS_KNOWN_MEMBER)
    )
  }
  //show security verification dialog in this case
  function isPrereigstrationUserThatNeedsSecurityClearance<T>(
    arg: any,
  ): arg is T {
    let retVal = false
    retVal =
      arg !== null &&
      arg.status !== 400 &&
      arg.status !== 404 &&
      arg.statusCode &&
      arg.statusCode.code === RESPONSE_CODE_SECURITY_VALIDATION_NEEDED_NEW_CODE
    return retVal
  }

  function isValidLogginResponse<T>(arg: any): arg is T {
    return (
      arg !== null &&
      arg.status !== 400 &&
      arg.status !== 404 &&
      arg.statusCode &&
      arg.statusCode.code === 1
    )
  }

  return (
    <>
      {/* <Box sx={{ marginBottom: 2.5 }}>
        <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
          {t('header')}
        </Typography>
      </Box> */}
      <CustomTextField
        data-testid="login-username-input"
        autoFocus
        autoComplete="off"
        value={userLoginName}
        onChange={(event) => setUserLoginName(event.target.value)}
        label={t('enterUser')}
        placeholder={t('enterUserPlaceholder')}
        inputProps={{ maxLength: 64 }}
        sx={{ marginBottom: 2.5, height: '48px' }}
        error={isInvalidCredentials}
      />

      <CustomTextField
        data-testid="login-password-input"
        error={isInvalidCredentials}
        autoComplete="off"
        sx={{ marginBottom: 3.8, height: '48px' }}
        value={password}
        type="password"
        onChange={(event) => setPassword(event.target.value)}
        label={t('enterPass')}
        placeholder={t('enterPassPlaceholder')}
      />

      {loginApiErrorMsg && (
        <Grid item xs={12}>
          <Typography sx={{ color: 'red', marginBottom: '15px' }}>
            {loginApiErrorMsg}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {isInvalidCredentials && (
          <Typography sx={{ color: 'red', marginBottom: '15px' }}>
            {t('invalidUsernameOrPassword')}
          </Typography>
        )}
        {/* if this is a preregistered user trying to log in but they are still being processed / not yet verified by bizops */}
        {isPreregInProgress && (
          <PreregInProgressDialog
            open={isPreregInProgress}
            setOpen={setIsPreregInProgress}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid item container justifyContent="center">
          <Grid item xs={12}>
            <SButton
              data-testid="login-submit-button"
              fullWidth
              variant="outlined"
              sx={{
                height: '50px',
                color: COLORS.BUTTON_PRIMARY_TEXT,
                backgroundColor: COLORS.BUTTON_PRIMARY_BACKGROUND,
                '&:hover': {
                  backgroundColor: COLORS.BUTTON_PRIMARY_BACKGROUND_ACTIVE,
                },
                '&:disabled': {
                  color: COLORS.TEXT_GREY_LIGHT,
                  backgroundColor: COLORS.BACKGROUND_GREY_LIGHT,
                },
              }}
              disabled={!userLoginName || !password || isLoading}
              onClick={async () => {
                asyncLogin(userLoginName, password)
              }}
            >
              {isLoading && (
                <CircularProgress
                  size={14}
                  sx={{
                    color: COLORS.TEXT_GREY_LIGHT,
                    marginRight: 1,
                  }}
                />
              )}
              {t('login')}
            </SButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        gap={2}
        sx={{ marginTop: '20px' }}
      >
        <Box sx={{ display: 'flex' }}>
          <Link
            component="button"
            variant="body2"
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
              cursor: 'pointer',
            }}
            underline="none"
            onClick={() => {
              history.push(REG_ROUTE_FORGOT_USERNAME)
              gaService.trackEvent(gaService.eventMap.forgot_username)
            }}
          >
            {t('forgotUser')}
          </Link>
          <Typography sx={{ paddingX: '8px' }}>|</Typography>
          <Link
            component="button"
            variant="body2"
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
              cursor: 'pointer',
            }}
            underline="none"
            onClick={() => {
              history.push(REG_ROUTE_FORGOT_PASSWORD)
              gaService.trackEvent(gaService.eventMap.forgot_password)
            }}
          >
            {t('forgotPass')}
          </Link>
        </Box>
      </Grid>
      {!IS_PROD() && (
        <Grid
          item
          xs={12}
          container
          alignItems={'center'}
          sx={{ paddingTop: '10px' }}
        >
          <div>
            UAT Environment Details: You are connected to:{' '}
            {IS_DEV() ? 'DEV' : IS_INTEGRATION() ? 'INTEGRATION' : 'BLUE'}
          </div>
        </Grid>
      )}
    </>
  )
}

function RegOrPreregTabContent({
  selectedRegType,
  setSelectedRegType,
  onContinueRegister,
  onContinuePreRegister,
}: {
  selectedRegType: 'register' | 'preregister'
  setSelectedRegType: (selected: 'register' | 'preregister') => void
  onContinueRegister: () => void
  onContinuePreRegister: () => void
}) {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useSTranslate(['login', 'common'])

  const [forceTriggerTooltip, setForceTriggerTooltip] = useState(false)

  // const [animationSrc, setAnimatioSrc] = useState<string>(animeRegister)
  const [animationSrc, setAnimatioSrc] = useState<string>(animeRegister)
  useEffect(() => {
    if (selectedRegType === 'register') {
      setAnimatioSrc(animeRegister)
    } else {
      setAnimatioSrc(animePreRegister)
    }
  }, [selectedRegType])

  return (
    <Grid container sx={{}}>
      <Grid
        item
        xs={12}
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          //pull the image up a bit on desktop
          margingTop: '-20px',
          [theme.breakpoints.up('md')]: {
            marginTop: '-30px',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            // border: '1px solid black',
            alignSelf: 'center',
            paddingBottom: '20px',
            [theme.breakpoints.down('sm')]: {
              width: '180px',
              height: '180px',
            },
            [theme.breakpoints.up('sm')]: {
              width: '311px',
              height: '311px',
            },
          })}
        >
          <img
            alt="register-animation"
            data-testid="register-animation"
            src={animationSrc}
            width="100%"
            height="100%"
          />
        </Box>
      </Grid>

      <Grid item xs={12} container justifyContent={'center'}>
        <Grid
          item
          xs={12}
          container
          justifyContent={'center'}
          sx={{ paddingBottom: '20px' }}
        >
          {selectedRegType === 'register' && (
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '1rem',
                fontWeight: COMMON_BOLD_WEIGHT,
                lineHeight: '19.5px',
              }}
            >
              {t('regTimeMsg')}
            </Typography>
          )}
          {selectedRegType === 'preregister' && (
            <Box
              onClick={() => {
                setForceTriggerTooltip(true)
              }}
              sx={{
                display: 'flex',
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '1rem',
                  fontWeight: COMMON_BOLD_WEIGHT,
                  lineHeight: '19.5px',
                }}
              >
                {t('preregTimeMsg')}
                <Tooltip
                  arrow
                  title={
                    <Typography sx={{ fontSize: '1rem' }}>
                      {t('preregTooltip')}
                    </Typography>
                  }
                  placement={isSmall ? 'bottom' : 'right'}
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                >
                  <InfoIcon
                    data-testid="prereg-tooltip-icon"
                    sx={{
                      verticalAlign: 'middle',
                      cursor: 'pointer',
                      paddingLeft: '7px',
                      marginTop: '-5px',
                    }}
                    color="primary"
                    fontSize="small"
                  />
                </Tooltip>
              </Typography>
            </Box>
          )}

          {/* {selectedRegType === 'preregister' && <Grid item xs={1}></Grid>} */}
        </Grid>
        <Grid
          item
          sx={{
            padding: isSmall ? '5px' : '10px',
            marginRight: isSmall ? 'auto' : undefined,
          }}
        >
          <RegisterChip
            dataTestId="register-radio"
            label={t('chipRegisterNow')}
            selected={selectedRegType === 'register' ? true : false}
            onClick={() => {
              setSelectedRegType('register')
            }}
          />
        </Grid>
        <Grid
          item
          sx={{
            padding: isSmall ? '5px' : '10px',
            marginRight: isSmall ? 'auto' : undefined,
          }}
        >
          <RegisterChip
            dataTestId="preregister-radio"
            label={t('chipPreRegister')}
            selected={selectedRegType === 'preregister' ? true : false}
            onClick={() => {
              setSelectedRegType('preregister')
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent={'center'}
        sx={{ paddingTop: '20px', paddingBottom: '10px' }}
      >
        <SButton
          data-testid="continue-button"
          sx={{ width: isSmall ? '290px' : '320px' }}
          onClick={() => {
            if (selectedRegType === 'register') {
              onContinueRegister()
            } else {
              onContinuePreRegister()
            }
          }}
        >
          {t('btnContinue')}
        </SButton>
      </Grid>
    </Grid>
  )
}

function RegisterChip({
  label,
  selected,
  onClick,
  dataTestId,
}: {
  label: string
  selected: boolean
  onClick: () => void
  dataTestId: string
}) {
  const { COLORS } = useSColors()
  //box with 100px raidus and main purple color and white text, if active highlighted

  return (
    <Box
      sx={{
        cursor: 'pointer',
        borderRadius: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: selected
          ? COLORS.TEXT_LIGHT_PRIMARY_WHITE
          : COLORS.BACKGROUND_GREY_DARKER,
        border: '1px solid',
        // minWidth: '200px',
        padding: '0px 15px 0px 15px',
        height: '44px',
        maxHeight: '44px',
        backgroundColor: selected
          ? COLORS.PRIMARY_BRAND
          : COLORS.BACKGROUND_GREY_LIGHTER,
      }}
      data-testid={dataTestId}
      onClick={onClick}
    >
      <Radio
        sx={{
          padding: '0px',
          width: '12px',
          height: '12px',

          //if selected, background and color are white
          color: selected
            ? COLORS.TEXT_LIGHT_PRIMARY_WHITE
            : COLORS.BACKGROUND_GREY_DARKER,
          backgroundColor: selected
            ? COLORS.TEXT_LIGHT_PRIMARY_WHITE
            : 'transparent',
        }}
      />
      <Typography
        sx={{
          fontSize: '1rem',
          fontWeight: selected ? 600 : 400,
          paddingLeft: '10px',
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}
