import {
  Member,
  OneTimePassCode,
  RegistrationChallenge,
  RegistrationChallengeResponse,
  RegistrationData,
  RegistrationSetupData,
  StatusCode,
  Tenant,
  RequestIdentificationData,
  PasswordResetData,
  VerifyRegistrationIdentityResponse,
  VerifiedRegistrationSetupData,
  ApiUrl,
  isValidResponse,
  CreateMemberAccountData,
} from '../../types'
import {
  ExOps,
  getMicroserviceApiDetails,
  getModulesApiEndpoint,
  ScriptaAppLocation_Header_Value_Mobile,
  ScriptaAppLocation_Header_Value_Portal,
} from '../apiexec/portalapiexec'
import { usePublicPortalApi } from '../apiexec/usePortalApi'
import { OtpMethod } from './IdentityValidation'
import {
  createMemberAccount,
  findAccount,
  findTenant,
  registrationModule,
  sendVerificationOtp,
  verificationChallengeQuestions,
  verifyChallengeQuestionsResponse,
  verifyOtp,
  captureEmail,
  smsOptIn,
  findAccountFromQuickLink,
  verifyQuickLinkOtp,
  findMigratedMemberProfileFromQuickLink,
  completeProfile,
  // completePreregUserProfileEndpoint,
} from './module-config'
import {
  memberModule,
  authenticateMemberEndpoint,
  forgotUserName,
  forgotPassword,
  resetPassword,
  acceptMemberPolicy,
} from '../member/module-config'
import { useMemberAppContext } from '../MemberAppContext'
import { MemberPolicyAcceptData, MemberProfile } from '../../types'
import { MemberTOUAcceptData, QuickLinkFindAcccountReponse } from '../../types'
import { acceptMemberTOU } from '../member/module-config'
import { IS_NATIVE } from '../apiexec/utils'
import { isSSNRequired, PreRegForm, VerifyCodeResp } from './PreReg'
import { toServerDate } from '../helpmeswitch/types'
import { toDigitsOnly } from '../member/phone-field-utils'
import {
  RESPONSE_CODE_EMAIL_ALREADY_USED,
  RESPONSE_CODE_PHONE_ALREADY_USED,
} from './RegistrationErrorHandler'
import { LOG } from '../v2/applog'

const clientLogoEndpoint: ApiUrl = {
  endpoint: '/member-service/api/clients', //{clientUuid}/logo',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

const processPreregCodeEndpoint: ApiUrl = {
  endpoint: '/member-service/api/registration-codes',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

const savePreregFormEndpoint: ApiUrl = {
  endpoint: '/member-service/api/preregistered-members',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
const submitMemberSupportRequestEndpoint: ApiUrl = {
  endpoint: '/member-service/api/registration-support',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

export interface ClientNotFoundSupportRequest {
  'support-type': 'Company not found'
  'company-data': {
    'search-term': string
    'user-email': string | undefined
    'user-phone': string | undefined
  }
}
export interface MemberNotFoundSupportRequest {
  'support-type': 'Member not found'
  'member-data': {
    'client-name': string
    'client-id': string
    'user-email': string
    'user-phone': string
    'identification-type':
      | 'MEMBER_ID'
      | 'EMPLOYEE_ID'
      | 'SOCIAL_SECURITY_NUMBER'
    'first-name': string
    'last-name': string
    dob: string
    'identification-value': string
  }
}

export function useRegistrationService() {
  const { portalPublicApi } = usePublicPortalApi()
  const { setJwt } = useMemberAppContext()
  const { langCodeSelectedOnLoginScreen } = useMemberAppContext()

  async function submitCompanyNotFoundSupportRequest(
    supportRequestData:
      | ClientNotFoundSupportRequest
      | MemberNotFoundSupportRequest,
  ): Promise<void | StatusCode> {
    let { url, ops } = getMicroserviceApiDetails(
      submitMemberSupportRequestEndpoint,
    )

    return portalPublicApi.postMicro<any>(url, supportRequestData, ops)
  }

  async function getClientLogoUrl(
    clientUuid: string,
    clientChildCompanyId?: number,
  ) {
    let { url, ops } = getMicroserviceApiDetails(clientLogoEndpoint)

    //if we have a child compan id, then we need to append it to the url
    ///member-service/api/clients/06eb246f-539e-11ee-b0d2-163c10fd62ef/child-companies/1/logo
    let finalUrl = `${url}/${clientUuid}/logo`

    if (clientChildCompanyId) {
      finalUrl = `${url}/${clientUuid}/child-companies/${clientChildCompanyId}/logo`
    }
    return portalPublicApi.getMicro<Blob>(finalUrl, ops)
  }

  async function processPreregistrationCode(
    code: string,
    isManualCodeEntry: boolean,
  ) {
    let { url, ops } = getMicroserviceApiDetails(processPreregCodeEndpoint)
    let finalUrl = `${url}/${code}/verify`
    //custom headers for backend analytics tracking - if it was manual vs link
    const HEADER_SOURCE = isManualCodeEntry
      ? 'MEMBER_PORTAL_ACCESS'
      : 'EMAIL_LINK'
    ops = {
      ...ops,
      customHeaders: { ...ops?.customHeaders, source: HEADER_SOURCE },
    }

    return portalPublicApi.getMicro<VerifyCodeResp>(finalUrl, ops)
  }
  async function savePreregistrationForm(regForm: PreRegForm) {
    let { url, ops } = getMicroserviceApiDetails(savePreregFormEndpoint)

    //first remove all the UI only fields
    const intermediateData: Partial<PreRegForm> = { ...regForm }
    delete intermediateData['client-uuid']
    delete intermediateData['is-same-as-member-id-ui-check']
    delete intermediateData['dob-ui-format']
    delete intermediateData['confirmedPassword']
    delete intermediateData['acceptedFinalTermsAndConditions']

    //if subscriber-ssn is empty, delete it from payload
    if (!intermediateData['subscriber-ssn']) {
      delete intermediateData['subscriber-ssn']
    }

    //if SSN is not required - it will be hidden in the UI but we need it in the backend as 999 99 9999
    if (!isSSNRequired(regForm['client-uuid'])) {
      intermediateData['subscriber-ssn'] = '999999999'
      intermediateData['ssn'] = '999999999'
    }

    delete intermediateData.specificallyConfirmedOptOutInDialog

    let backendPayload: any = {
      ...intermediateData,
    }

    function toNumberDigitsOnly(num: string) {
      if (!num) return num
      return num.replace(/\D/g, '')
    }

    // delete backendPayload.accepted
    backendPayload['dob'] = toServerDate(regForm['dob-ui-format'])
    backendPayload['mobile'] = toNumberDigitsOnly(regForm['mobile'])
    //append street2 to street1 with comma before sending to backend
    if (regForm['street-address2']) {
      backendPayload['street-address'] =
        backendPayload['street-address'] + ', ' + regForm['street-address2']
    }
    delete backendPayload['street-address2']

    const HEADER_CLIENT_ID = regForm['client-uuid']

    ops = {
      ...ops,
      customHeaders: { ...ops?.customHeaders, id: HEADER_CLIENT_ID },
    }

    // let finalUrl = `${url}/${code}`
    return portalPublicApi.postMicro<any>(url, backendPayload, ops)
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => resolve({ 'client-id': 123 }), 3000)
    // })
  }

  async function findTenantApi(
    registrationData: RegistrationData,
  ): Promise<Tenant | StatusCode> {
    const url = getModulesApiEndpoint(registrationModule, findTenant)
    return portalPublicApi.post<Tenant | StatusCode>(url, registrationData)
  }

  async function findTenantMemberFromQuickLinkApi(
    quickLinkCode: string,
  ): Promise<QuickLinkFindAcccountReponse | StatusCode> {
    const url =
      getModulesApiEndpoint(registrationModule, findAccountFromQuickLink) +
      '?quickLinkCode=' +
      quickLinkCode
    return portalPublicApi.get<QuickLinkFindAcccountReponse | StatusCode>(url)
  }

  async function validateQuickLinkOtpApi(
    quickLinkCode: string,
    quickLinkOtp: string,
  ): Promise<QuickLinkFindAcccountReponse | StatusCode> {
    const url = `${getModulesApiEndpoint(
      registrationModule,
      verifyQuickLinkOtp,
    )}?quickLinkCode=${quickLinkCode}&quickLinkOtp=${quickLinkOtp}`
    return portalPublicApi.get<QuickLinkFindAcccountReponse | StatusCode>(url)
  }

  async function authenticateMemberApi(
    userName: string,
    password: string,
    whatToDoWithJwt: 'initToContext' | 'returnAsString',
  ): Promise<void | StatusCode | string> {
    const url = getModulesApiEndpoint(memberModule, authenticateMemberEndpoint)

    //we haveto process separatelly this network request becase its returning both json data as well
    //as optional auth headers
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ userName, password }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ScriptaAppLocation: IS_NATIVE()
          ? ScriptaAppLocation_Header_Value_Mobile
          : ScriptaAppLocation_Header_Value_Portal,
      },
    })
    //check http level response is ok first
    if (response.ok) {
      //extract the authorization header from the raw response
      const jwtToken =
        response && response.headers
          ? response.headers.get('Authorization')
          : undefined

      if (jwtToken) {
        if (whatToDoWithJwt === 'initToContext') {
          setJwt(jwtToken)
        } else if (whatToDoWithJwt === 'returnAsString') return jwtToken
      }
      //then return the optional json for further processing (i.e. scripta statusCodes, memberprofile etc)
      return await response.json()
    } else {
      //unforunatelly, backend mixes up http status codes but still returns json data with scripta codes...
      try {
        return await response.json()
      } catch (error) {
        //TODO - can this happen?
        console.error('Error reading json from auth call')
      }
    }
  }

  async function findAccountApi(
    registrationData: RegistrationData,
  ): Promise<Member | StatusCode> {
    const url = getModulesApiEndpoint(registrationModule, findAccount)
    return portalPublicApi.post<Member | StatusCode>(url, registrationData)
  }

  async function sendVerificationOtpApi(
    method: OtpMethod,
    memberId: number,
    tenantId: number,
  ): Promise<Member | StatusCode> {
    const url = getModulesApiEndpoint(registrationModule, sendVerificationOtp)
    const withmethod = url.replace('{method}', method)

    //sending otp now supports spanish
    //send username now supports language header
    let ops: ExOps | undefined = undefined
    if (langCodeSelectedOnLoginScreen) {
      ops = {
        langCode: langCodeSelectedOnLoginScreen,
      }
    }
    const payload: Partial<RegistrationSetupData> = {
      memberId: memberId,
      tenantId: tenantId,
    }

    return portalPublicApi.post<Member | StatusCode>(withmethod, payload, ops)
  }

  async function verifyRegistrationOtpApi(
    otp: OneTimePassCode,
  ): Promise<VerifyRegistrationIdentityResponse | StatusCode> {
    const url = getModulesApiEndpoint(registrationModule, verifyOtp)

    return portalPublicApi.post<
      VerifyRegistrationIdentityResponse | StatusCode
    >(url, otp)
  }

  async function loadSecurityQuestionsDataApi(
    member: Member,
  ): Promise<Member | StatusCode> {
    const url = getModulesApiEndpoint(
      registrationModule,
      verificationChallengeQuestions,
    )
    return portalPublicApi.post<RegistrationChallenge | StatusCode>(url, member)
  }

  async function verifyChallengeQuestionsApi(
    regResponses: RegistrationChallengeResponse,
  ): Promise<VerifyRegistrationIdentityResponse | StatusCode> {
    const url = getModulesApiEndpoint(
      registrationModule,
      verifyChallengeQuestionsResponse,
    )
    return portalPublicApi.post<
      VerifyRegistrationIdentityResponse | StatusCode
    >(url, regResponses)
  }

  async function captureEmailApi(
    tenantId: number,
    memberId: number,
    email: string,
  ): Promise<void | StatusCode> {
    const paylod: Partial<RegistrationSetupData> = {
      tenantId: tenantId,
      memberId: memberId,
      email: email,
    }
    const url = getModulesApiEndpoint(registrationModule, captureEmail)
    return portalPublicApi.post<void | StatusCode>(url, paylod)
  }

  async function checkEmailAvailableApi(
    tenantId: number,
    memberId: number,
    email: string,
  ): Promise<boolean> {
    //lets just assume its availalbe, unless we were specifically get a dupe error code
    //this is just for ui validation as the user types
    //once we get field-validation api to be refactored
    let isAvailable = true
    try {
      const paylod: Partial<RegistrationSetupData> = {
        tenantId: tenantId,
        memberId: memberId,
        email: email,
      }
      let url = getModulesApiEndpoint(registrationModule, captureEmail)
      let availableUrl = url + '?checkAvailableOnly=true'
      const resp = await portalPublicApi.post<void | StatusCode>(
        availableUrl,
        paylod,
      )
      if (!isValidResponse(resp)) {
        if (resp && (resp as any).code === RESPONSE_CODE_EMAIL_ALREADY_USED) {
          isAvailable = false
        }
      }
    } catch (error) {
      LOG.error('registration', 'Error checking email availability', {
        error,
      })
    }
    return isAvailable
  }

  async function captureSmsOptInApi(
    tenantId: number,
    memberId: number,
    mobilePhone: string,
  ): Promise<void | StatusCode> {
    const paylod: Partial<RegistrationSetupData> = {
      tenantId: tenantId,
      memberId: memberId,
      mobilePhone: mobilePhone,
    }
    const url = getModulesApiEndpoint(registrationModule, smsOptIn)
    return portalPublicApi.post<void | StatusCode>(url, paylod)
  }

  async function checkPhoneAvailableApi(
    tenantId: number,
    memberId: number,
    phoneNumber: string,
  ): Promise<boolean> {
    //lets just assume its availalbe, unless we were specifically get a dupe error code
    //this is just for ui validation as the user types
    //once we get field-validation api to be refactored
    let isAvailable = true
    try {
      //add country code 1 if needed for server phone
      let digitsOnly = toDigitsOnly(phoneNumber)

      const finalPhoneWithCountryCode =
        digitsOnly && digitsOnly.length === 10
          ? `1${digitsOnly}`
          : `${digitsOnly}`

      const paylod: Partial<RegistrationSetupData> = {
        tenantId: tenantId,
        memberId: memberId,
        mobilePhone: finalPhoneWithCountryCode,
      }
      let url = getModulesApiEndpoint(registrationModule, smsOptIn)
      let availableUrl = url + '?checkAvailableOnly=true'
      const resp = await portalPublicApi.post<void | StatusCode>(
        availableUrl,
        paylod,
      )
      if (!isValidResponse(resp)) {
        if (resp && (resp as any).code === RESPONSE_CODE_PHONE_ALREADY_USED) {
          isAvailable = false
        }
      }
    } catch (error) {
      LOG.error('registration', 'Error checking phone availability', {
        error,
      })
    }
    return isAvailable
  }

  async function createMemberAccountApi(
    createData: CreateMemberAccountData,
  ): Promise<void | StatusCode> {
    const url = getModulesApiEndpoint(registrationModule, createMemberAccount)
    return portalPublicApi.post<void | StatusCode>(url, createData)
  }

  //NOTE here - this API is shared between both registartion flows and standard app
  //it is defined in memberModule (not registrationModule)
  async function memberAcceptPolicyApi(
    acceptPolicyData: MemberPolicyAcceptData,
  ): Promise<any | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, acceptMemberPolicy)
    return portalPublicApi.post<void | StatusCode>(url, acceptPolicyData)
  }
  async function memberAcceptTOUApi(
    acceptTOUData: MemberTOUAcceptData,
  ): Promise<any | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, acceptMemberTOU)
    return portalPublicApi.post<void | StatusCode>(url, acceptTOUData)
  }

  async function forgotUsernameApi(email: string): Promise<any | StatusCode> {
    //NOTE below we read this one from member module,not registration
    //this is where it was defined by RG, but in UI we need jwt in order to instantiate useMemberService
    const url = getModulesApiEndpoint(memberModule, forgotUserName)
    //actualy payload is regsetupd data with just email
    const forgotUsernamePayload: Partial<RegistrationSetupData> = {
      email: email,
    }
    //send username now supports language header
    let ops: ExOps | undefined = undefined
    if (langCodeSelectedOnLoginScreen) {
      ops = {
        langCode: langCodeSelectedOnLoginScreen,
      }
    }
    return portalPublicApi.post(url, forgotUsernamePayload, ops)
  }
  async function forgotPasswordApi(
    pwdResetData: PasswordResetData,
  ): Promise<PasswordResetData | StatusCode> {
    //NOTE same - we read these from the memberModule
    //but use in in reg service whichd oesn use jwt
    //below we read this one from member module, not registration
    //this is where it was defined by RG, but in UI we need jwt in order to instantiate useMemberService
    const url = getModulesApiEndpoint(memberModule, forgotPassword)
    //send password now supports language header
    let ops: ExOps | undefined = undefined
    if (langCodeSelectedOnLoginScreen) {
      ops = {
        langCode: langCodeSelectedOnLoginScreen,
      }
    }
    return portalPublicApi.post<PasswordResetData>(url, pwdResetData, ops)
  }
  async function resetPasswordApi(
    pwdResetData: PasswordResetData,
  ): Promise<void | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, resetPassword)
    return portalPublicApi.post(url, pwdResetData)
  }

  return {
    findTenantApi,
    findAccountApi,
    findTenantMemberFromQuickLinkApi,
    sendVerificationOtpApi,
    verifyRegistrationOtpApi,
    loadSecurityQuestionsDataApi,
    verifyChallengeQuestionsApi,
    validateQuickLinkOtpApi,
    createMemberAccountApi,
    authenticateMemberApi,
    captureEmailApi,
    captureSmsOptInApi,
    forgotUsernameApi,
    forgotPasswordApi,
    resetPasswordApi,
    memberAcceptPolicyApi,
    memberAcceptTOUApi,
    processPreregistrationCode,
    savePreregistrationForm,
    getClientLogoUrl,
    submitCompanyNotFoundSupportRequest,
    checkEmailAvailableApi,
    checkPhoneAvailableApi,
  }
}
