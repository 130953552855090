import { Theme } from '@mui/material'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const useChangePasswordStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerTextCollapsed: {
      fontSize: '.8rem',
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
      },
    },
    headerTextExpanded: {
      fontSize: '1rem',
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.45rem',
      },
    },
    passwordPaperContainerMobile: {
      maxWidth: '100%',
      boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      padding: '30px 37px 30px 37px',
      margin: '0px',
    },
    passwordPaperContainer: {
      maxWidth: '440px',
      boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      padding: '30px 37px 30px 37px',
      margin: '20px',
    },
    changePasswordButton: {
      marginBottom: '8px',
    },
    srOnly: {
      position: 'absolute',
      width: '1px',
      height: '1px',
      padding: 0,
      margin: '-1px',
      overflow: 'hiden',
      clip: 'rect(0,0,0,0)',
      border: 0,
      fontSize: '0px',
    },
  }),
)

export const useSmsOptInStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerText: {
      fontSize: '1rem',
      textAlign: 'left',
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.45rem',
      },
    },

    headerTextCollapsed: {
      fontSize: '.8rem',
      textAlign: 'left',
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.15rem',
      },
    },

    smsNotifications: {
      fontSize: '.8rem',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1rem',
      },
    },

    smsNotificationsIcon: {
      fontSize: '1.25rem',
      marginTop: '5px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.5rem',
      },
    },

    smsPaperContainer: {
      padding: '20px 20px 10px 20px',
      margin: '30px 25px 25px 25px',
      height: '90%',
      [theme.breakpoints.up('sm')]: {
        padding: '20px',
      },
    },
    smsPaperCollapsed: {
      padding: '20px 20px 0px 20px',
      margin: '30px 25px 25px 25px',
      height: '68px',
    },
    srOnly: {
      position: 'absolute',
      width: '1px',
      height: '1px',
      padding: 0,
      margin: '-1px',
      overflow: 'hiden',
      clip: 'rect(0,0,0,0)',
      border: 0,
      fontSize: '0px',
    },
  }),
)

export const useMyAccountStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainPaperContainer: {
      padding: '0px',
      margin: '10px',
      marginBottom: '30px',
      [theme.breakpoints.up(542)]: {
        padding: '20px',
        margin: '30px',
      },
    },
    innerPaperContainer: {
      padding: '12px',
      margin: '10px',
      height: '90%',
      [theme.breakpoints.up(542)]: {
        padding: '20px',
      },
    },
    innerText: {
      fontSize: '.85rem',
      fontWeight: 400,
      lineHeight: 1.5,
      [theme.breakpoints.up(542)]: {
        fontSize: '1rem',
      },
      color: '#201F22',
    },
    sectionTitle: {
      fontWeight: 700,
      fontSize: 18,
      color: '#201F22',
    },
    paperContainerMobile: {
      maxWidth: '100%',
      boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      padding: '30px 37px 30px 37px',
      margin: '0px',
      width: '100%',
    },
    paperContainer: {
      maxWidth: '440px',
      boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      padding: '30px 37px 30px 37px',
      margin: '20px',
      width: '100%',
    },
  }),
)
