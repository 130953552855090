import { PortalAppConfig } from '../app-root-types'
export const DEFAULT_PORTAL_APP_CONFIG: PortalAppConfig = {
  FAQ_URL: 'https://www.scriptainsights.com/faqs-english-spanish',
  PRIVACY_POLICY_URL: 'https://www.scriptainsights.com/privacy',
  TERMS_AND_CONDITIONS_URL: 'https://www.scriptainsights.com/terms-of-use',

  MEMBER_SUPPORT_NUMBER: '1-866-572-7478',
  MEMBER_SUPPORT_EMAIL: 'help@scriptainsights.com',
  TIMEOUT_MILIS: 300000, //5 minutes
  TESTING_TIMEOUT_MILIS: 300000, //
  // TESTING_TIMEOUT_MILIS: 2000, //
}

export const AMAZON_PORTAL_APP_CONFIG: PortalAppConfig = {
  ...DEFAULT_PORTAL_APP_CONFIG,
  //888 7379799
  MEMBER_SUPPORT_NUMBER: '1-888-737-9799',
  MEMBER_SUPPORT_EMAIL: 'rxaidassist@scriptainsights.com',
}
