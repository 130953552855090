import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { IS_DEV, IS_NATIVE, getUrlParameter } from '../apiexec/utils'
import { ROUTE_APP_ROOT } from '../PortalRoutes'
import { usePortalAppConfig } from '../config/usePortalAppConfig'
import DataNotFoundImage from './../../images/data_not_found.png'
import OfflineLogo from '../../images/logo.png'
import { useMemberAppContext } from '../MemberAppContext'
import history from 'src/app/scripta-browser-history'
import { REG_ROUTE_LOGIN } from '../registration/RegistrationSubRoutes'
import { useLocation } from 'react-router-dom'
import { URL_PARAM_MOBILE_APP_VERSION } from './ForceUpgradeDialog'

export function GenericErrorPage({
  additionalDetails,
  //extract from the url in the case of context error handler so we can maintain it when user clicks back to home page
  mobileVersionToRedirectTo,
}: {
  additionalDetails?: string
  mobileVersionToRedirectTo?: string
}) {
  const PORTAL_APP_CONFIG = usePortalAppConfig()
  const location = useLocation()

  const { t } = useSTranslate(['application'])
  const { mobileVersion } = useMemberAppContext()
  //also enable some additional details when routing via react router
  const [additionalRoutedErrorDetails, setAdditionalRoutedErrorDetails] =
    useState<string | undefined>()
  useEffect(() => {
    document.title = 'Something Went Wrong'
  })
  useEffect(() => {
    if (
      location.state &&
      (location.state as any).additionalRoutedErrorDetails
    ) {
      setAdditionalRoutedErrorDetails(
        (location.state as any).additionalRoutedErrorDetails,
      )
    }
  }, [location])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        // border: '1px solid black',
        // width: '70vw',
      }}
    >
      <div>
        <img alt="Logo" src={OfflineLogo} width="240px" height="80px" />
      </div>
      <div>
        <img
          alt="Error Page"
          src={DataNotFoundImage}
          width="150px"
          height="150px"
        ></img>
      </div>
      <div
        style={{
          marginTop: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          fontFamily: 'Proxima Nova,Roboto',
        }}
      >
        {t('somethingWentWrong')}
      </div>
      {additionalRoutedErrorDetails && (
        <div
          style={{
            marginTop: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            fontFamily: 'Proxima Nova,Roboto',
          }}
        >
          {t('application:additionalDetails')} {additionalRoutedErrorDetails}
        </div>
      )}
      {additionalDetails && (IS_DEV() || IS_NATIVE()) && (
        <div
          style={{
            marginTop: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            fontFamily: 'Proxima Nova,Roboto',
          }}
        >
          {t('application:additionalDetails')} {additionalDetails}
        </div>
      )}
      <div style={{ marginTop: '20px', fontFamily: 'Proxima Nova,Roboto' }}>
        <button
          onClick={() => {
            let targetUrl = REG_ROUTE_LOGIN
            let versionParam = undefined
            //check if given to component in case of context level errors
            if (mobileVersionToRedirectTo) {
              versionParam = mobileVersionToRedirectTo
              //otherwise check if its in context in case of component level errors
            } else if (mobileVersion && typeof mobileVersion === 'string') {
              versionParam = mobileVersion
            }
            if (versionParam) {
              targetUrl =
                targetUrl +
                '?' +
                URL_PARAM_MOBILE_APP_VERSION +
                '=' +
                versionParam
            }

            ;(window as Window).location = targetUrl as any
          }}
        >
          {t('homePage')}
        </button>
      </div>
      <div
        style={{
          fontSize: '1.5rem',
          marginTop: '20px',
          fontFamily: 'Proxima Nova,Roboto',
        }}
      >
        {t('application:memberSupport')}
        {PORTAL_APP_CONFIG.MEMBER_SUPPORT_NUMBER}
      </div>
    </div>
  )
}
