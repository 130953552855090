import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const useCommonRadioStyles = makeStyles((theme: Theme) => {
  return createStyles({
    radioLabelText: {
      fontSize: '0.85rem',
      fontWeight: 400,
    },

    radioLabels: {
      fontSize: '1.125rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '.8rem',
      },
    },
  })
})
