import TextField from '@mui/material/TextField'
import { useState, useEffect, useCallback } from 'react'
import { validateEmail } from './EditContactInfo'
import { Typography, Grid, useTheme, useMediaQuery } from '@mui/material'
import { RegistrationToolbar } from './RegistrationToolbar'
import { useRegistrationService } from './useRegistrationService'
import { isValidResponse } from '../../types'
import {
  REG_ROUTE_ERROR_HANDLER,
  REG_ROUTE_LOGIN,
} from './RegistrationSubRoutes'
import { useNotifier } from '../hooks/useNotify'
import { useRegistration } from './RegistrationContext'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { GAService } from '../application/ga/GAService'

export function ForgotUsername() {
  const gaService = new GAService()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [email, setEmail] = useState('')
  const regService = useRegistrationService()
  const { history, setApiResponse } = useRegistration()
  const { sendMsg } = useNotifier()
  const { t } = useSTranslate(['register', 'account'])

  const [isValidEmail, setIsValidEmail] = useState<boolean>()
  useEffect(() => {
    if (email) {
      if (validateEmail(email)) {
        setIsValidEmail(true)
      } else {
        setIsValidEmail(false)
      }
    }
  }, [email])

  useEffect(() => {
    document.title = 'Forgot Username - Scripta Insights'
  })

  async function asyncSendUserName(email: string) {
    try {
      const resp = await regService.forgotUsernameApi(email)

      if (isValidResponse<any>(resp)) {
        sendMsg(t('forgotUsernameSent'), 'success', 20000)
        setTimeout(() => {
          history.push(REG_ROUTE_LOGIN)
        }, 10)
      } else {
        setApiResponse(resp)
        history.push(REG_ROUTE_ERROR_HANDLER)
      }
    } catch (e) {
      throw new Error('error in api execution')
    }
  }

  const doSendUsername = useCallback(() => {
    asyncSendUserName(email)
  }, [email])

  return (
    <Grid
      item
      xs={12}
      container
      gap={isSmall ? '16px' : '32px'}
      style={{ padding: '30px' }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          {t('forgotUsernameTitle')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: 400,
            textAlign: 'center',
            lineHeight: 'normal',
          }}
        >
          {t('forgotUsernameDisclaimer')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          variant="outlined"
          id="email"
          fullWidth
          name="email"
          placeholder={t('enterEmail')}
          label={t('enterEmail')}
          value={email}
          error={email && !isValidEmail ? true : false}
          required
          helperText={email && !isValidEmail ? t('account:validateEmail') : ''}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <RegistrationToolbar
          onClickNext={() => {
            doSendUsername()
            gaService.trackEvent(gaService.eventMap.forgot_username_submit)
          }}
          customNextLabel={t('sendUsername')}
          nextDisabled={isValidEmail ? false : true}
          useBackLabelForCancel={false}
          sidePadding={isSmall ? undefined : '20%'}
        />
      </Grid>
    </Grid>
  )
}
