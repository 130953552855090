import React, { useEffect, useState } from 'react'
import { useRegistrationService } from '../registration/useRegistrationService'
import { useMemberAppContext } from '../MemberAppContext'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { Box } from '@mui/material'
import { LOG } from '../v2/applog'
export function ClientLogo({ isSmall }: { isSmall: boolean }) {
  //force hide

  const regService = useRegistrationService()
  const { clientUuid, clientIdInRegistration, clientLogoChildCompanyId } =
    useMemberAppContext()

  const [logoUrl, setLogoUrl] = useState<string | undefined>()
  //show white box placeholder until logo is fetched
  const [isLogoFetched, setIsLogoFetched] = useState<boolean>(false)

  async function loadLogo() {
    if (!clientUuid) {
      setLogoUrl(undefined)
      return
    }

    const msResp = await regService.getClientLogoUrl(
      clientUuid,
      clientLogoChildCompanyId,
    )
    const msStatus = checkMicroServiceResponseStatus(msResp)
    if (!msStatus.isError) {
      const url = msResp.logo
      setLogoUrl(url)
      setIsLogoFetched(true)
    } else {
      console.error(
        'Error fetching logo url for clientUuid = ,MS resp=' + clientUuid,
        msResp,
      )
      LOG.error(
        'generic_error',
        'error fetching logo for clientIdInRegistration ' +
          clientIdInRegistration +
          ' via client public UUID = ' +
          clientUuid,
        msResp,
      )
    }
  }
  useEffect(() => {
    try {
      loadLogo()
    } catch (error) {
      console.error('error fetching company logo...') //, error)
    }
  }, [clientUuid])

  //dont even try to take up space, there is no logo possible
  if (!clientUuid) {
    return null
  }

  // if (!logoUrl || !isLogoFetched) {
  //   return null
  // }
  return (
    // <Box display={'flex'} justifyContent={'center'}>
    <Box
      display={'flex'}
      sx={
        {
          // backgroundColor: 'green',
          // flexDirection: 'column',
          // paddingLeft: '20px', paddingTop: '10px'
        }
      }
    >
      <Box
        sx={{
          display: 'flex',
          // backgroundColor: 'red',
          marginLeft: '15px',
          paddingTop: isSmall ? '0px' : '10px',
          // paddingTop: '10px',
          maxWidth: '25%',
          height: 'auto',
          maxHeight: isSmall ? '40px' : '50px',
          minHeight: isSmall ? '40px' : '50px',
        }}
      >
        {logoUrl && isLogoFetched && (
          <img
            src={logoUrl}
            alt="Company Logo."
            style={{
              alignSelf: 'center',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        )}
      </Box>
    </Box>
  )
}
