import {
  Divider,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import {
  Member,
  RegistrationChallenge,
  isServerErrorResponse,
  isValidResponse,
} from '../../types'
// import { usePortalStyles } from '../styles/portal-styles'
import { useMemberAppContext } from '../MemberAppContext'
import { GAService } from '../application/ga/GAService'
import { DualBottomButtonToolbar } from './DualBottomButtonActionToolbar'
import { useRegistration } from './RegistrationContext'
import {
  REG_ROUTE_CHALLENGE_QUESTIONS,
  REG_ROUTE_CONFIRM_OTP_REGISTRATION,
  REG_ROUTE_ERROR_HANDLER,
  REG_ROUTE_REGISTER_TENANT_ACCOUNT,
  REG_ROUTE_SERVER_ERROR,
} from './RegistrationSubRoutes'
import { RegistrationStepCard } from './components/RegistrationStepCard'
import { useRegistrationService } from './useRegistrationService'
import { LOG } from '../v2/applog'
import { STrans, useSTranslate } from 'src/app/hooks/useSTranslate'
export type OtpMethod = 'phone' | 'email'

export function IdentityValidationSubflow() {
  const {
    member,
    // registrationData,
    history,
    setMember,
    setApiResponse,
    setRegChallenge,
    setIdentityValidationOtpMethodUsed,
  } = useRegistration()
  const { isFirstTimePreregMemberAccess } = useMemberAppContext()

  const { quickLinkCode } = useMemberAppContext()
  const gaService = new GAService()
  const service = useRegistrationService()
  const { t } = useSTranslate(['register', 'common', 'prereg'])

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  const [isEmailDeliveryEnabled, setIsEmailDeliveryEnabled] = useState(
    !isNullOrEmpty(member?.enrollmentEmail),
  )
  const [isPhoneDeliveryEnabled, setIsPhoneDeliveryEnabled] = useState(
    !isNullOrEmpty(member?.enrollmentMobilePhone),
  )

  const [isSecurityQuestionsEnabled, setIsSecurityQuestionsEnabled] =
    useState(false)

  const [otpMethod, setOtpMethod] = useState<OtpMethod | undefined>(
    isEmailDeliveryEnabled
      ? 'email'
      : isPhoneDeliveryEnabled
      ? 'phone'
      : undefined,
  )

  // const isMissingContactInfo: boolean =
  //   isNullOrEmpty(member?.enrollmentEmail) &&
  //   isNullOrEmpty(member?.enrollmentEmail)

  //automatically send to use security quetisons if neitehr is available
  useEffect(() => {
    if (!isEmailDeliveryEnabled && !isPhoneDeliveryEnabled) {
      // setOtpMethod('security_questions')
      if (isSecurityQuestionsEnabled) {
        doMoveToAnswerSecurityQuestions()
      }
    }
  }, [
    isEmailDeliveryEnabled,
    isPhoneDeliveryEnabled,
    isSecurityQuestionsEnabled,
  ])

  async function asyncSendOtp(
    method: OtpMethod,
    memberId: number,
    tenantId: number,
  ) {
    try {
      const resp = await service.sendVerificationOtpApi(
        method,
        memberId,
        tenantId,
      )

      if (isServerErrorResponse<Member>(resp)) {
        LOG.error('registration', 'server error in send otp api', resp)
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<Member>(resp)) {
        setApiResponse(resp)
        setMember({ ...member, ...resp })
        setIdentityValidationOtpMethodUsed(method)
        history.push(REG_ROUTE_CONFIRM_OTP_REGISTRATION)
      } else {
        LOG.error('registration', 'error in send otp api', resp)
        setApiResponse(resp)
        history.push(REG_ROUTE_ERROR_HANDLER)
      }
    } catch (e) {
      LOG.error('registration', 'error in send otp api execution', e)
      throw new Error('error in api execution')
    }
  }

  async function asyncLoadSecurityQuestions(member: Member) {
    try {
      const resp = await service.loadSecurityQuestionsDataApi(member)

      if (isServerErrorResponse<RegistrationChallenge>(resp)) {
        LOG.error(
          'registration',
          'server error in load security questions api',
          resp,
        )
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<RegistrationChallenge>(resp)) {
        setApiResponse(resp)
        setRegChallenge(resp)
        setIsSecurityQuestionsEnabled(true)
      } else {
        LOG.warn(
          'registration',
          'No security questions found for member. That option for identity validation will not be available',
          resp,
        )
        setApiResponse(resp)
        setIsSecurityQuestionsEnabled(false)
      }
    } catch (e) {
      LOG.error(
        'registration',
        'error in load security questions api execution',
        e,
      )
      throw new Error('error in api execution')
    }
  }
  //need to load security questions for member in order to enable/disable radio choice
  //if they are not found
  useEffect(() => {
    if (!member) return
    asyncLoadSecurityQuestions(member)
  }, [member])

  const doMoveToAnswerSecurityQuestions = useCallback(() => {
    if (!member) {
      LOG.error('registration', 'cannot challenge without member object')
      throw Error('cannot challenge without member objcet..')
    }

    //questions (if available), have already been loaded
    //the radio will not be visible if its not available
    history.push(REG_ROUTE_CHALLENGE_QUESTIONS)
    // asyncLoadSecurityQuestions(member)
  }, [member])

  function isNullOrEmpty(val?: string) {
    return val === undefined || val == null || val.length <= 0 ? true : false
  }

  let isIdentificationNotPossible = false
  if (
    !isEmailDeliveryEnabled &&
    !isPhoneDeliveryEnabled &&
    !isSecurityQuestionsEnabled
  ) {
    isIdentificationNotPossible = true
  }

  return (
    <RegistrationStepCard
      registrationType="registration"
      //custom toolbar
      disableToolbar={true}
      progressBarStep={3}
      customToolbarComponent={
        // <Box
        //   sx={{
        //     backgroundColor: 'green',
        //     width: '100%',
        //     minWidth: '100%',
        //     minHeight: '50px',
        //   }}
        // >
        <DualBottomButtonToolbar
          noText={t('register:back')}
          yesText={t('register:continue')}
          onClickNo={() => {
            history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)
            // history.goBack()
          }}
          onClickYes={async () => {
            if (otpMethod && member && member.id && member.tenantId) {
              asyncSendOtp(otpMethod, member?.id, member?.tenantId)
            } else {
              LOG.error(
                'registration',
                "can't send otp in click handler, missing member data",
              )
            }
          }}
          noHidden={quickLinkCode !== undefined}
          yesDisabled={isIdentificationNotPossible}
        />
        // </Box>
      }
    >
      <Grid item xs={12}>
        <Typography
          data-testid="identity-validation-page-title"
          sx={{
            textAlign: 'center',
            fontSize: isSmall ? '1.125rem' : '1.5rem',
            fontWeight: 700,
            paddingBottom: '20px',
            paddingTop: '0px',
            // marginTop: isSmall ? '-15px' : '0px',
            lineHeight: '21.92px',
          }}
        >
          {isFirstTimePreregMemberAccess
            ? t('prereg:helpUsVerifyYourIdentity')
            : t('whereShouldWeSendCode')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'center',
            paddingLeft: isSmall ? '20px' : undefined,
            paddingRight: isSmall ? '20px' : undefined,
          }}
        >
          {t('forYourProtection')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: '30px' }}>
        {!isIdentificationNotPossible && (
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
              textAlign: 'left',
              paddingLeft: '20px ',
            }}
          >
            {t('forYourProtectionChooseMethod')}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sx={{ paddingLeft: '20px' }}>
        <RadioGroup
          style={{ marginTop: '0px' }}
          aria-label="Verify By"
          name="otpMethod"
          value={otpMethod}
          onChange={(e) => {
            if (e.target.value === 'security_questions') {
              doMoveToAnswerSecurityQuestions()
              // history.push(REG_ROUTE_CHALLENGE_QUESTIONS)
            } else {
              setOtpMethod(e.target.value as any)
            }
          }}
        >
          {isPhoneDeliveryEnabled && (
            <FormControlLabel
              value="phone"
              sx={{ padding: '0px' }}
              control={
                <Radio
                  data-testid="phone-verification-radio-button"
                  color="primary"
                  sx={{ padding: '7px' }}
                />
              }
              disabled={isNullOrEmpty(member?.enrollmentMobilePhone)}
              label={<Typography>{t('methodText')}</Typography>}
              // placeholder={t('methodText')}
            />
          )}
          {isEmailDeliveryEnabled && (
            <FormControlLabel
              value="email"
              control={
                <Radio
                  data-testid="email-verification-radio-button"
                  color="primary"
                  sx={{ padding: '7px' }}
                />
              }
              disabled={isNullOrEmpty(member?.enrollmentEmail)}
              label={
                <Typography>
                  {/* {t('methodEmail', { email: emailMasked })} */}
                  {t('methodEmail')}
                </Typography>
              }
              // placeholder={t('methodEmail')}
            />
          )}

          {isSecurityQuestionsEnabled && (
            <FormControlLabel
              value="security_questions"
              control={
                <Radio
                  data-testid="security-questions-verification-radio-button"
                  color="primary"
                  sx={{ padding: '7px' }}
                />
              }
              // disabled={isNullOrEmpty(member?.enrollmentMobilePhone)}
              label={<Typography>{t('methodQuestions')}</Typography>}
              // placeholder={t('methodQuestions')}
            />
          )}
          {/* //edge case where nothing is even available - no email/phone in membership data and no claims either */}
          {isIdentificationNotPossible && (
            <Typography>{t('noIdentificationMethodAvailable')}</Typography>
          )}
        </RadioGroup>
      </Grid>
      {otpMethod && member && (
        <Grid
          item
          container
          xs={12}
          sx={{
            paddingTop: '10px',
            paddingLeft: '20px',
            textAlign: 'left',
            display: 'flex',
          }}
        >
          <ValueOnFile otpMethod={otpMethod} member={member} />
        </Grid>
      )}
      <Grid item xs={12} sx={{ marginTop: '30px', marginBottom: '30px' }}>
        <Divider
          sx={{
            width: '100%',
            minWidth: '100%',
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ paddingLeft: '10px' }}>
        <Typography
          sx={{
            textAlign: 'left',
            lineHeight: '20px',
            paddingLeft: isSmall ? '10px' : undefined,
            paddingRight: isSmall ? '10px' : undefined,
            fontSize: isSmall ? '0.8125rem' : '1rem',
          }}
        >
          <STrans
            namespace={'register'}
            i18nKey={'importantNoticeVerification'}
          />
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ minHeight: '10px' }}></Grid>
    </RegistrationStepCard>
  )
}

export function ValueOnFile({
  otpMethod,
  member,
}: {
  otpMethod: OtpMethod
  member: Member
}) {
  const { t } = useSTranslate(['register', 'common'])

  return (
    <Grid container>
      {otpMethod === 'phone' && (
        <>
          <Grid item>
            <Typography data-testid="phone-on-file-label">
              {t('phoneOnFile')}
            </Typography>
            &nbsp;
          </Grid>
          <Grid item>
            <Typography sx={{ fontWeight: 700, paddingLeft: '5px' }}>
              +{member?.enrollmentMobilePhone}
            </Typography>
          </Grid>
        </>
      )}
      {otpMethod === 'email' && (
        <>
          <Grid item>
            <Typography data-testid="email-on-file-label" noWrap>
              {t('emailOnFile')}
            </Typography>
            &nbsp;
          </Grid>
          <Grid>
            <Typography sx={{ fontWeight: 700, paddingLeft: '5px' }}>
              {member?.enrollmentEmail}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}
