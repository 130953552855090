import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const useCommonPortalStyles = makeStyles((theme: Theme) => {
  const baseLink = {
    fontSize: '0.85rem',
    fontWeight: 500,
    cursor: 'pointer',
  }

  return createStyles({
    baseLink,
  })
})
