import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
//when member was registred (or registration link was sent) but then they got deactivated by the client (i.e. left company)
//to be used also in regular login
//as opposed to generic error handler that says no matched member
export function MemberNoLongerActive() {
  const { t } = useSTranslate(['common', 'login'])
  return (
    <Grid container spacing={2} alignItems="center" style={{ padding: 30 }}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant="body2">{t('login:inactiveMember')}</Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant="body2">{t('common:contactHR')}</Typography>
      </Grid>
    </Grid>
  )
}
