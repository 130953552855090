import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import { ReactNode, useEffect } from 'react'

import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { RegistrationToolbar } from '../RegistrationToolbar'
import { RegistrationFormFieldsBox } from '../RegistrationContainer'
import { RegType, useRegistration } from '../RegistrationContext'
import { useMemberAppContext } from 'src/app/MemberAppContext'

interface RegistrationStepCardProps {
  children: ReactNode
  nextButtonTitle?: string
  cancelButtonTitle?: string
  onClickNextButton?: () => void
  onClickCancelButton?: () => void
  nextButtonDisabled?: boolean
  // footerComponent?: ReactNode
  progressBarStep?: number
  disableToolbar?: boolean
  customToolbarComponent?: ReactNode
  registrationType: RegType
  backHidden?: boolean

  // enableOverflow?: boolean
}
export const RegistrationStepCard = ({
  children,
  nextButtonTitle,
  cancelButtonTitle,
  onClickNextButton,
  onClickCancelButton,
  nextButtonDisabled,
  // footerComponent,
  progressBarStep,
  disableToolbar = false,
  customToolbarComponent,
  backHidden = false,
  //by default its registration
  registrationType,
}: // enableOverflow = true,
RegistrationStepCardProps) => {
  const { t } = useSTranslate('common')
  // const secondButtonTitle = cancelButtonTitle ? cancelButtonTitle : t('cancel')
  const { setProgressBarState } = useRegistration()
  const { clientUuid } = useMemberAppContext()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    setProgressBarState({
      type: registrationType,
      disabled: false,
      currentStep: progressBarStep ? progressBarStep : 0,
    })
  }, [progressBarStep])

  return (
    <>
      <Grid
        container
        sx={(theme) => ({
          // backgroundColor: 'yellow',
          // backgroundColor: 'red',
          paddingTop: clientUuid ? '20px' : '30px', //assuming clientUuid dictates logo visibility
          paddingLeft: '20px',
          paddingRight: '20px',

          // textAlign: 'center',
          [theme.breakpoints.down('sm')]: {
            padding: '10px',
            paddingTop: clientUuid ? '10px' : '20px',
          },
        })}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              maxHeight: isSmall ? '60vh' : '65vh',
              minHeigth: isSmall ? '60vh' : '65vh',
              overflowY: 'auto',
            }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>

      <RegistrationFormFieldsBox>
        {!disableToolbar && (
          <RegistrationToolbar
            // customMarginTop={enableOverflow ? '10px' : '20px'}
            // customMarginTop={'15px'}
            // my={'2vh'}
            onClickNext={onClickNextButton}
            onClickCancel={onClickCancelButton}
            nextDisabled={nextButtonDisabled}
            customNextLabel={nextButtonTitle}
            customCancelTitle={cancelButtonTitle}
            useBackLabelForCancel={true}
            backHidden={backHidden}
          />
        )}
      </RegistrationFormFieldsBox>
      {customToolbarComponent && customToolbarComponent}
      {/* {footerComponent} */}
      {/* </RegistrationContentBox> */}
      {/* <Box sx={{ minHeight: '20px' }}></Box> */}
    </>
  )
}
