import { Box, Grid, Typography } from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'

export function SMSTermsOfUseContent() {
  const { t } = useSTranslate('settings')

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="caption">{t('smsTerms.0')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">{t('smsTerms.1')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">{t('smsTerms.2')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">{t('smsTerms.3')}</Typography>
      </Grid>

      {/* <Grid item xs={12}>
      policy is shown in the screen after this one
        <ScripaTermsPolicyLinkWithDialog
          variant="privacy_policy"
          customLinkText={t('smsTermsPrivacy')}
        />
      </Grid> */}
    </Grid>
  )
}
