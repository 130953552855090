import {
  AccountSettings,
  CustomizedDependentInviteData,
  MemberDependents,
  MemberIdData,
  MemberProfile,
  MemberSavingReport,
  PasswordChangeData,
  RegistrationSetupData,
  SmsOptInChangeData,
  StatusCode,
} from '../../types'
import { MembersPolicyAndTermsAcceptanceStatus } from '../../types'
import {
  getModulesApiEndpoint,
  getMicroserviceApiDetails,
} from '../apiexec/portalapiexec'
import { usePortalApi, usePublicPortalApi } from '../apiexec/usePortalApi'
import { completeProfile } from '../registration/module-config'

import {
  changePassword,
  changeSmsOptIn,
  getDependents,
  getProfile,
  getReportPdf,
  getSavingsReport,
  getSettings,
  memberModule,
  setProfile,
  setSettings,
  getPolicyAndTermsAcceptanceStatus,
  inviteCustomizedDependents,
  isClientTokenExpired,
  isSingleSessionClient,
  getCouponCardPdf,
} from './module-config'
import { ApiUrl } from '../../types/copies/app-types'
import { DrugIdParams } from '../drugprofile/types'
import { useDrugSearchService } from '../search/useDrugSearchService'
import { FavoritesResp } from '../mymeds/BookmarksMainContainer'
import { ElipsisMenuDrugContext } from '../widgets/DrugElipsisMenu'
import { HelpMeSwitchBackendPayload } from '../helpmeswitch/types'
import { MOCK_PHARMACY_CHANGE_REPORT } from 'src/mockdata/pharmacy_change_report'
// import { MOCK_PHARMACY_CHANGE_REPORT } from 'src/mockdata/pharmacy_change_report'

// JT - 5/26/22
// putting this here because I am having issue importing it from '../../types'
export interface MemberLoginInfo {
  tenantId: number
  memberId: number
  userName: string
  password?: string
  mobileNumber?: string
  email?: string
  smsOptIn: boolean
}

//new extended login api response
//we get this when processing preregistered members that are not yet fully validated their security
export interface PreregMemberLoginInfoResp {
  statusCode: StatusCode
  memberLoginInfo: MemberLoginInfo & {
    enrollmentEmail?: string
    enrollmentMobilePhone?: string
  }
}
export interface AdditionalPharmacyDetailsByNpiLookupResp {
  id: number
  name: string
  npi: string
  // logoPath: null
  address1: string
  address2: string
  address3: string
  city: string
  state: string
  zip: string
  phone: string
  'mail-order': boolean
  'cash-only': boolean
  // 'qualified-340b': boolean
}
export const pharmacyDetailsEndpoint: ApiUrl = {
  endpoint: '/member-service/api/pharmacies', //+ npi i.e. /1831388024
  apiType: 'member_micro',
  apiVersion: '1.0',
}

export const costPlustAcceptanceEndpoint: ApiUrl = {
  endpoint: '/member-service/api/mark-cuban-cost-plus-agreement',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
export interface CostPlusStatus {
  'mcpd-agreement-accepted': boolean
  'redirect-url'?: string
}

export const acceptCernerPolicyEndpoint: ApiUrl = {
  endpoint: '/member-service/api/cerner-multum-agreement',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

const submitHelpMeSwitchEndpoint: ApiUrl = {
  endpoint: '/member-service/api/help-me-switch',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

const hiddenDrugs: ApiUrl = {
  endpoint: '/member-service/api/hidden-drugs',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
//todo - pagination across the board for various apis...
const PAGE_SIZE_DEFAULT_MYMEDS = 50
const PAGE_SIZE_DEFAULT_SAVED_MEDS = 50
const prescribedDrugs: ApiUrl = {
  endpoint: '/member-service/api/prescribed-drugs',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
const savingsCard: ApiUrl = {
  endpoint: '/member-service/api/savings-card',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
const favoriteDrug: ApiUrl = {
  endpoint: '/member-service/api/favorite-drugs',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
const feedback: ApiUrl = {
  endpoint: '/member-service/api/feedback',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
const zipProfileUpdate: ApiUrl = {
  //finalUrl = url + /{memberId}
  endpoint: '/member-service/api/members',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

//TODO - review - this is used in zipProfileUpdate and also individually when fetching langauge details
const memberProfileExtendedDetails: ApiUrl = {
  endpoint: '/member-service/api/members',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

//this is the latest version of the member profile returned by the microservice
//it contains up to date information not available in the standard profile
//should be fetched at the end and merged with the standard profile
export type MemberProfileExtendedDetailsResp = {
  zip: string
  'plan-type': string
  'preferred-language-code': string
  'savings-report-contact-opt-out': boolean
  'savings-report-mail-opt-out': boolean
  'savings-report-email-opt-out': boolean
  'savings-report-sms-opt-out': boolean
  'marketing-email-opt-out': boolean
  'marketing-mail-opt-out': boolean
  'marketing-sms-opt-out': boolean
}

export type HideReasonAlternative =
  | 'TRIED_DIDNT_WORK'
  | 'TRIED_EXPERIENCED_SIDE_EFFECTS'
export type HideResonOriginal = 'NO_LONGER_TAKING' | 'NEVER_TOOK_IT'
export type HideReason = HideReasonAlternative | HideResonOriginal

export interface HiddenDrugsResp {
  'hidden-drugs': HiddenDrug[]
}
export interface PrescribedDrugResp {
  'prescribed-drugs': PrescribedDrug[]
}
export interface SavingsCardResp {
  'savings-card': {
    bin: string
    pcn: string
    grp: string
    authenticationNumber: string
    'coupon-card-custom-logo-url'?: string
  }
}
export interface PrescribedDrug {
  id: 196160
  'drug-basic-info'?: {
    name: string
    strength: string
    'drug-id': number
    'dosage-form': string
    'unit-of-measure': string
  }
  'quantity-dispensed': number
  'prescribed-days-supply': string
  'last-fill-date': string
  'next-fill-date': string
}
export interface HiddenDrug {
  id: number
  //discontinued = system, manually discontinued = user, suppressed = suppressed
  type: 'DISCONTINUED' | 'MANUALLY_DISCONTINUED' | 'SUPPRESSED'
  reason: string
  'drug-id': number
  'mma-id': number
  'drug-name': string
  'last-fill-date': string
  'next-fill-date': string
  'dispensed-quantity': number
  'prescribed-days-supply': string
  'created-when': string
  'dosage-form': string
  strength: string
  'strength-unit-of-measure': string
}

export function useMemberService() {
  const { portalApi } = usePortalApi()
  const { portalPublicApi } = usePublicPortalApi()
  const drugService = useDrugSearchService()

  async function getPharmacyDetails(
    npi: string,
  ): Promise<AdditionalPharmacyDetailsByNpiLookupResp> {
    let { url, ops } = getMicroserviceApiDetails(pharmacyDetailsEndpoint)
    let finalUrl = `${url}/${npi}`
    return portalApi.getMicro<AdditionalPharmacyDetailsByNpiLookupResp>(
      finalUrl,
      ops,
    )
  }

  async function saveNewCostPlustAcceptance(): Promise<CostPlusStatus> {
    let { url, ops } = getMicroserviceApiDetails(costPlustAcceptanceEndpoint)
    let payLoad = undefined
    return portalApi.postMicro<any>(url, payLoad, ops)
  }

  async function acceptCernerPolicy() {
    let { url, ops } = getMicroserviceApiDetails(acceptCernerPolicyEndpoint)
    return portalApi.postMicro<any>(url, {}, ops)
  }

  async function sendHelpMeSwitch(
    data: HelpMeSwitchBackendPayload,
  ): Promise<any> {
    
    let { url, ops } = getMicroserviceApiDetails(submitHelpMeSwitchEndpoint)
    return portalApi.postMicro<any>(url, data, ops)
  }

  async function getMemberProfileExtendedDetails(
    memberId: number,
  ): Promise<MemberProfileExtendedDetailsResp> {
    let { url, ops } = getMicroserviceApiDetails(memberProfileExtendedDetails)
    let finalUrl = `${url}/${memberId}`
    return portalApi.getMicro<MemberProfileExtendedDetailsResp>(finalUrl, ops)
  }

  async function getAllFavorites(): Promise<FavoritesResp> {
    let { url, ops } = getMicroserviceApiDetails(favoriteDrug)
    url = url + '?size=' + PAGE_SIZE_DEFAULT_SAVED_MEDS
    return portalApi.getMicro<FavoritesResp>(url, ops)
  }

  async function updateMemberZip(
    newZip: string,
    memberId: number,
  ): Promise<any> {
    const { url, ops } = getMicroserviceApiDetails(zipProfileUpdate)
    let finalUrl = `${url}/${memberId}`
    const payload = {
      zip: newZip,
    }

    return portalApi.putMicro<any>(finalUrl, payload, ops)
  }

  async function updateMemberProfileExtended(
    memberId: number,
    keys: (keyof MemberProfileExtendedDetailsResp)[],
    values: any[],
  ): Promise<any> {
    const { url, ops } = getMicroserviceApiDetails(memberProfileExtendedDetails)
    let finalUrl = `${url}/${memberId}`

    const payload: Partial<MemberProfileExtendedDetailsResp> = {}

    keys.forEach((key, index) => {
      payload[key] = values[index]
    })

    return portalApi.putMicro<any>(finalUrl, payload, ops)
  }

  async function addDrugToFavorites(drugIdParams: DrugIdParams): Promise<any> {
    const { url, ops } = getMicroserviceApiDetails(favoriteDrug)
    const drugId = await drugService.resolveDrugIdParams(drugIdParams)
    const payload = { 'drug-id': drugId }
    //request full response from fetch call to be returned as opposed to json()
    ops.returnRawFetchResponse = true

    return portalApi.postMicro<any>(url, payload, ops)
  }
  async function removeDrugFromFavorites(
    drugIdParams: DrugIdParams,
  ): Promise<any> {
    const { url, ops } = getMicroserviceApiDetails(favoriteDrug)
    const drugId = await drugService.resolveDrugIdParams(drugIdParams)
    let finalUrl = url + '/' + drugId
    return portalApi.deleteMicro<any>(finalUrl, ops)
  }

  async function getMemberHiddenDrugs(): Promise<HiddenDrugsResp> {
    let { url, ops } = getMicroserviceApiDetails(hiddenDrugs)
    url = url + '?size=' + PAGE_SIZE_DEFAULT_MYMEDS

    return portalApi.getMicro<HiddenDrugsResp>(url, ops)
  }

  async function getSavingsCard(): Promise<SavingsCardResp> {
    const { url, ops } = getMicroserviceApiDetails(savingsCard)

    return portalApi.getMicro<HiddenDrugsResp>(url, ops)
  }
  async function getMemberPrescribedDrugs(): Promise<PrescribedDrugResp> {
    let { url, ops } = getMicroserviceApiDetails(prescribedDrugs)
    url = url + '?size=' + PAGE_SIZE_DEFAULT_MYMEDS

    return portalApi.getMicro<PrescribedDrugResp>(url, ops)
  }
  //TODO - mmaId based suppresion for alternative
  async function addNewHiddenDrug(
    drugIdParams: DrugIdParams,
    drugContext: ElipsisMenuDrugContext,
    reason: HideReason,
  ): Promise<any> {
    
    const { url, ops } = getMicroserviceApiDetails(hiddenDrugs)
    let payLoad: any = {
      reason: reason,
    }
    const drugId = await drugService.resolveDrugIdParams(drugIdParams)
    payLoad['drug-id'] = drugId

    //now we also include mma-id when suppressing new drug if we have it
    if (drugIdParams.mmaId) {
      payLoad['mma-id'] = drugIdParams.mmaId
    }

    return portalApi.postMicro<any>(url, payLoad, ops)
  }
  async function removeHiddenDrug(
    drugIdParams: DrugIdParams,
    drugContext: ElipsisMenuDrugContext,
    // reason: HideReason,
  ): Promise<any> {
    const { url, ops } = getMicroserviceApiDetails(hiddenDrugs)
    const drugId = await drugService.resolveDrugIdParams(drugIdParams)
    let finalUrl = `${url}/${drugId}`

    //now we also pass mma-id  when removing a hidden drug
    if (drugIdParams.mmaId) {
      finalUrl = finalUrl + '?mma-id=' + drugIdParams.mmaId
    }

    return portalApi.deleteMicro<any>(finalUrl, ops)
  }
  async function sendFeedback(rating: number, comments: string): Promise<any> {
    const { url, ops } = getMicroserviceApiDetails(feedback)
    const payload = {
      rating: rating,
      feedback: comments,
    }

    return portalApi.postMicro<any>(url, payload, ops)
  }

  async function getMemberSettingsApi(): Promise<AccountSettings | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, getSettings)
    return portalApi.get<AccountSettings | StatusCode>(url)
  }

  async function saveMemberSettingsApi(settings: AccountSettings) {
    const url = getModulesApiEndpoint(memberModule, setSettings)
    return portalApi.post<MemberProfile | StatusCode>(url, settings)
  }

  async function getMemberProfileApi(): Promise<MemberProfile | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, getProfile)
    return portalApi.get<MemberProfile | StatusCode>(url)
  }

  async function getMemberSavingsReportApi(): Promise<
    MemberSavingReport | StatusCode
  > {
    const url = getModulesApiEndpoint(memberModule, getSavingsReport)
    // return new Promise((resolve, reject) => {
    //   resolve(MOCK_PHARMACY_CHANGE_REPORT as any)
    // })
    return portalApi.get<MemberSavingReport | StatusCode>(url)
  }

  async function getMemberSavingsReportPDFApi(): Promise<any | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, getReportPdf)
    return portalApi.get<any | StatusCode>(url)
  }
  async function getMemberCouponCardPDFApi(): Promise<any | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, getCouponCardPdf)
    return portalApi.get<any | StatusCode>(url)
  }

  async function saveMemberProfileApi(
    profile: MemberProfile,
  ): Promise<MemberProfile | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, setProfile)
    return portalApi.post<MemberProfile | StatusCode>(url, profile)
  }

  async function getMemberDepsApi(
    regSetup: Partial<RegistrationSetupData>,
  ): Promise<MemberDependents | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, getDependents)
    return portalApi.post<MemberDependents | StatusCode>(url, regSetup)
  }

  // async function sendDepsInviteApi(
  //   inviteData: MemberDependentInviteData,
  // ): Promise<any | StatusCode> {
  //   const url = getModulesApiEndpoint(memberModule, inviteDependents)
  //   return portalApi.post<MemberDependentInviteData | StatusCode>(
  //     url,
  //     inviteData,
  //   )
  // }
  async function sendCustomizedDepsInviteApi(
    inviteData: CustomizedDependentInviteData,
  ): Promise<any | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, inviteCustomizedDependents)
    return portalApi.post<CustomizedDependentInviteData | StatusCode>(
      url,
      inviteData,
    )
  }

  async function changeMemberPasswordApi(pwdChangeData: PasswordChangeData) {
    const url = getModulesApiEndpoint(memberModule, changePassword)
    return portalApi.post<void | StatusCode>(url, pwdChangeData)
  }

  async function changeSmsOptInApi(smsChangeData: SmsOptInChangeData) {
    const url = getModulesApiEndpoint(memberModule, changeSmsOptIn)
    return portalApi.post<void | StatusCode>(url, smsChangeData)
  }

  async function getMemberPolicyAndTermsAcceptanceStatusApi(
    memberIdData: Partial<MemberIdData>,
  ): Promise<MembersPolicyAndTermsAcceptanceStatus | StatusCode> {
    const url = getModulesApiEndpoint(
      memberModule,
      getPolicyAndTermsAcceptanceStatus,
    )
    return portalApi.post<MemberDependents | StatusCode>(url, memberIdData)
  }

  async function isSingleSessionClientApi(clientId: number): Promise<boolean> {
    const url =
      getModulesApiEndpoint(memberModule, isSingleSessionClient) +
      '?clientId=' +
      clientId
    return portalApi.get<void | boolean>(url)
  }
  async function isSingleSessionExpiredApi(): Promise<void | boolean> {
    const url = getModulesApiEndpoint(memberModule, isClientTokenExpired)
    return portalApi.get<void | boolean>(url)
  }

  async function submitCompleteProfile(
    memberInfo: MemberLoginInfo,
  ): Promise<any> {
    const url = getModulesApiEndpoint(memberModule, completeProfile)
    return portalPublicApi.post<any | StatusCode>(url, memberInfo)
  }

  return {
    getMemberProfileApi,
    getMemberProfileExtendedDetails,
    updateMemberProfileExtended,
    getMemberSavingsReportApi,
    getMemberSettingsApi,
    saveMemberProfileApi,
    saveMemberSettingsApi,
    getMemberDepsApi,
    // sendDepsInviteApi,
    sendCustomizedDepsInviteApi,
    getMemberSavingsReportPDFApi,
    changeMemberPasswordApi,
    changeSmsOptInApi,
    getMemberPolicyAndTermsAcceptanceStatusApi,
    isSingleSessionExpiredApi,
    isSingleSessionClientApi,
    submitCompleteProfile,
    getMemberCouponCardPDFApi,
    getMemberHiddenDrugs,
    getMemberPrescribedDrugs,
    addNewHiddenDrug,
    removeHiddenDrug,
    addDrugToFavorites,
    removeDrugFromFavorites,
    getSavingsCard,
    getAllFavorites,
    sendFeedback,
    updateMemberZip,
    sendHelpMeSwitch,
    acceptCernerPolicy,
    // completeSsoProfile,
    saveNewCostPlustAcceptance,
    getPharmacyDetails,
  }
}
