import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material'
import React, { useContext, useState } from 'react'
import { getScriptaTheme } from './theme-config-scripta'
import { SupportedThemeNames } from 'src/app/styles/scripta-theme'
import { getAmazonOrangeTheme } from './theme-config-amazon-orange'
import { getAmazonBlueTheme } from './theme-config-amazon-blue'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export type PortalThemeContextType = {
  setSelectedThemeByName: (themeName: SupportedThemeNames) => void
}
export const PortalThemeContext = React.createContext<PortalThemeContextType>({
  setSelectedThemeByName: (themeName: SupportedThemeNames) => {},
})

export function PortalThemeProvider({ children }: { children: any }) {
  const initTeme = getScriptaTheme()

  //by default start with scripta
  const [selectedTheme, setSelectedTheme] = useState(initTeme)
  const setSelectedThemeByName = (themeName: SupportedThemeNames) => {
    let newTheme
    if (themeName === 'amazon-orange') {
      newTheme = getAmazonOrangeTheme()
    } else if (themeName === 'amazon-blue') {
      newTheme = getAmazonBlueTheme()
    } else {
      newTheme = getScriptaTheme()
    }
    setSelectedTheme(newTheme)
  }

  return (
    <PortalThemeContext.Provider
      value={{
        setSelectedThemeByName,
      }}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={selectedTheme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </PortalThemeContext.Provider>
  )
}

export function usePortalThemeContext() {
  return useContext(PortalThemeContext)
}
