import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import {
  getProgressBarPercentValue,
  useRegistration,
} from '../registration/RegistrationContext'
import { useEffect } from 'react'
import { useMediaQuery } from '@mui/material'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}))

export function StepProgressBar() {
  const { progressBarState, setProgressBarState } = useRegistration()
  const [value, setValue] = React.useState(0)

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    setValue(getProgressBarPercentValue(progressBarState))
  }, [progressBarState])

  if (progressBarState.disabled) {
    return null
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: '15px 10px 10px 10px',
        maxWidth: isSmall ? '188px' : '320px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
  )
}
