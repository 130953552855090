//indicates 500 or other server exception - not evenr ecovrable

export function isServerErrorResponse<T>(arg: any): arg is T {
  // 
  if (arg.status === 500 || arg.httpStatusCode === 500) {
    //trigger rebuild
    //console.error('isServerErrorResponse,arg=', arg)
    return true
  } else return false
}
