import { Typography, TypographyProps, styled } from '@mui/material'

export type STypographyVariant =
  | 'title1'
  | 'title2'
  | 'title3'
  | 'bodybig'
  | 'body'
  | 'bodysmall'
export type STypographyWeight = 'bold' | 'semibold' | 'regular'

export type STypographyCombination =
  `${STypographyVariant}_${STypographyWeight}`

export interface STypographyProps extends Omit<TypographyProps, 'variant'> {
  variant: STypographyCombination
  component?: React.ElementType
}

const styles = {
  title1: {
    fontSize: 40,
    lineHeight: '48px',
  },
  title2: {
    fontSize: 32,
    lineHeight: '40px',
  },
  title3: {
    fontSize: 24,
    lineHeight: '32px',
  },
  bodybig: {
    fontSize: 18,
    lineHeight: '24px',
  },
  body: {
    fontSize: 16,
    lineHeight: '24px',
  },
  bodysmall: {
    fontSize: 13,
    lineHeight: '16px',
  },
}

const FONT_WEIGHT = {
  regular: 400,
  semibold: 600,
  bold: 700,
}

const STypography = (props: STypographyProps) => {
  const { variant, ...otherProps } = props
  const [variantPart, weightPart] = variant.split('_') as [
    STypographyVariant,
    STypographyWeight,
  ]
  const style = { ...styles[variantPart], fontWeight: FONT_WEIGHT[weightPart] }
  return <Typography style={style} {...otherProps} />
}

export default STypography

export const STypographyEllipsis = styled(STypography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})
