import HomeIcon from '@mui/icons-material/Home'
import {
  Dialog,
  DialogContent,
  Grid,
  Link,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useIntersection } from 'react-use'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import welcomeStep1 from '../../images/prereg/WelcomeStep1.svg'
import welcomeStep2 from '../../images/prereg/WelcomeStep2.png'
import welcomeStep3 from '../../images/prereg/WelcomeStep3.svg'
import whatwillyoudo from '../../images/prereg/whatwillyoudo.svg'

import SouthIcon from '@mui/icons-material/South'
import welcomeSmily from '../../images/prereg/WelcomeSmiley.png'
import welcomeStep4 from '../../images/prereg/WelcomeStep4.svg'
import homeLogoMobile from '../../images/prereg/homeLogoMobile.svg'
import SavingCircle123 from '../../images/prereg/preregWelcomeSaving123.png'
import thankyouending from '../../images/prereg/thank_you_end_anime.gif'
import thankyougiflonger from '../../images/prereg/thank_you_white_anime.gif'

import videoPreviewHowToReadYourReport from '../../images/prereg/videoPreviewHowToReadYourReport.jpg'
import videoPreviewHowToReadYourReport_whitebg from '../../images/prereg/videoPreviewHowToReadYourReport_whitebg.jpg'
import videoPreviewSavingsExample from '../../images/prereg/videoPreviewSavingsExample.jpg'
import videoPreviewSavingsExample_whitebg from '../../images/prereg/videoPreviewSavingsExample_whitebg.jpg'

import videoPreviewNavigating1 from '../../images/prereg/videoPreviewNavigating1.jpg'
import videoPreviewNavigating2 from '../../images/prereg/videoPreviewNavigating2.jpg'

import videoPreviewHowToSave from '../../images/prereg/videoPreviewHowToSave.jpg'
import videoPreviewMeetScripta from '../../images/prereg/videoPreviewMeetScripta.png'

import { useEffect, useState, useRef } from 'react'
import Confetti from 'react-confetti'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useHistory } from 'react-router'
import { useWindowSize } from 'react-use'
import YouTube from 'react-youtube'
import followbullet from '../../images/prereg/followbullet.svg'
import followdot from '../../images/prereg/followpurpledot.svg'
import { useMemberAppContext } from '../MemberAppContext'
import {
  IS_NATIVE,
  getOS,
  nativeApi_setBackButtonVisible,
} from '../apiexec/utils'
import './arrowdown.css'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useSColors } from '../styles/scripta-theme'
import { usePortalAppConfig } from 'src/app/config/usePortalAppConfig'

let BG_COLOR_GRAY = '#EBEEF2'

const VIDEO_ID_HOW_TO_READ = '_oW8rZDwPTQ'
const VIDEO_ID_SAMPLE_SAVINGS = 'A2nnuAOcq74'

const VIDEO_ID_NAVIGATING_1 = 'poSuLNZgc_g'
const VIDEO_ID_NAVIGATING_2 = 'KFpWl7MnkJA'

function MyConfetti() {
  const { width, height } = useWindowSize()

  return (
    <Confetti
      numberOfPieces={450}
      width={width - 30}
      height={height}
      recycle={false}
      initialVelocityY={10}
    />
  )
}

function ArrowDownIndicator() {
  //extra large down icon
  //dissapears on small screens and on first scroll
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  //is tablet also we dont want to show
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const [showArrow, setShowArrow] = useState(true)

  if (isSmall || isTablet || !showArrow) {
    return <></>
  }
  return (
    <Box
      sx={{
        position: 'absolute',
        // right: '175px',
        // right: 'calc(50vw - 80px)',
        left: 'calc(50%)',
        marginLeft: '-40px',
        marginTop: '60px',

        // bottom: '200px',
        zIndex: 10000,
      }}
    >
      <div>
        <SouthIcon
          className="arrow"
          sx={{
            position: 'absolute',
            // right: '175px',
            // bottom: '70px',
            fontSize: '80px',
            color: '#3C4142',
          }}
        />
      </div>
    </Box>
  )
  // return (
  //   <Box display="flex">
  //     <Box sx={{ marginLeft: 'auto', marginRight: '40px' }}>
  //       {/* <SouthIcon
  //         sx={{
  //           position: 'absolute',
  //           right: '175px',
  //           bottom: '70px',
  //           fontSize: '80px',
  //           color: '#3C4142',
  //         }}
  //       /> */}
  //     </Box>
  //   </Box>
  // )
}

function ThankYouHeader({
  isSmall,
  mode,
  onStartSavingNowClicked,
  hideArrowDown,
}: {
  isSmall: boolean
  mode?: 'register' | 'prereg'
  onStartSavingNowClicked?: () => void
  hideArrowDown: boolean
}) {
  const { COLORS } = useSColors()

  const [showThankYouEnd, setShowThankYouEnd] = useState(false)
  const { t } = useSTranslate(['prereg', 'register'])

  const titleTextContent =
    mode === 'prereg'
      ? t('prereg:forPreregistering')
      : t('register:forRegistering')
  const bodyTextContent =
    mode === 'prereg'
      ? t('prereg:weWillLetYouKnow')
      : t('register:whileYouAreHere')

  useEffect(() => {
    // onCodeInitialized(result)
    setTimeout(() => {
      setShowThankYouEnd(true)
    }, 3000)
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '300px',
        background: COLORS.BACKGROUND_GRADIENT,
        paddingTop: '50px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Grid
        container
        justifyContent="center"
        sx={{
          paddingLeft: isSmall ? '10px' : '0px',
          paddingRight: isSmall ? '10px' : '0px',
        }}
      >
        <Grid item xs={12}>
          <MyConfetti />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
          <img
            alt="Thank you"
            data-testid="thank-you-gif"
            src={!showThankYouEnd ? thankyougiflonger : thankyouending}
            width={isSmall ? '275px' : '380px'}
            height={isSmall ? '275px' : '380px'}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: '#FFF',
              textAlign: 'center',
              fontSize: isSmall ? 24 : 40,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '48.72px',
            }}
          >
            {titleTextContent}
          </Typography>
        </Grid>
        {mode === 'register' ? (
          <>
            <Grid item xs={12}>
              <WelcomeHeaderSubtitle isSmall={isSmall} />
            </Grid>
            <Grid item container xs={12} alignItems={'center'}>
              <Grid
                item
                sx={{
                  margin: 'auto',
                  paddingTop: '30px',
                  paddingBottom: '20px',
                }}
              >
                <WhiteActionButton
                  dataTestId="register-view-my-savings-button"
                  buttonText={t('register:accountBtnStartSavingNow')}
                  isSmall={isSmall}
                  onClick={() => {
                    if (onStartSavingNowClicked) {
                      onStartSavingNowClicked()
                    }
                  }}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <Typography
            sx={{
              textAlign: 'center',

              color: '#FFF',
              fontSize: isSmall ? 16 : 24,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: isSmall ? '19.49px' : '29.23px',

              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: isSmall ? '5px' : '15px',
              marginBottom: isSmall ? '35px' : '35px',
              padding: isSmall ? '0px 10px' : '0px 30px',
              maxWidth: '800px',
            }}
          >
            {bodyTextContent}
          </Typography>
        </Grid>
        <Grid item sx={{ marginBottom: isSmall ? '170px' : '240px' }}></Grid>
      </Grid>
      <WhiteElipsisUpSlice isSmall={isSmall} hideArrowDown={hideArrowDown} />
    </Box>
  )
}

function WelcomeHeaderSubtitle({ isSmall }: { isSmall: boolean }) {
  const { t } = useSTranslate(['register'])
  return (
    <Box
      style={{
        backgroundColor: 'white',
        width: 'max-content',
        padding: '5px 10px',
        margin: 'auto',
        transform: 'skew(-20deg)',
        marginTop: isSmall ? 0 : 10,
        marginBottom: isSmall ? 5 : 0,
      }}
    >
      <Typography
        sx={{
          color: '#4D008C',
          textAlign: 'center',
          fontSize: isSmall ? 16 : 24,
          fontWeight: 600,
          lineHeight: 'normal',
        }}
      >
        {t('register:accountIsNowSetup')}
      </Typography>
    </Box>
  )
}

function WhiteElipsisUpSlice({
  isSmall,
  hideArrowDown,
}: {
  isSmall: boolean
  hideArrowDown: boolean
}) {
  return (
    <Box
      style={{
        width: '120%',
        left: '-10%',
        borderRadius: '200%',
        backgroundColor: 'white',
        height: isSmall ? '150px' : '420px',
        position: 'absolute',
        zIndex: 1,
        bottom: isSmall ? '-85px' : '-325px',
      }}
    >
      {!hideArrowDown && <ArrowDownIndicator />}
    </Box>
  )
}

export function WelcomePreregDialog({
  onGoHomeFromThankYouClicked,
  mode = 'prereg',
}: {
  onGoHomeFromThankYouClicked: () => void
  mode?: 'register' | 'prereg'
}) {
  const { COLORS } = useSColors()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [arrowDownVisible, setArrowDownVisible] = useState(true)
  //put in dialog in case we need to reuse this from login page when prreg users login
  const [mainDialogOpen, setMainDialogOpen] = React.useState(true)

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useSTranslate(['common', 'login', 'register'])

  const NAVBAR_SCROLL_THRESHOLD = isSmall ? 50 : 450

  const scrollContainerRef: any = useRef(null)
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    nativeApi_setBackButtonVisible(false)
    return () => {
      if (scrollContainerRef && scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  function handleScroll() {
    const { scrollTop } = scrollContainerRef.current ?? { scrollTop: 0 }
    setScrollPosition(scrollTop)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Dialog fullScreen={true} open={mainDialogOpen}>
        <DialogContent
          ref={(node) => {
            if (node && !scrollContainerRef.current) {
              scrollContainerRef.current = node
              scrollContainerRef.current.addEventListener(
                'scroll',
                handleScroll,
              )
            }
          }}
          sx={{
            padding: 0,
            overscrollBehavior: 'none',
            overflowX: 'hidden',
            width: '100%',
          }}
        >
          {!isSmall ? (
            <Box
              style={{
                height: 20,
                background: COLORS.BACKGROUND_GRADIENT,
              }}
            ></Box>
          ) : null}
          <AppBar
            component="nav"
            elevation={0}
            position="sticky"
            color={'transparent'}
            style={{
              background:
                scrollPosition > NAVBAR_SCROLL_THRESHOLD
                  ? COLORS.BACKGROUND_GRADIENT
                  : undefined,
              overflowX: 'hidden',
            }}
          >
            <Toolbar>
              <Grid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  paddingLeft: isSmall ? '5px' : '20px',
                  paddingRight: isSmall ? '5px' : '20px',
                  paddingTop: '2.5px',
                }}
              >
                <Grid
                  item
                  sx={{ padding: '0px 0px 0px 0px', marginTop: '5px' }}
                >
                  <img
                    src={
                      isSmall
                        ? homeLogoMobile
                        : theme.customized.whiteOnDarkLogoUrl
                    }
                    alt="Logo"
                    height={isSmall ? 31 : 40}
                  />
                </Grid>
                <Grid item sx={{ padding: '0px 0px 0px 0px' }}>
                  <HomeButton
                    mode={mode}
                    isSmall={isSmall}
                    onHomeClicked={() => {
                      setMainDialogOpen(false)
                      onGoHomeFromThankYouClicked()
                    }}
                    onScrolledOutOfSight={() => {
                      setArrowDownVisible(false)
                    }}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <Box component="main" sx={{ marginTop: '-65px' }}>
            <CssBaseline />
            <ThankYouHeader
              isSmall={isSmall}
              mode={mode}
              onStartSavingNowClicked={() => {
                setMainDialogOpen(false)
                onGoHomeFromThankYouClicked()
              }}
              hideArrowDown={scrollPosition > 1}
            />
            {/* {scrollPosition < 1 && <ArrowDownIndicator />} */}
            <MeetScripta isSmall={isSmall} />
            <DottedSpacer />
            <WelcomeSteps isSmall={isSmall} />
            {/* <DottedSpacer /> no spacer here  */}
            {isSmall && <HowToSaveWithScriptaMobile />}
            {!isSmall && <HowToSaveTabletAndDesktop />}
            <DottedSpacer />
            <FollowDot isSmall={isSmall} />
            <DottedSpacer />
            <HowToReadYourSavingsReport isSmall={isSmall} />
            <DottedSpacer />
            <NavigatingAppAndPortal isSmall={isSmall} />
            <DottedSpacer />
            <WhatWillYouDo isSmall={isSmall} />
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: COLORS.PRIMARY_BRAND,
                paddingTop: '20px',
                paddingBottom: '20px',
              }}
            >
              <WelcomeFooter />
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

function WelcomeFooter() {
  const CONFIG = usePortalAppConfig()
  const { t } = useSTranslate(['common', 'login'])
  const email: string = CONFIG.MEMBER_SUPPORT_EMAIL
  const phoneNumber: string = CONFIG.MEMBER_SUPPORT_NUMBER
  const isNative: boolean = IS_NATIVE()
  const isAndroid: boolean = getOS() === 'Android'

  const textSx: any = {
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#fff',
    // textShadow: 'black 3px 3px 10px',
  }
  return (
    <>
      <Typography sx={textSx}>
        {t('login:needHelpFooter')}
        <Link
          href={`mailto:${email}`}
          onClick={
            isNative && isAndroid
              ? () => {
                  window.open(`mailto:${email}`)
                }
              : undefined
          }
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: '#fff',
          }}
        >
          {email}
        </Link>
      </Typography>
      <Typography sx={textSx}>
        or call us at{' '}
        <Link
          href={`tel:+${phoneNumber}`}
          onClick={
            isNative && isAndroid
              ? () => {
                  window.open(`tel:+${phoneNumber}`)
                }
              : undefined
          }
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: '#fff',
          }}
        >
          {phoneNumber}
        </Link>
      </Typography>
    </>
  )
}

function HomeButton({
  isSmall,
  onHomeClicked,
  onScrolledOutOfSight,
  mode = 'prereg',
}: {
  isSmall: boolean
  onHomeClicked: () => void
  onScrolledOutOfSight: () => void
  mode?: 'register' | 'prereg'
}) {
  const intersectionRef = React.useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  })
  const { mobileVersion, setMobileVersion } = useMemberAppContext()
  const history = useHistory()
  const { t } = useSTranslate('common')

  const buttonText = mode === 'prereg' ? t('backToHome') : t('viewMySavings')

  useEffect(() => {
    if (intersection && intersection.isIntersecting === false) {
      onScrolledOutOfSight()
    }
  }, [intersection])

  const goToHome = () => {
    onHomeClicked()
  }
  return (
    <div ref={intersectionRef}>
      <WhiteActionButton
        dataTestId="preregister-go-home-button"
        isSmall={isSmall}
        onClick={goToHome}
        buttonChildeCustomNodeElement={
          <>
            {mode === 'register' && !isSmall ? (
              <ExitToAppIcon style={{ marginRight: 5 }} />
            ) : null}
            {isSmall ? <HomeIcon /> : buttonText}
          </>
        }
      />
    </div>
  )
}
function WhiteActionButton({
  dataTestId,
  buttonText,
  buttonChildeCustomNodeElement,
  onClick,
  isSmall,
}: {
  dataTestId: string
  buttonText?: string
  buttonChildeCustomNodeElement?: any
  onClick: () => void
  isSmall: boolean
}) {
  const { COLORS } = useSColors()
  return (
    <Button
      data-testid={dataTestId}
      variant="outlined"
      onClick={onClick}
      sx={{
        height: isSmall ? '28px' : '37px',
        padding: isSmall ? '10px 20px' : '10px 30px',
        borderRadius: '100px',
        color: COLORS.PRIMARY_BRAND,
        borderColor: 'white',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'white',
          color: COLORS.PRIMARY_BRAND,
        },
      }}
    >
      {buttonChildeCustomNodeElement
        ? buttonChildeCustomNodeElement
        : buttonText}
    </Button>
  )
}

function MeetScripta({ isSmall }: { isSmall: boolean }) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        marginTop: '0px',
        paddingLeft: isSmall ? '10px' : '0px',
        paddingRight: isSmall ? '10px' : '0px',
      }}
      container
      justifyContent={'center'}
    >
      <Grid item xs={12} md={6} sx={{ marginTop: '0px' }}>
        <img src={welcomeSmily} width="100%" height="100%" />
      </Grid>
      <Grid
        container
        item
        xs={12}
        alignItems={'center'}
        sx={{
          marginTop: isSmall ? '50px' : '100px',
        }}
      >
        <Grid item xs={12}>
          <WelcomeVideoWrapper
            youtubeVideoId={'t8-ckPW_pLo'}
            title="Meet Scripta - Save On Prescriptions"
            staticPreviewImageSrc={videoPreviewMeetScripta}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ marginTop: '25px' }}
          // style={{ maxWidth: '200px' }}
        >
          <Box sx={{ maxWidth: '800px', margin: 'auto' }}>
            <Typography
              sx={{
                fontSize: isSmall ? '1rem' : '1.5rem',
                marginTop: '20px',
                textAlign: 'left',
                paddingLeft: isSmall ? '20px' : '50px',
                paddingRight: isSmall ? '20px' : '50px',
              }}
            >
              Scripta is a FREE member benefit offered through your health plan
              that could save you hundreds or even thousands of dollars each
              year on your prescriptions.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '50px' }}>
          <Box sx={{ maxWidth: '800px', margin: 'auto' }}>
            <Typography
              sx={{
                fontSize: isSmall ? '1rem' : '1.5rem',
                marginTop: isSmall ? '15px' : '25px',
                textAlign: 'left',
                paddingLeft: isSmall ? '20px' : '50px',
                paddingRight: isSmall ? '20px' : '50px',
              }}
            >
              At Scripta, we know medications can be expensive. Our job is to
              help you better afford your medicines, so you can focus on staying
              healthy, while keeping the most money in your pocket.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

function WelcomeSteps({ isSmall }: { isSmall: boolean }) {
  //otherwise tablet or desktop
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const { COLORS } = useSColors()
  const { t } = useSTranslate('prereg')
  const HEADER = (
    <Grid container sx={{ backgroundColor: '#EBEEF2' }}>
      <Grid item xs={12} sx={{ marginTop: '25px', marginBottom: '25px' }}>
        <Typography
          sx={{
            fontSize: isSmall ? '1.75rem' : '3rem',
            paddingLeft: '30px',
            paddingRight: '30px',
            color: COLORS.PRIMARY_BRAND,
            textAlign: 'center',
          }}
        >
          SCRIPTA HELPS YOU STAY HEALTHY,
          <br /> SAVE MONEY
        </Typography>
      </Grid>
    </Grid>
  )
  let step1Text =
    'When your doctor writes a script, they often don’t know the cost of the drug'
  let step2Text =
    'We deliver Savings Reports with lower-priced options based on your health plan'
  let step3Text =
    'Discuss your report with your doctor to see if an Rx switch that saves is right for you'
  let step4Text =
    'So you get The Right Meds at The Best Price™ \n (And your doctor is happy to help you save!)'

  if (isSmall) {
    return (
      <>
        <>{HEADER}</>
        <>
          <Grid container sx={{ backgroundColor: BG_COLOR_GRAY }}>
            <MobileStepImage
              isSmall={isSmall}
              src={welcomeStep1}
              text={step1Text}
            />
            <MobileStepImage
              isSmall={isSmall}
              src={welcomeStep2}
              text={step2Text}
            />
            <MobileStepImage
              isSmall={isSmall}
              src={welcomeStep3}
              text={step3Text}
            />
            <MobileStepImage
              isSmall={isSmall}
              src={welcomeStep4}
              text={step4Text}
            />
          </Grid>
          <Grid
            container
            sx={{ backgroundColor: BG_COLOR_GRAY, minHeight: '60px' }}
          ></Grid>
        </>
      </>
    )
  }

  function DesktopImage({ src }: { src: any }) {
    return (
      <Grid
        item
        container
        justifyContent={'center'}
        xs={3}
        sx={{ padding: '10px' }}
      >
        <img
          src={src}
          style={{
            width: '70%',
            maxWidth: '200px',
            height: '200px',
            maxHeight: '150px',
          }}
        />
      </Grid>
    )
  }
  function DesktopText({ text }: { text: string }) {
    return (
      <Grid item xs={3}>
        <Typography
          sx={{
            padding: '10px',
            fontSize: isTablet ? '0.9rem' : '1.4rem',
            whiteSpace: 'pre-line',
            textAlign: 'center',
          }}
        >
          {text}
        </Typography>
      </Grid>
    )
  }

  return (
    <>
      <>{HEADER}</>
      <Grid
        container
        spacing={2}
        justifyContent={'space-evenly'}
        sx={{
          backgroundColor: BG_COLOR_GRAY,
          paddingLeft: '30px',
          paddingRight: '30px',
        }}
      >
        <DesktopImage src={welcomeStep1} />
        <DesktopImage src={welcomeStep2} />
        <DesktopImage src={welcomeStep3} />
        <DesktopImage src={welcomeStep4} />
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent={'space-evenly'}
        sx={{
          backgroundColor: BG_COLOR_GRAY,
          paddingLeft: '30px',
          paddingRight: '30px',
        }}
      >
        <DesktopText text={step1Text} />
        <DesktopText text={step2Text} />
        <DesktopText text={step3Text} />
        <DesktopText text={step4Text} />
      </Grid>
      <Grid
        container
        sx={{ backgroundColor: BG_COLOR_GRAY, minHeight: '80px' }}
      ></Grid>
    </>
  )
}

function MobileStepImage({
  src,
  text,
  isSmall,
}: {
  src: any
  text: string
  isSmall: boolean
}) {
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        padding: isSmall ? '10px' : '15px',
      }}
    >
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <img src={src} style={{ width: '200px', height: '200px' }} />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography
          sx={{
            margin: 'auto',
            fontSize: '1.2rem',
            whiteSpace: 'pre-line',
            maxWidth: '275px',
          }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

function HowToSaveWithScriptaMobile() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <HowToSaveHeader isSmall={true} />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '40px' }}>
        <HowToSaveVideo />
      </Grid>
      <Grid item container xs={12} justifyContent={'center'}>
        <Grid item sx={{ marginTop: '20px' }}>
          <HowToSaveList isSmall={true} />
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent={'center'}>
        <Grid item sx={{ marginTop: '15px', marginBottom: '15px' }}>
          <HowToSave123Circle isSmall={true} />
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent={'center'}>
        <Grid
          item
          sx={{
            marginleft: '10px',
            marginRight: '10px',
            textAlign: 'center',
            paddingBottom: '20px',
          }}
        >
          <HowToSaveReminder isSmall={true} />
        </Grid>
      </Grid>
    </Grid>
  )
}

function HowToSaveTabletAndDesktop() {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginTop: '0px', marginBottom: '20px' }}>
        <HowToSaveHeader isSmall={false}></HowToSaveHeader>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '20px', marginBottom: '20px' }}>
        <HowToSaveVideo />
      </Grid>
      <Box sx={{ maxWidth: '780px', margin: 'auto' }}>
        <Grid item container xs={12} justifyContent={'center'}>
          <Grid item xs={4} sx={{ padding: '20px' }}>
            <HowToSave123Circle isSmall={false} />
          </Grid>
          <Grid item container xs={8}>
            <Grid item>
              <HowToSaveList isSmall={false} />
            </Grid>
            <Grid item sx={{ paddingBottom: '30px' }}>
              <HowToSaveReminder isSmall={false} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

function HowToSaveHeader({ isSmall }: { isSmall: boolean }) {
  const { COLORS } = useSColors()
  return (
    <Box sx={{ background: COLORS.BACKGROUND_GRADIENT }}>
      <Typography
        sx={{
          fontSize: isSmall ? '1.72rem' : '4rem',
          color: 'white',
          paddingTop: '20px',
          paddingBottom: '20px',
          textAlign: 'center',
          paddingRight: isSmall ? '100px' : '0px',
          paddingLeft: isSmall ? '100px' : '0px',
        }}
      >
        HOW TO SAVE WITH SCRIPTA
      </Typography>
    </Box>
  )
}
function HowToSaveVideo() {
  return (
    <Box sx={{ maxWidth: '800px', margin: 'auto' }}>
      <WelcomeVideoWrapper
        youtubeVideoId="Ejp8rOJDH5s"
        title="How To Save With Scripta"
        staticPreviewImageSrc={videoPreviewHowToSave}
      />
    </Box>
  )
}
function HowToSave123Circle({ isSmall }: { isSmall: boolean }) {
  return (
    <img
      src={SavingCircle123}
      width="100%"
      style={{ maxWidth: isSmall ? '200px' : '250px' }}
    />
  )
}

function HowToSaveList({ isSmall }: { isSmall: boolean }) {
  return (
    <List
      sx={{
        listStyleType: 'number',
        listStylePosition: 'inside',
      }}
    >
      <ListItem
        sx={{
          display: 'list-item',
          fontSize: isSmall ? '1rem' : '1.3rem',
        }}
      >
        Discuss your report with your doctor
      </ListItem>
      <ListItem
        sx={{
          display: 'list-item',
          fontSize: isSmall ? '1rem' : '1.3rem',
        }}
      >
        Ask to switch your script
      </ListItem>
      <ListItem
        sx={{
          display: 'list-item',
          fontSize: isSmall ? '1rem' : '1.3rem',
        }}
      >
        Fill at your in-network pharmacy & SAVE!
      </ListItem>
    </List>
  )
}

function HowToSaveReminder({ isSmall }: { isSmall: boolean }) {
  return (
    <Box>
      <Typography
        sx={{
          padding: '20px',
          fontSize: isSmall ? '1rem' : '1.3rem',
          textAlign: 'left',
        }}
      >
        Remember, always talk to your doctor before making changes to your
        current medications or taking any new ones. Only your doctor can
        determine the medications that are right for you.
      </Typography>
    </Box>
  )
}

function FollowDot({ isSmall }: { isSmall: boolean }) {
  const { COLORS } = useSColors()
  return (
    <Box sx={{ width: '100%', backgroundColor: BG_COLOR_GRAY }}>
      <Box
        sx={{
          maxWidth: '780px',
          margin: 'auto',
          // backgroundColor: BG_COLOR_GRAY,
        }}
      >
        <Grid container alignItems={'flex-start'} sx={{ marginBottom: '30px' }}>
          <Grid item xs={12} sx={{ paddingTop: '30px' }}>
            <Typography
              sx={{
                fontSize: isSmall ? '1.75rem' : '3.25rem',
                paddingLeft: '30px',
                paddingRight: '30px',
                color: COLORS.PRIMARY_BRAND,
                textAlign: 'center',
              }}
            >
              FOLLOW THE PURPLE DOT TO SAVINGS...
            </Typography>
          </Grid>
          <Grid item container xs={isSmall ? 12 : 8} sx={{ marginTop: '30px' }}>
            <Grid item xs={12}>
              <FollowBullet
                isSmall={isSmall}
                title="The Medicine You Need, at the Best Possible Price"
                text="To help you and your doctor choose the medicines that are best for you, Scripta acts like a personal shopper. We scour the market every day to identify money-saving options."
              />
            </Grid>
            <Grid item xs={12}>
              <FollowBullet
                isSmall={isSmall}
                title="Personalized Savings Reports"
                text="We create Personalized Savings Reports just for you. Your reports may come via mail, email, SMS or be accessed, anytime, 24/7 through the secure Scripta Member Portal or app."
              />
            </Grid>
            <Grid item xs={12}>
              <FollowBullet
                isSmall={isSmall}
                title="Strategies Specific to You"
                text="Your reports list lower-priced drug options and savings strategies specific to you. The suggested alternatives are the same or clinically equivalent to the medicines you’re already taking."
              />
            </Grid>
            <Grid item xs={12}>
              <FollowBullet
                isSmall={isSmall}
                title="A Helpful Tool to Discuss with Your Doctor"
                text="Doctors often don’t know how much your medications cost. Scripta gives you and your doctor the information needed to decide which drugs are best for your health and your wallet—based on pricing on your insurance plan. "
              />
            </Grid>
          </Grid>
          <Grid item xs={isSmall ? 12 : 4} sx={{ marginTop: '30px' }}>
            <img
              src={followdot}
              width="100%"
              style={{
                maxHeight: isSmall ? '300px' : '525px',
                paddingTop: isSmall ? '0px' : '50px',
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ minHeight: isSmall ? '10px' : '50px' }} />
      </Box>
    </Box>
  )
}

function HowToReadYourSavingsReport({ isSmall }: { isSmall: boolean }) {
  const [selectedVideo, setSelectedVideo] = React.useState(VIDEO_ID_HOW_TO_READ)
  const [hoeveredVideo, setHoveredVideo] = React.useState<string | undefined>(
    undefined,
  )
  const [forceStartPlay, setForceStartPlay] = React.useState(false)
  const { COLORS } = useSColors()
  return (
    <Box sx={{ maxWidth: '780px', margin: 'auto' }}>
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography
            sx={{
              fontSize: isSmall ? '1.75rem' : '3rem',
              color: COLORS.PRIMARY_BRAND,
              paddingTop: '20px',
              paddingBottom: '20px',
              paddingLeft: '20px',
              paddingRight: '20px',
              textAlign: 'center',
            }}
          >
            HOW TO READ YOUR SAVINGS REPORT
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <WelcomeVideoWrapper
            youtubeVideoId={selectedVideo}
            title={
              selectedVideo === VIDEO_ID_HOW_TO_READ
                ? 'How To Read Your Savings Report'
                : 'Sample Savings Report'
            }
            staticPreviewImageSrc={
              selectedVideo === VIDEO_ID_HOW_TO_READ
                ? videoPreviewHowToReadYourReport
                : videoPreviewSavingsExample
            }
            forceStartPlay={forceStartPlay}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={'center'}
          sx={{ paddingTop: '30px', paddingLeft: isSmall ? '0px' : '10px' }}
        >
          <Grid
            item
            xs={isSmall ? 6 : undefined}
            sx={{ textAlign: isSmall ? 'right' : 'center' }}
            onMouseEnter={() => setHoveredVideo(VIDEO_ID_HOW_TO_READ)}
            onMouseLeave={() => setHoveredVideo(VIDEO_ID_SAMPLE_SAVINGS)}
          >
            <img
              src={
                hoeveredVideo === VIDEO_ID_HOW_TO_READ
                  ? videoPreviewHowToReadYourReport
                  : videoPreviewHowToReadYourReport_whitebg
              }
              width={isSmall ? '150px' : '227px'}
              height={isSmall ? 'auto' : '130px'}
              style={{
                cursor: 'pointer',
                border: '1px solid black',
              }}
              // height="100%"
              onClick={() => {
                setSelectedVideo(VIDEO_ID_HOW_TO_READ)
                setForceStartPlay(true)
              }}
            />
          </Grid>
          <Grid
            item
            xs={isSmall ? 6 : undefined}
            sx={{
              textAlign: isSmall ? 'left' : 'center',
              paddingLeft: isSmall ? '5px' : '20px',
            }}
            onMouseEnter={() => setHoveredVideo(VIDEO_ID_SAMPLE_SAVINGS)}
            onMouseLeave={() => setHoveredVideo(VIDEO_ID_HOW_TO_READ)}
          >
            <img
              src={
                hoeveredVideo === VIDEO_ID_SAMPLE_SAVINGS
                  ? videoPreviewSavingsExample
                  : videoPreviewSavingsExample_whitebg
              }
              style={{ cursor: 'pointer', border: '1px solid black' }}
              width={isSmall ? '150px' : '227px'}
              height={isSmall ? 'auto' : '130px'}
              // height="50%"
              onClick={() => {
                setSelectedVideo(VIDEO_ID_SAMPLE_SAVINGS)
                setForceStartPlay(true)
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '30px' }} />
    </Box>
  )
}

function WhatWillYouDo({ isSmall }: { isSmall: boolean }) {
  const { COLORS } = useSColors()
  return (
    <Box sx={{ backgroundColor: BG_COLOR_GRAY }}>
      <Box sx={{ maxWidth: '820px', margin: 'auto' }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              paddingLeft: isSmall ? '10px' : '20px',
              paddingRight: isSmall ? '10px' : '20px',
            }}
          >
            <Typography
              sx={{
                fontSize: isSmall ? '1.75rem' : '3rem',
                color: COLORS.PRIMARY_BRAND,
                paddingTop: '20px',
                paddingBottom: '20px',
                textAlign: 'center',
                paddingLeft: isSmall ? '10px' : '20px',
                paddingRight: isSmall ? '10px' : '20px',
              }}
            >
              WHAT WILL YOU DO WITH YOUR PRESCRIPTION SAVINGS?
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid
              item
              xs={12}
              md={5}
              container
              justifyContent={'center'}
              sx={{
                textAlign: 'center',
                paddingLeft: isSmall ? '10px' : 0,
                paddingRight: isSmall ? '10px' : 0,
              }}
            >
              <Grid item>
                <img src={whatwillyoudo} width="100%" height="100%" />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{ padding: '10px', textAlign: 'left' }}
            >
              <Typography
                sx={{
                  fontSize: '1.2rem',
                  marginTop: '25px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                }}
              >
                Scripta has saved plan members, like you, millions of dollars on
                prescriptions for more than a decade.
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.2rem',
                  marginTop: '15px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                }}
              >
                We also save your health plan money, which keeps the cost of
                benefits as low as possible for everyone.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 600,
                  padding: isSmall ? '10px' : '20px',
                  textAlign: 'center',
                  marginTop: isSmall ? '0px' : '20px',
                }}
              >
                Check Your Personalized Savings Report and Start Saving Now!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ minHeight: '30px' }} />
      </Box>
    </Box>
  )
}

function NavigatingAppAndPortal({ isSmall }: { isSmall: boolean }) {
  let customHeight = '225px'
  let customWidth = '400px'
  const { COLORS } = useSColors()
  return (
    <Box sx={{ backgroundColor: BG_COLOR_GRAY }}>
      <Box sx={{ maxWidth: '820px', margin: 'auto' }}>
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Typography
              sx={{
                fontSize: isSmall ? '1.75rem' : '3rem',
                color: COLORS.PRIMARY_BRAND,
                paddingTop: '20px',
                // paddingBottom: '20px',
                textAlign: 'center',
                whiteSpace: 'pre-line',
              }}
            >
              {`NAVIGATING THE SCRIPTA \n APP & PORTAL`}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            spacing={2}
            sx={{ paddingTop: '30px' }}
          >
            <Grid item xs={isSmall ? 12 : 6}>
              <WelcomeVideoWrapper
                youtubeVideoId={VIDEO_ID_NAVIGATING_1}
                title="Scripta App & Portal Registration"
                staticPreviewImageSrc={videoPreviewNavigating1}
                customHeight={customHeight}
                customWidth={customWidth}
              />
            </Grid>
            <Grid item xs={isSmall ? 12 : 6}>
              <WelcomeVideoWrapper
                youtubeVideoId={VIDEO_ID_NAVIGATING_2}
                title="Scripta App & Portal Tutorial"
                staticPreviewImageSrc={videoPreviewNavigating2}
                customHeight={customHeight}
                customWidth={customWidth}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ minHeight: '30px' }} />
      </Box>
    </Box>
  )
}
function FollowBullet({
  title,
  text,
  isSmall,
}: {
  title: string
  text: string
  isSmall: boolean
}) {
  //left bullet image and rigt side text aligned to top
  //box with centered content inside
  const { COLORS } = useSColors()

  return (
    <Grid container sx={{ marginTop: '20px' }}>
      <Grid item xs={12} container>
        <Grid
          item
          xs={isSmall ? 0.9 : 0.3}
          container
          justifyContent={'center'}
          sx={{
            paddingLeft: !isSmall ? '10px' : '0px',
          }}
        >
          <img
            src={followbullet}
            width="20px"
            height="20px"
            style={{
              marginTop: '9px',
              marginLeft: '15px',
            }}
          />
        </Grid>
        <Grid item xs={isSmall ? 11.1 : 11.7}>
          <Typography
            sx={{
              marginLeft: '20px',
              fontSize: '1.5rem',
              color: COLORS.PRIMARY_BRAND,
              paddingLeft: '10px',
              paddingRight: '15px',
              marginBottom: '10px',
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={isSmall ? 0.8 : 0.2}></Grid>
        <Grid item xs={isSmall ? 11.2 : 11.8}>
          <Typography
            sx={{
              marginLeft: '20px',
              marginRight: '20px',
              fontSize: '1rem',
              color: COLORS.PRIMARY_BRAND,
              paddingLeft: '10px',
              paddingRight: !isSmall ? '25px' : '15px',
            }}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

function DottedSpacer() {
  const { COLORS } = useSColors()
  return (
    <Box
      sx={{
        background: COLORS.BACKGROUND_GRADIENT,
        height: '50px',
        // marginTop: '20px',
        // marginBottom: '20px',
      }}
    ></Box>
  )
}
const VIDEO_DEKSTOP_W = '760px'
const VIDE_DSKTOP_H = '430px'
const VIDEO_MOBILE_W = '320px'
const VIDEO_MOBILE_H = '180px'

const VIDEO_TABLET_W = '480px'
const VIDEO_TABLET_H = '270px'

function WelcomeVideoWrapper({
  youtubeVideoId,
  title,
  forceStartPlay,
  staticPreviewImageSrc,
  customWidth,
  customHeight,
}: {
  youtubeVideoId: string
  title: string
  forceStartPlay?: boolean
  staticPreviewImageSrc: any
  customWidth?: string
  customHeight?: string
}) {
  const [showPreview, setShowPreview] = React.useState(true)

  useEffect(() => {
    if (forceStartPlay) {
      setShowPreview(false)
    }
  }, [forceStartPlay])

  const theme = useTheme()

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  let width = '320px'
  let height = '180px'
  if (isSmall) {
    width = '100%'
    height = 'auto'
  } else if (isTablet) {
    width = VIDEO_TABLET_W
    height = VIDEO_TABLET_H
  } else {
    width = VIDEO_DEKSTOP_W
    height = VIDE_DSKTOP_H
  }

  if (customWidth) {
    width = customWidth
  }
  if (customHeight) {
    height = customHeight
  }
  const opts = {
    height: height,
    width: width,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  }

  if (showPreview) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
              alignItems: 'center',
            }}
          >
            <img
              style={{ border: '1px solid black' }}
              src={staticPreviewImageSrc}
              width={width}
              height={height}
              onClick={() => setShowPreview(false)}
            />
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <YouTube
            videoId={youtubeVideoId}
            opts={opts}
            // onReady={this._onReady}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
