import { Grid, Typography, useTheme, useMediaQuery, Box } from '@mui/material'
import PLAYSTORE_LOGO from '../../images/playstore.png'
import APPSTORE_LOGO from '../../images/apple.png'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { GAService } from '../application/ga/GAService'
const GOOLE_PLAY_IMAGE_URL =
  'https://scripta-public-assets.s3.amazonaws.com/memberapp/google_play_link.png'
export const GOOGLE_PLAY_TARGET_LINK =
  'https://play.google.com/store/apps/details?id=com.scriptainsights'
const IOS_IMAGE_URL =
  'https://scripta-public-assets.s3.amazonaws.com/memberapp/ios_app_store_link.png'
export const IOS_APP_TARGET_LINK =
  'https://apps.apple.com/us/app/scripta-insights/id1548430674'

const imageStyle = {
  width: '150px',
  maxWidth: '150px',
  minWidth: '150px',
}

export function MobileAppLinks() {
  const gaService = new GAService()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useSTranslate('savingReport')
  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Typography
            width={'19rem'}
            component="div"
            sx={{
              fontSize: isSmallScreen ? 28 : 30,
              fontWeight: isSmallScreen ? 600 : 400,
              lineHeight: '36.54px',
              color: isSmallScreen ? '#4D008C !important' : '#FFFFFF',
            }}
          >
            <div>{t('accessYour')}</div>
            <div>{t('reportAnytime')}</div>
          </Typography>
          <Typography
            sx={{
              width: 'max-content',
              fontSize: 18,
              fontWeight: 400,
              color: isSmallScreen ? '#66636C !important' : '#FFFFFF',
              marginTop: '10px',
              marginBottom: '5px',
              lineHeight: '21.92px',
            }}
          >
            {t('downloadApp')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} style={{ marginTop: '10px' }}>
          <a
            href={IOS_APP_TARGET_LINK}
            target="_blank"
            onClick={() =>
              gaService.trackEvent(
                gaService.eventMap.generate_footer_link_event('iOS App Store'),
              )
            }
          >
            <img
              src={APPSTORE_LOGO}
              alt="Download from IOS Store"
              style={imageStyle}
              height={'50px'}
            />
          </a>
        </Grid>
        <Grid item xs={6} style={{ marginTop: '10px' }}>
          <a
            href={GOOGLE_PLAY_TARGET_LINK}
            target="_blank"
            onClick={() =>
              gaService.trackEvent(
                gaService.eventMap.generate_footer_link_event(
                  'Google Play Store',
                ),
              )
            }
          >
            <img
              src={PLAYSTORE_LOGO}
              alt="Download from Google Play Store"
              style={imageStyle}
              height={'50px'}
            />
          </a>
        </Grid>
      </Grid>
    </Box>
  )
}
