import { useAmazon } from 'src/app/hooks/useAmazon'
import { PortalAppConfig } from '../app-root-types'
import {
  AMAZON_PORTAL_APP_CONFIG,
  DEFAULT_PORTAL_APP_CONFIG,
} from './appconfig'

export function usePortalAppConfig(): PortalAppConfig {
  const { isAmazon } = useAmazon()
  return isAmazon ? AMAZON_PORTAL_APP_CONFIG : DEFAULT_PORTAL_APP_CONFIG
}
