import { Box, Grid, Paper, Theme, useMediaQuery, useTheme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { ClientLogo } from '../member/MemberLogo'
import { StepProgressBar } from '../widgets/StepProgressBar'
import MemberSupportFooter from './MemberSupportFooter'
import RegistrationAnimateChangeInHeight from './RegistrationAnimateChangeInHeight'
import { RegistrationProvider } from './RegistrationContext'
import { RegistrationSubRoutes } from './RegistrationSubRoutes'

export function RegistrationContainer() {
  const backgroundImageDesktop = "url('/images/new-bg-1920x1080.jpg')"
  const backgroundImageTabletV2 = "url('/images/new-bg-768x1024-v2.jpg')"
  const backgroundImageMobileV3 = "url('/images/new-bg-390x844-v3.jpg')"

  const useLandStyles = makeStyles((theme: Theme) =>
    createStyles({
      pillsStrechBackgroundImage: {
        backgroundImage: backgroundImageDesktop,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'fixed',
        height: '100vh',
        //stretch this out a little bit to better fit the member support footer text against some of the pills in the background
        width: '105vw',
        margin: '-8px',
        zIndex: -1,
        //these are approximate breakpoints where the custom background images scales the best
        //and corners do not override and content
        [theme.breakpoints.down(1400)]: {
          backgroundImage: backgroundImageDesktop,
        },
        [theme.breakpoints.down(1200)]: {
          backgroundImage: backgroundImageTabletV2,
        },
        [theme.breakpoints.down(500)]: {
          backgroundImage: backgroundImageMobileV3,
        },
      },
    }),
  )

  const classes = useLandStyles()

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  //need a bit different width & height for amaon logo
  let width = 132
  let height = 40
  if (theme.customized.name === 'amazon-blue') {
    width = 94
    height = 40
  }
  return (
    <RegistrationProvider>
      {/* <CssBaseline /> */}
      <div className={classes.pillsStrechBackgroundImage}></div>
      <div>
        <Box
          sx={(theme) => ({
            // backgroundColor: 'green',
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            // minHeight: '87vh',
            minHeight: '98vh', //this is just to remove scrollbar on desktop when it fits
            // paddingTop: '11vh',
            [theme.breakpoints.down('sm')]: {
              // paddingTop: '5vh',
            },
          })}
        >
          <Box
            sx={{
              display: 'flex',
              // backgroundColor: 'red',

              //need to reduce the header height on mobile
              minHeight: isSmall ? '50px' : '70px',
              height: isSmall ? '50px' : '70px',
            }}
          >
            <img
              alt="Main Logo"
              src={theme.customized.whiteOnDarkLogoUrl}
              width={width}
              // height="40px"
              style={{
                alignSelf: 'center',
                height: height,
                // position: 'absolute',
                // top: '20px',
              }}
            />
          </Box>

          <Box
            sx={(theme) => ({
              // backgroundColor: 'green',
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              // marginTop: '200px',
              // marginTop: '20px',
              // marginLeft: '10px',
              // marginRight: '10px',

              [theme.breakpoints.down('sm')]: {
                // marginTop: '20px',
                width: '90vw',
                paddingLeft: '10px',
                paddingRight: '10px',
              },
              [theme.breakpoints.up('sm')]: {
                maxWidth: '554px',
                width: '554px',
                // paddingLeft: '100px',
                // paddingRight: '100px',
              },
            })}
          >
            <Paper
              sx={{
                //this gives the border around the whole card
                borderRadius: '15px',
                border: '3px solid white',
                borderColor: 'white',
                overflow: 'hidden',
              }}
            >
              <RegistrationAnimateChangeInHeight>
                <ClientLogo isSmall={isSmall} />
                <RegistrationSubRoutes />
              </RegistrationAnimateChangeInHeight>
            </Paper>
          </Box>
          <Box
            sx={{
              display: 'flex',
              minHeight: '60px',
              height: '60px',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              // alignItems: 'flex-end',
              // justifySelf: 'center',
            }}
          >
            <Box>
              <StepProgressBar />
            </Box>
            <Box>
              <MemberSupportFooter />
            </Box>
          </Box>
        </Box>
      </div>
    </RegistrationProvider>
  )
}

//little more padding on the left and right in desktop when showing input fields and controls
export function RegistrationFormFieldsBox({
  children,
  customWidth,
}: {
  children: any
  customWidth?: string
}) {
  const theme = useTheme()

  return (
    <Box
      sx={(theme) => ({
        // backgroundColor: 'green',
        minWidth: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      })}
    >
      <Box
        sx={(theme) => ({
          alignSelf: 'center',
          [theme.breakpoints.down('sm')]: {
            width: customWidth ? customWidth : '90%',
            maxWidth: customWidth ? customWidth : '90%',
            // paddingLeft: '20px',
            // paddingRight: '20px',
          },
          [theme.breakpoints.up('sm')]: {
            width: customWidth ? customWidth : '334px',
            maxWidth: customWidth ? customWidth : '334px',
          },
        })}
      >
        {children}
      </Box>
    </Box>
  )
}

//used to be AWrap in individual compoennts
function WrappedSubroutesContent({ children }: { children: any }) {
  const theme = useTheme()

  return (
    <Paper
      sx={{
        maxWidth: '250px',
        [theme.breakpoints.between('xs', 'md')]: {
          width: '90vw',
        },

        // boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.1)',
        borderRadius: '15px',
        border: '3px solid white',
        // borderColor: 'black',
        // borderSize: '5px',
        // padding: '5px',
      }}
      elevation={2}
      style={{ overflow: 'hidden' }}
    >
      <Grid
        item
        xs={12}
        sx={{
          // padding: '30px',
          // paddingBottom: '32px',
          [theme.breakpoints.between('xs', 'md')]: {
            // padding: '30px',
          },
        }}
      >
        {children}
      </Grid>
    </Paper>
  )
}
