import { Grid, Button, useTheme, useMediaQuery } from '@mui/material'
import React from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useHistory } from 'react-router'
import { useSColors } from '../styles/scripta-theme'
import SButton from '../system/customcomponents/SButton'

export function RegistrationToolbar({
  onClickNext,
  nextDisabled = false,
  backHidden = false,
  onClickCancel,
  customNextLabel,
  useBackLabelForCancel = false,
  // my,
  customCancelTitle,
  sidePadding = undefined,
}: //adding this custom top maring to reduce it in case when we showing overflow
// customMarginTop,
{
  nextDisabled?: boolean
  backHidden?: boolean
  onClickNext?: any
  onClickCancel?: any
  customNextLabel?: string
  // my?: string
  useBackLabelForCancel?: boolean
  customCancelTitle?: string
  sidePadding?: number | string
  // customMarginTop?: string
}) {
  const { t } = useSTranslate('common')
  const history = useHistory()

  //need different padding in the toolbar on phone
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      item
      xs={12}
      sx={{
        // marginTop: '10px',
        marginTop: isSmall ? '10px' : '20px',
        marginBottom: isSmall ? '10px' : '20px',
        // backgroundColor: 'red',
      }}
    >
      <Grid
        item
        container
        justifyContent="center"
        style={{ paddingLeft: sidePadding, paddingRight: sidePadding }}
      >
        <Grid item xs={12}>
          <SButton
            data-testid="registration-next-button"
            variant="contained"
            fullWidth
            sx={{
              height: '40px',
              fontSize: '1rem',
            }}
            disabled={nextDisabled}
            onClick={onClickNext}
          >
            {customNextLabel ? customNextLabel : t('next')}
          </SButton>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '5px' }}>
          {!backHidden && (
            <SButton
              data-testid="registration-cancel-button"
              onClick={() => {
                if (onClickCancel) {
                  onClickCancel()
                } else {
                  history.goBack()
                }
              }}
              variant="outlined"
              fullWidth
              sx={{
                height: '40px',
                fontSize: '1rem',
              }}
            >
              {customCancelTitle
                ? customCancelTitle
                : useBackLabelForCancel
                ? t('back')
                : t('cancel')}
            </SButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
