import {
  Box,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { TenantIdWithOptions } from '../../../../types'
import { useSColors } from 'src/app/styles/scripta-theme'

export enum NewTenantRadioGroupOptions {
  SSN = 'SSN',
  MEMBER_ID = 'MID',
  COMPANY_ID = 'EID',
}

interface NewTenantRadioGroupProps extends RadioGroupProps {
  isSmall: boolean
  matchedTenant: TenantIdWithOptions
}

type keys = 'ssnAvailable' | 'memberIdAvailable' | 'companyIdAvailable'

export const NewTenantRadioGroup = ({
  isSmall,
  value,
  onChange,
  matchedTenant,
}: NewTenantRadioGroupProps) => {
  const { t } = useSTranslate('register')
  const { COLORS } = useSColors()
  //check is small

  const options: {
    descriptionKey: string
    value: string
    id: keys
  }[] = [
    {
      id: 'ssnAvailable',
      descriptionKey: 'newTenantRadioSSN',
      value: NewTenantRadioGroupOptions.SSN,
    },
    {
      id: 'memberIdAvailable',
      descriptionKey: 'newTenantRadioMID',
      value: NewTenantRadioGroupOptions.MEMBER_ID,
    },
    {
      id: 'companyIdAvailable',
      descriptionKey: 'newTenantRadioEID',
      value: NewTenantRadioGroupOptions.COMPANY_ID,
    },
  ]

  return (
    <Box sx={{ paddingLeft: isSmall ? '0px' : '10px' }}>
      <RadioGroup
        aria-labelledby="identification-type-radio-buttons-group-label"
        defaultValue="female"
        name="identification-type-radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {options.map(
          (option, idx) =>
            matchedTenant[option.id] && (
              <Box
                key={option.id}
                // value={option.value}
                sx={{
                  // marginTop: '5px',
                  padding: '5px 5px 5px 5px',
                  // border: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  // alignItems: 'flex-start',
                  // justifyContent: 'flex-start',
                }}
              >
                <Radio
                  data-testid={`identification-type-input-${option.id}-radio`}
                  value={option.value}
                  sx={{
                    padding: '0px',
                    alignSelf: 'flext-start',
                    color: COLORS.PRIMARY_BRAND,
                  }}
                />
                <Typography
                  align="left"
                  sx={{
                    paddingLeft: '10px',
                    display: 'flex',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontSize: '1rem',
                      lineHeight: '24px',
                      // paddingLeft: '10px',
                      paddingRight: '10px',
                    }}
                  >
                    {t(option.descriptionKey)}
                    {option.descriptionKey === 'newTenantRadioMID' && (
                      <span
                        style={{
                          fontSize: '0.8rem',
                          fontStyle: 'italic',
                          opacity: 0.75,
                        }}
                      >
                        {t('newTenantRadioMIDNoSpaces')}
                      </span>
                    )}
                  </Typography>
                </Typography>
              </Box>
            ),
        )}
      </RadioGroup>
    </Box>
  )
}
