import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import {
  isServerErrorResponse,
  isValidResponse,
  MemberProfile,
  MemberSavingReport,
} from '../../types'
import {
  checkMicroServiceResponseStatus,
  roundAllSavingsAmountsToTwoDecimals,
} from '../apiexec/utils'
import { useMemberService } from '../member/useMemberService'
import { isProvider, useMemberAppContext } from '../MemberAppContext'
import { ROUTE_APP_ROOT, ROUTE_PROVIDER_ROOT } from '../PortalRoutes'

import { Warning as WarningIcon } from '@mui/icons-material'
import { Box, CircularProgress, Grid } from '@mui/material'
import { usePortalAppConfig } from 'src/app/config/usePortalAppConfig'
import STypography from 'src/app/system/customcomponents/STypography'
import { LOG } from '../v2/applog'

export function CompleteLoginHandler() {
  const history = useHistory()
  const PORTAL_CONFIG = usePortalAppConfig()

  const {
    jwt,
    setCurrentMemberProfile,
    setSavingReport,
    jwtDetails,
    isSsoLogin,
    mainDataLoading,
    setMainDataLoading,
  } = useMemberAppContext()
  const memberService = useMemberService()

  // const [mainDataLoading, setMainDataLoading] = useState(false)
  const [mainDataLoadError, setMainDataLoadError] = useState(false)

  //WARNING - please node that this function also calls the asyncLoadSavingsReport function where we fetch additional
  //details from the microservice, and update the current member profile with the latest zip code
  //TODO - also update here to use additional fields like marketing preferences etc
  async function asyncLoadProfile() {
    setMainDataLoading(true)
    try {
      const resp = await memberService.getMemberProfileApi()

      //we have some extra error hanlding here - for both server errors and api call errors
      //mostly for initial rollout of sso and easier debug

      if (isServerErrorResponse<MemberProfile>(resp)) {
        setMainDataLoadError(true)
        //custom event handler for sso related users
        if (isSsoLogin) {
          LOG.error(
            'sso',
            'server error response, Unable to fetch member profile for the incoming sso user',
            resp,
          )
        } else {
          //cannot fetch member profile - but not sso related
          LOG.error(
            'registration',
            'server error response Unable to fetch member profile for the incoming user',
            resp,
          )
        }
        //do not push to error handler anymore - these are core errors to be handled here
        // history.push(REG_ROUTE_SERVER_ERROR)
        setMainDataLoading(false)
        return
      }

      if (isValidResponse<MemberProfile>(resp)) {
        setCurrentMemberProfile(resp)

        if (resp) {
          LOG.initMember(resp.memberId, resp.tenantId)
        }
        try {
          await asyncLoadSavingsReport(resp)
          setMainDataLoading(false)
        } catch (error) {
          console.error('error ')
          //,error=', error)
          setMainDataLoading(false)
          throw error
        }
      } else {
        setMainDataLoading(false)
        setMainDataLoadError(true)
        if (isSsoLogin) {
          LOG.error(
            'sso',
            'Invalid response returned by the get member profile api',
            resp,
          )
        } else {
          LOG.error(
            'registration',
            'not a valid response from get member profile api',
            resp,
          )
        }

        // history.push(REG_ROUTE_ERROR_HANDLER)
      }
    } catch (e) {
      setMainDataLoading(false)
      setMainDataLoadError(true)
      let errorDetails =
        e && (e as any).message !== undefined ? (e as any).message : undefined
      const logMessage = isSsoLogin
        ? 'Network / server level error in api execution for SSO user. Request timed out / failed to reach the API server.'
        : 'Network / server level error in api execution. Request timed out / failed to reach the API server.'
      if (isSsoLogin) {
        LOG.error('sso', logMessage, errorDetails)
      } else {
        LOG.error('registration', logMessage, errorDetails)
      }
    }
  }

  async function asyncInitProviderProfile() {
    //TODO - init the provider profile
    history.push(ROUTE_PROVIDER_ROOT)
  }

  async function asyncLoadSavingsReport(currentMemberProfile: MemberProfile) {
    try {
      const resp = await memberService.getMemberSavingsReportApi()

      // if (isServerErrorResponse<MemberSavingReport>(resp)) {
      //   history.push(REG_ROUTE_SERVER_ERROR)
      //   return
      // }

      if (isValidResponse<MemberSavingReport>(resp)) {
        //update to amounts to two rounded decimal places

        let roundedUpReport = roundAllSavingsAmountsToTwoDecimals(resp)
        //  roundedUpReport.isCopay = roundedUpReport.reportType === "COPAY"
        setSavingReport(roundedUpReport)
        //try to fetch additional details from the microservice, to get the latest zip file
        //TODO - other details like report type,martketing preference etc should be fetched from this
        //make sure to grab them one by one, not spread the whole object
        let additionalDetailsResp = undefined
        let latestZip = undefined
        try {
          additionalDetailsResp =
            await memberService.getMemberProfileExtendedDetails(
              currentMemberProfile.memberId,
            )
          const microRespCheck = checkMicroServiceResponseStatus(
            additionalDetailsResp,
          )
          if (!microRespCheck.isError) {
            latestZip = additionalDetailsResp?.zip
          } else {
            console.error(
              'not a valid response from additional member profile details, will ignore..',
            )
          }
        } catch (error) {
          console.error('Error loading additional details...')
        }

        const memberProfileWithReportAndZip: MemberProfile = {
          ...currentMemberProfile,
          reportType: roundedUpReport.reportType,
          isCopay: roundedUpReport.reportType === 'COPAY',
          // zip: undefined,
          zip: latestZip ? latestZip : currentMemberProfile.zip,
        }
        setCurrentMemberProfile(memberProfileWithReportAndZip)

        history.push(ROUTE_APP_ROOT)
      } else {
        setMainDataLoadError(true)

        //custom event handler for sso related users
        if (isSsoLogin) {
          LOG.error(
            'sso',
            'Unable to fetch member savings report for the sso user',
            resp,
          )
        } else {
          //cannot fetch savings profile - but not sso related
          LOG.error(
            'saving_report_data',
            'Unable to fetch savings report data for the member',
            resp,
          )
        }

        setMainDataLoading(false)
        setMainDataLoadError(true)
        // setApiResponse(resp)
        // history.push(ROUTE_GENERIC_ERROR)
      }
    } catch (e) {
      setMainDataLoading(false)
      setMainDataLoadError(true)

      console.error('Not a valid response from get savings report api, e=', e)
      //dont swallow this exception - the error boundary now takes care of this
      console.error('error in complete login - load savings report handler', e)
      throw e
    }
  }

  useEffect(() => {
    //this may happen in case of sso login
    if (jwtDetails === undefined) {
      return
    }
    if (isProvider(jwtDetails)) {
      asyncInitProviderProfile()
    } else {
      asyncLoadProfile()
    }
  }, [jwtDetails])

  //add a custom loader for sso users because we dont have a normal login component
  if (mainDataLoading && isSsoLogin) {
    return (
      <Box sx={{ padding: '50px' }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress variant="indeterminate" size={30} />
          </Grid>
          <Grid item xs={12}>
            <STypography variant="body_regular" sx={{ textAlign: 'center' }}>
              Please wait, loading your savings report....
            </STypography>
          </Grid>
        </Grid>
      </Box>
    )
  }

  //add a custom error handler for sso users
  if (mainDataLoadError) {
    return (
      <Box sx={{ padding: '50px' }}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <WarningIcon color="warning" fontSize="large" />
          </Grid>
          <Grid item xs={12}>
            <STypography variant="body_regular" sx={{ textAlign: 'center' }}>
              We are sorry, there was an error completing your login.
            </STypography>
          </Grid>
          <Grid item xs={12}>
            <STypography variant="body_regular" sx={{ textAlign: 'center' }}>
              Please contact our member support at{' '}
              <a href={`mailto:${PORTAL_CONFIG.MEMBER_SUPPORT_EMAIL}`}>
                {PORTAL_CONFIG.MEMBER_SUPPORT_EMAIL}
              </a>{' '}
              or call us at{' '}
              <a href={`tel:${PORTAL_CONFIG.MEMBER_SUPPORT_NUMBER}`}>
                {PORTAL_CONFIG.MEMBER_SUPPORT_NUMBER}
              </a>
            </STypography>
          </Grid>
        </Grid>
      </Box>
    )
  }
  return null
}
