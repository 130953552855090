export const BOTTOM_NAV_HEIGHT = '100px'

export const RADIUS = {
  xs: '4px',
  sm: '8px',
  md: '16px',
}

export const SPACING = {
  _0: '0px',
  _025: '4px',
  _05: '8px',
  _075: '12px',
  _1: '16px',
  _15: '24px',
  _2: '32px',
  _25: '40px',
}
export const MARGIN = {
  xs: '10px',
  sm: '20px',
}
export const FONT_WEIGHT = {
  REGULAR: 400,
  SEMI_BOLD: 600,
  EXTRA_BOLD: 700,
}
export const FONT_SIZE = {
  BODY_SMALL: '0.8rem',
  BODY: '1rem',
  BODY_BIG: '1.2rem',
  TITLE_3: '1.3rem',
  TITLE_2: '1.35rem',
  TITLE_1: '1.8rem',
}
export const ICON_FONT_SIZE = {
  SMALL_16px: '16px',
  //check why design is 38 but it seems to big?
  MEDIUM_32px: '32px',
  MEDIUM_38px: '38px',
  LARGE: '60px',
}

// export const COLORS = {
//   PRIMARY_BRAND: BRAND_PURPLE,
//   BRAND_40: '#B899D1',
//   BORDER_BRAND: BRAND_PURPLE,
//   BACKGROUND_BRAND_10: '#EDE5F3',
//   BACKGROUND_BRAND_20: '#DBCCE8',

//   BACKGROUND_LIGHT: 'white',

//   BACKGROUND_SECONDARY: BRAND_BLUE,
//   BACKGROUND_SECONDARY_ACTIVE: '#006EF5',
//   BORDER_GREY_LIGHT: '#D0CFD3',

//   TEXT_BRAND: BRAND_PURPLE,
//   TEXT_SECONDARY: '#006EF5',

//   BACKGROUND_GREY_MEDIUM: '#D0CFD3',
//   BACKGROUND_GREY_LIGHT: '#E5E4E7',
//   BACKGROUND_GREY_LIGHTER: '#F7F7F8',
//   BACKGROUND_GREY_DARKER: '#2C2B2E',
//   BACKGROUND_LIGHT_GREEN: '#E5F3E5',
//   BACKGROUND_LIGHT_BLUE: '#EAF4FF',

//   TEXT_BLACK: '#201F22',
//   TEXT_LIGHT_PRIMARY_WHITE: '#FFFFFF',
//   TEXT_GREY_MEDIUM: '#66636C',
//   TEXT_GREY_LIGHT: '#96949A',
//   TEXT_GREY_LIGHTER: '#C3C1C6',
//   TEXT_GREY_DARK: '#4D4B51',
//   TEXT_GREEN: '#008800',
// }
