import history from 'src/app/scripta-browser-history'
import { ApiUrl, ModuleConfig } from '../../types'
import { REG_ROUTE_JWT_EXP_LOGOUT } from '../PortalRoutes'

import { getPortalApiRootUrl, IS_NATIVE, resolveMicroServerRoot } from './utils'

//this header is passed along when executing api requests from portal to the backend
//to distinguish between mobile app vs portal
export const ScriptaAppLocation_Header_Value_Portal = 'P'
export const ScriptaAppLocation_Header_Value_Mobile = 'M'

export interface ExOps {
  customHeaders?: any
  //added to handle for example adding new favorited drug. in this case microservice returns 201 but no body content
  //we want to skip await resp.json() in that case and return the raw fetch response so the UI can check http raw status
  returnRawFetchResponse?: boolean
  langCode?: string
}

export class PortalApiEx {
  private _jwt?: string

  constructor(jwt?: string) {
    this._jwt = jwt
  }

  setJwt(jwt: string) {
    this._jwt = jwt
  }

  /**
   * @param {*} [extraOps] Optional arguments for future.
   * Might be needed to enable shared validation, errors, success msgs, action confirmation popups etc
   */
  post<ReturnType>(url: string, payload: any, ops?: ExOps) {
    return execPortalNetRequest({
      url,
      method: 'post',
      jsonBody: payload,
      jwt: this._jwt,
      ops,
    })
  }
  put<ReturnType>(url: string, payload: any, ops?: ExOps) {
    return execPortalNetRequest({
      url,
      method: 'put',
      jsonBody: payload,
      jwt: this._jwt,
      ops,
    })
  }
  get<ReturnType>(url: string, ops?: ExOps) {
    return execPortalNetRequest({
      url,
      method: 'get',
      jwt: this._jwt,
      ops,
    })
  }
  //lets make it clear for microservices extra options (i.e. api headers are required)
  //otherwise we may get misleading errors from backend (i.e. 404 is thrown if that header is missing)
  getMicro<ReturnType>(url: string, ops: ExOps) {
    return this.get(url, ops)
  }
  postMicro<ReturnType>(url: string, payload: any, ops: ExOps) {
    return this.post(url, payload, ops)
  }
  deleteMicro<ReturnType>(url: string, ops: ExOps) {
    return this.delete(url, ops)
  }
  putMicro<ReturnType>(url: string, payload: any, ops: ExOps) {
    return this.put(url, payload, ops)
  }

  delete<ReturnType>(url: string, ops: ExOps) {
    return execPortalNetRequest({
      url,
      method: 'delete',
      jwt: this._jwt,
      ops,
    })
  }
}

export async function execPortalNetRequest<ReturnType>({
  url,
  method,
  jsonBody,
  jwt,
  ops,
}: //   thunkApi,
{
  url: string
  method: 'post' | 'get' | 'put' | 'delete'
  jsonBody?: any
  jwt?: string
  ops?: ExOps
  //   thunkApi: any
}): Promise<any> {
  // first process auth headers
  let headers: any = jwt
    ? {
        Authorization: 'Bearer ' + jwt,
      }
    : undefined
  // check if there are any additional custom headers, i.e. microservice api version
  if (ops && ops.customHeaders) {
    headers = { ...headers, ...ops.customHeaders }
  }

  if (ops?.langCode) {
    headers = { ...headers, 'Accept-Language': ops.langCode }
  }

  let response = undefined
  try {
    response = await fetch(url, {
      method,
      body: JSON.stringify(jsonBody),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ScriptaAppLocation: IS_NATIVE()
          ? ScriptaAppLocation_Header_Value_Mobile
          : ScriptaAppLocation_Header_Value_Portal,
        ...headers,
      },
    })

    //special hanlding introduced for adding bookmarks which return 201 and content=json but no body
    //at least this way we can let the ui check if response.ok and show toast msg
    if (ops && ops.returnRawFetchResponse) {
      return response
    }

    if (response.ok) {
      //if its not json but its ok, just return that
      const contentType = response.headers.get('content-type')

      if (contentType && contentType.indexOf('application/json') !== -1) {
        try {
          return await response.json()
        } catch (ex) {
          try {
            const hasContent =
              response.headers.get('content-length') &&
              parseInt(response.headers.get('content-length')!) > 0
            if (hasContent) {
              console.error(
                'unable to extract json from the response for url=',
                url,
                'error=',
                ex,
              )
            }
          } catch (ex2) {
            console.error(
              'Error trying to get json out of the response for url=',
              url,
              'error=',
              ex2,
            )
          }
        }
      } else {
        //not a json response
        return response
      }
    } else if (response.status === 401) {
      //nothing to do here - it should get a login page automatically
      console.error('401 - unauthorized response')
    } else {
      //THIS is bc existing EBS code will return a custom 400 + 352 if jwt is expired...
      if (response.status === 400) {
        const contentType = response.headers.get('content-type')
        if (contentType && contentType.indexOf('application/json') !== -1) {
          const resolved = await response.json()
          if (resolved.code === 352) {
            history.push(REG_ROUTE_JWT_EXP_LOGOUT)
          }
          return resolved
        } else return response
      }

      //it is not a custom expired code, so extract error messages if any
      const portalErrorResponse = await getPortalErrorResponse(response)
      // devLog('Got portal error response=', portalErrorResponse, 'warn')
      return portalErrorResponse
    }
  } catch (genericError) {
    //this would be things like failed to fetch and get a reponse from server
    //this like no network
    //504 BAD Gateway etc
    const detailedError: any = {
      message:
        'A network-level error occurred while executing the request - request timed out / failed to reach the APIserver.',
      originalError: genericError,
    }
    if ((genericError as any).response) {
      detailedError.errorStatus = (genericError as any).response.status
      detailedError.errorStatusText = (genericError as any).response.statusText
    }

    //check if anything is on response itself
    if (response) {
      detailedError.responseStatus = response.status
      detailedError.responseStatusText = response.statusText
    } else {
      //bc so far we were swallowing this
      //this is unfortunatelly to support both EKS type of errors and legacy EBS type of error responses
      ;(detailedError as any).responseStatus = 500
      ;(detailedError as any).status = 400
    }

    return detailedError
  }
}

//TODO - this needs careful review
export async function getPortalErrorResponse(
  response: any,
): Promise<PortalErrorDetails> {
  //console.warn('in spring boot error message for response=', response)
  let errorJson: PortalErrorDetails = {}
  try {
    errorJson = await response.json()
  } catch (ex) {
    console.error('Error - unable to extract spring boot error response')
    errorJson = {
      status: response.status,
      error:
        'Unable to even extract proper spring error response. Please check what the backend api is returning.',
    }
  }

  return errorJson
}

export interface PortalErrorDetails {
  error?: string
  message?: string
  path?: string
  status?: number
  timestamp?: string
  trace?: string
}

export function getModulesApiEndpoint(module: ModuleConfig, apiUrl: ApiUrl) {
  const apiEndPoint: string = apiUrl.endpoint
  const rootApiUrl: string = module.rootApiUrl
  const finalEndpoint =
    (rootApiUrl.startsWith('/') ? module.rootApiUrl : '/' + module.rootApiUrl) +
    (apiEndPoint.startsWith('/') ? apiEndPoint : '/' + apiEndPoint)
  const rootUrl = getPortalApiRootUrl()
  //change this away from replaceAll as it doesnt seem not supported in safari
  //https://stackoverflow.com/questions/62825358/javascript-replaceall-is-not-a-function-type-error
  // const url: string = rootUrl + finalEndpoint.replace('///g', '/')
  const cleanedUrl = finalEndpoint.replace('//', '/')
  const url: string =
    rootUrl + (cleanedUrl.endsWith('/') ? cleanedUrl : cleanedUrl + '/')

  if (url.endsWith('//')) {
    const pos = url.indexOf('//')
  }
  return url
}
export function getMicroserviceApiDetails(apiUrl: ApiUrl): {
  url: string
  ops: ExOps
} {
  //verion is required on every microservice api
  if (!apiUrl.apiVersion) {
    throw new Error('Missing version in the UiApiUrl definition in the ui')
  }
  if (apiUrl.apiType !== 'member_micro') {
    throw new Error(
      'getMicroserviceApiDetails should only be used for microservices api definitions',
    )
  }
  const rootUrl = resolveMicroServerRoot() + apiUrl.endpoint
  const exOps: ExOps = {
    customHeaders: { 'api-version': apiUrl.apiVersion },
  }

  return { url: rootUrl, ops: exOps }
}
