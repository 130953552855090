import { Box, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import SButton from '../system/customcomponents/SButton'
import EmailLink from '../widgets/EmailLink'
import PhoneLink from '../widgets/PhoneLink'
import { useRegistration } from './RegistrationContext'
import {
  REG_ROUTE_CHALLENGE_QUESTIONS,
  REG_ROUTE_CREATE_PROFILE,
  REG_ROUTE_FIND_TENANT,
  REG_ROUTE_IDENTITY_VALIDATION,
  REG_ROUTE_LOGIN,
  REG_ROUTE_REGISTER_TENANT_ACCOUNT,
} from './RegistrationSubRoutes'
import { RegistrationToolbar } from './RegistrationToolbar'
import { UI_RESPONSE_CODE_DECORATION_INVALID_OTP_RESET_PWD } from './ResetPassword'

export const RESPONSE_CODE_COMPANY_NOT_FOUND = 211
export const RESPONSE_CODE_NOT_ENOUGH_INFO = 220
export const REPONSE_CODE_NO_MATCHING_MEMBER = 225
export const REPONSE_CODE_NO_VERIFICATION_INFORMATION = 243
export const RESPONSE_CODE_INVALID_PASSCODE = 230
export const RESPONSE_CODE_PASSCODE_EXPIRED = 231
export const RESPONSE_CODE_NO_VERIFICATION_CHALLENGE_AVAILABLE = 244

export const RESPONSE_CODE_EMAIL_ALREADY_USED = 247
export const RESPONSE_CODE_PHONE_ALREADY_USED = 248

export const RESPONSE_CODE_NO_PLAN_ID = 513

export const RESPONSE_CODE_CHALLENGE_FAILED = 235
export const RESPONSE_CODE_USERNAME_ALREADY_USED = 303
export const RESPONSE_CODE_PASSWORD_REQ = 310
export const RESPONSE_CODE_NO_SUCH_USER = 332
export const RESPONSE_CODE_MINOR_PROHIBITED = 333
export const RESPONSE_CODE_USERNAME_ALREADY_TAKEN = 303
export const RESPONSE_CODE_PASSWORDS_DONT_MATCH = 320
export const RESPONSE_CODE_V1_PASSWORD_DONT_MATCH = 323
export const RESPONSE_CODE_ACCOUNT_ATTEMPTS_EXCEEDED = 330
export const RESPONSE_CODE_ACCOUNT_LOCKED = 331
export const RESPONSE_CODE_FAILED_CHALLENGE_LIMIT_EXCEEDED = 265
//lets try with default go-back behavior instead
export const RESPONSE_CODE_ALREADY_REGISTERED = 1011
export const RESPONSE_CODE_V1_ALREADY_REGISTERED = 245
export const RESPONSE_CODE_SSO_USER_ALREADY_REGISTERED = 1012

//this is prereg in process where the member is not even known yet
export const RESPONSE_CODE_PREREGISTRATION_IN_PROCESS = 1100
//this is prereg in process where the member has been identified
//the pre - reg flag is still on i.e.user is not yet officiall fully converted
export const RESPONSE_CODE_PREREGISTRATION_IN_PROCESS_KNOWN_MEMBER = 1101

//backend code for SECURITY VALIDATION - happens when pre-reg flag has been removed by bizops
//it indicates that the user needs to go through the security validation process
//the login response with this code must carry additional data in the response
//needed for the security validation process (i.e. member/tenant id etc)
export const RESPONSE_CODE_SECURITY_VALIDATION_NEEDED_NEW_CODE = 334

//for forgot username, try again should send
// export const RESPONSE_CODE_FORGOTTEN_USERNAME_NO_MATCHING_MEMBER = 225

export const UNKNOWN_RESPONSE_CODE = -1

export function RegistrationErrorHandler() {
  const { history, apiResponse } = useRegistration()
  const { t } = useSTranslate(['login', 'common', 'register', 'register'])

  const respCode: number = apiResponse
    ? apiResponse.code
    : UNKNOWN_RESPONSE_CODE

  let title = ''
  let description = apiResponse
    ? apiResponse.description
    : t('register:errorProcessingRequest')

  switch (respCode) {
    case RESPONSE_CODE_NO_PLAN_ID: {
      return (
        <Grid container sx={{ padding: '30px' }} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ textAlign: 'center' }}>
              {t('register:noPlanIdTitle')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 600 }}>
              {t('register:noPlanIdSubject1')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{t('register:noPlanIdDesc1')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 600 }}>
              {t('register:noPlanIdSubject2')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{t('register:noPlanIdDesc2')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ paddingLeft: '20px' }}>
              &#8226; {t('register:noPlanBullet1')}
            </Typography>
            <Typography sx={{ paddingLeft: '20px' }}>
              &#8226; {t('register:noPlanBullet2')}
              <a
                style={{ paddingLeft: '5px' }}
                href="mailto:help@scriptainsights.com "
              >
                help@scriptainsights.com{' '}
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{t('register:noPlanDesc3')}</Typography>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <Grid item sx={{ margin: 'auto' }}>
              <SButton
                onClick={() => {
                  history.goBack()
                }}
                sx={{ width: '100px' }}
              >
                OK
              </SButton>
            </Grid>
          </Grid>
        </Grid>
      )
      // title = t('register:noPlanIdTitle')

      break
    }

    case RESPONSE_CODE_SSO_USER_ALREADY_REGISTERED: {
      return <SSOAlreadyRegisteredError />
    }
    case RESPONSE_CODE_V1_ALREADY_REGISTERED: {
      title = t('register:alreadyRegistered')
      description = (
        <div>
          {t('register:loginUsingExistingUsernameAndPassword')}
          <br />
          <br />
          {t('register:forgotPassword')}
        </div>
      )
      break
    }
    case RESPONSE_CODE_ALREADY_REGISTERED: {
      title = t('register:alreadyRegistered')
      description = (
        <div>
          {t('register:loginUsingExistingUsernameAndPassword')}
          <br />
          <br />
          {t('register:forgotPassword')}
        </div>
      )
      break
    }
    case RESPONSE_CODE_ACCOUNT_ATTEMPTS_EXCEEDED: {
      title = t('register:accountLocked')
      break
    }
    case RESPONSE_CODE_ACCOUNT_LOCKED: {
      title = t('register:accountLocked')
      break
    }
    case RESPONSE_CODE_NO_SUCH_USER: {
      // title = 'No Such user'
      title = t('noUser')
      description = t('noUserParagraph')
      break
    }
    case RESPONSE_CODE_NOT_ENOUGH_INFO: {
      title = t('register:needMoreInformation')
      break
    }
    case RESPONSE_CODE_USERNAME_ALREADY_TAKEN: {
      title = t('register:usernameAlreadyTaken')
      break
    }
    case RESPONSE_CODE_COMPANY_NOT_FOUND: {
      title = t('register:unableToRegister')
      description = (
        <div>
          {t('register:companyOrEmailNotMatch')}
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>
            {t('register:healthPlanRecentlyAddedToScripta')}
          </span>
          {t('register:notAbleToRegisterUntilFirstReportReady')}
          <br />
          <br />
          {t('register:tryAgainForMisEnteredInfo')}
          <br />
          <br />
          {t('register:helpContactForRegistration')}
        </div>
      )
      break
    }
    //dont show title because it happens also during forgot password
    // case REPONSE_CODE_NO_MATCHING_MEMBER: {
    //   title = 'Member Not Found'
    //   break
    // }
    case REPONSE_CODE_NO_VERIFICATION_INFORMATION: {
      title = t('register:noVerificationInfoFound')
      break
    }
    case RESPONSE_CODE_NO_VERIFICATION_CHALLENGE_AVAILABLE: {
      title = t('register:noVerificationInfoAvail')
      break
    }
    case RESPONSE_CODE_CHALLENGE_FAILED: {
      title = t('register:challangeFailed')
      break
    }
    case RESPONSE_CODE_INVALID_PASSCODE: {
      title = t('register:invalidPassCode')
      break
    }
    case RESPONSE_CODE_PASSCODE_EXPIRED: {
      title = t('register:expiredPassCode')
      break
    }
    case RESPONSE_CODE_USERNAME_ALREADY_USED: {
      title = t('register:usernameNotAvail')
      break
    }
    case RESPONSE_CODE_PASSWORD_REQ: {
      title = t('register:passwordReqNotMatched')
      break
    }
    case RESPONSE_CODE_PASSWORDS_DONT_MATCH: {
      title = t('register:incorrectPassword')
      description = <div>{t('register:tryAgainOrForgotPassowrd')}</div>
      break
    }
    case RESPONSE_CODE_V1_PASSWORD_DONT_MATCH: {
      title = t('register:incorrectPassword')
      description = <div>{t('register:tryAgainOrForgotPassowrd')}</div>
      break
    }
    case RESPONSE_CODE_MINOR_PROHIBITED: {
      title = t('register:accountDeactivated')
      description = (
        <div>
          {t('register:healthPlanNoLongerSupportScripta')}
          <br />
          <br />
          {t('register:minorProhibitedMsg')}
        </div>
      )
      break
    }
    case UNKNOWN_RESPONSE_CODE: {
      title = t('register:registrationError')
      description = (
        <>
          <div>{t('register:somethingWentWrong')}</div>
          <br />
          <br />
          {t('register:helpContactForRegistration')}
        </>
      )

      break
    }
    // return state;
    default: {
      title = ''
      break
    }
  }

  console.log('RegistrationErrorHandler', apiResponse)

  return (
    <Box
      sx={(theme) => ({
        // backgroundColor: 'green',
        minWidth: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingTop: '30px',
      })}
    >
      <Box
        sx={(theme) => ({
          alignSelf: 'center',
          [theme.breakpoints.down('sm')]: {
            width: '90%',
            maxWidth: '90%',
            // paddingLeft: '20px',
            // paddingRight: '20px',
          },
          [theme.breakpoints.up('sm')]: {
            width: '334px',
            maxWidth: '334px',
          },
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ textAlign: 'center' }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography>{description}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: '30px' }}>
            <RegistrationToolbar
              nextDisabled={
                respCode === RESPONSE_CODE_FAILED_CHALLENGE_LIMIT_EXCEEDED
              }
              backHidden={true}
              customNextLabel={t('common:back')}
              onClickNext={() => {
                switch (respCode) {
                  case RESPONSE_CODE_COMPANY_NOT_FOUND: {
                    history.push(REG_ROUTE_FIND_TENANT)
                    break
                  }

                  //no need actually both are go back - the second step is the one that needs special handling
                  // case REPONSE_CODE_NO_MATCHING_MEMBER: {
                  //   //can happen and will be decorated by ui during forgot password
                  //   if (
                  //     apiResponse &&
                  //     apiResponse.uiResponseDecoration &&
                  //     apiResponse.uiResponseDecoration ===
                  //       UI_RESPONSE_CODE_DECORATION_NO_MATCHING_MEMBER_FORGOT_PWD_FLOW
                  //   ) {
                  //     devLog(
                  //       'special case of no match during forgot pwd usage during forgot password',
                  //       undefined,
                  //       'log',
                  //     )
                  //     //actually both can just go back by default. its the second one that
                  //     history.goBack()
                  //   } else {
                  //     //otherwise in registration we
                  //     history.goBack();
                  //   }
                  //   history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)
                  //   break
                  // }
                  case RESPONSE_CODE_NOT_ENOUGH_INFO: {
                    history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)
                    break
                  }
                  case RESPONSE_CODE_INVALID_PASSCODE: {
                    console.log('invalid passcode', apiResponse)
                    //invalid passcode code can be received during registration
                    if (
                      apiResponse &&
                      apiResponse.uiResponseDecoration &&
                      apiResponse.uiResponseDecoration ===
                        UI_RESPONSE_CODE_DECORATION_INVALID_OTP_RESET_PWD
                    ) {
                      history.goBack()
                      // history.push(REG_ROUTE_FORGOT_PASSWORD)
                    } else {
                      //its normal registration flow
                      // for now
                      // don't try again by sending the user to retype the passcode
                      // becuase there is no "exit" out of that flow
                      // better send them one step back so they can request it again
                      // otherwise - there is no option to 'request' a new security code
                      history.push(REG_ROUTE_IDENTITY_VALIDATION)
                    }

                    break
                  }
                  case RESPONSE_CODE_PASSCODE_EXPIRED: {
                    console.log('invalid passcode', apiResponse)
                    //invalid passcode code can be received during registration
                    if (
                      apiResponse &&
                      apiResponse.uiResponseDecoration &&
                      apiResponse.uiResponseDecoration ===
                        UI_RESPONSE_CODE_DECORATION_INVALID_OTP_RESET_PWD
                    ) {
                      history.goBack()
                      // history.push(REG_ROUTE_FORGOT_PASSWORD)
                    } else {
                      //its normal registration flow
                      // for now
                      // don't try again by sending the user to retype the passcode
                      // becuase there is no "exit" out of that flow
                      // better send them one step back so they can request it again
                      // otherwise - there is no option to 'request' a new security code
                      history.push(REG_ROUTE_IDENTITY_VALIDATION)
                    }
                    break
                  }

                  case RESPONSE_CODE_CHALLENGE_FAILED: {
                    history.push(REG_ROUTE_CHALLENGE_QUESTIONS)
                    break
                  }
                  case RESPONSE_CODE_NO_SUCH_USER: {
                    history.push(REG_ROUTE_LOGIN)
                    break
                  }
                  case RESPONSE_CODE_PASSWORD_REQ: {
                    history.push(REG_ROUTE_CREATE_PROFILE)
                    break
                  }
                  case RESPONSE_CODE_USERNAME_ALREADY_TAKEN: {
                    history.push(REG_ROUTE_CREATE_PROFILE)
                    break
                  }
                  case RESPONSE_CODE_PASSWORDS_DONT_MATCH: {
                    history.push(REG_ROUTE_LOGIN)
                    break
                  }
                  case RESPONSE_CODE_ACCOUNT_LOCKED: {
                    history.push(REG_ROUTE_LOGIN)
                    break
                  }
                  case RESPONSE_CODE_ACCOUNT_ATTEMPTS_EXCEEDED: {
                    history.push(REG_ROUTE_LOGIN)
                    break
                  }
                  //no need to do this - just go back by default
                  //TODO - can probably simply bunch of this stuff
                  // case RESPONSE_CODE_ALREADY_REGISTERED: {
                  //   //just try to go back?
                  //   history.goBack()
                  //   break
                  // }
                  //this is specific ui error status code
                  case UNKNOWN_RESPONSE_CODE: {
                    // history.push(REG_ROUTE_LOGIN)
                    history.goBack()
                    break
                  }
                  default: {
                    // history.push(REG_ROUTE_SERVER_ERROR)
                    history.goBack()
                  }
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export function SSOAlreadyRegisteredError() {
  const { t } = useSTranslate(['login', 'common', 'register', 'register'])
  return (
    <Grid container sx={{ padding: '30px' }} spacing={2}>
      <Grid item xs={12}>
        <Typography
          sx={{ fontWeight: 700, fontSize: '24px', textAlign: 'center' }}
        >
          {t('register:alreadyRegisteredForSso')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ textAlign: 'center' }}>
          {t('register:alreadyRegisteredForSso1')}
        </Typography>
      </Grid>
      <Grid item container xs={12}>
        <Typography
          component="div"
          sx={{ fontSize: '13px', textAlign: 'center' }}
        >
          {t('register:alreadyRegisteredForSso2')}

          <Typography
            component="span"
            style={{ whiteSpace: 'nowrap' }}
            sx={{ fontSize: '13px' }}
          >
            <EmailLink shouldShowUnderline={true} />
          </Typography>
          <Typography component="span" sx={{ fontSize: '13px' }}>
            {t('register:alreadyRegistredForSsoOrCall')}
          </Typography>
          <Typography
            component="span"
            style={{ whiteSpace: 'nowrap' }}
            sx={{ fontSize: '13px' }}
          >
            <PhoneLink shouldShowUnderline={true} />
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  )
}
