import { useEffect, useState } from 'react'
import { JwtDetails, parseJwt, useMemberAppContext } from '../MemberAppContext'
import { getUrlParameter } from '../apiexec/utils'
import { CompleteLoginHandler } from '../registration/CompleteLoginHandler'

import { CircularProgress, Grid, Typography } from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { SsoInitResponse } from './SsoCompleteFirstTimeAccessForm'

import { Redirect } from 'react-router-dom'
import { REG_ROUTE_REGISTER_SSO_COMPLETEPROFILE } from '../registration/RegistrationSubRoutes'
import { LOG } from 'src/app/v2/applog'
import { useAmazon } from 'src/app/hooks/useAmazon'

export const SSO_URL_PARAM_INIT_TOKEN = 'initToken'
export const SSO_URL_PARAM_SHORTCODE = 'client-short-code'
export const SSO_URL_PARAM_ERROR_CODE = 'error-code'
export function SamlLoginHandler() {
  const { t } = useSTranslate('sso')
  //wait untill we update the context with jwt before continuing to load profile etc
  const [jwtProcessed, setJwtProcessed] = useState(false)
  const tokenParam = getUrlParameter(SSO_URL_PARAM_INIT_TOKEN)

  const { setIsAmazon } = useAmazon()

  const [samlHandlerError, setSamlHandlerError] = useState<string | undefined>(
    undefined,
  )

  const { setJwt, setIsSsoLogin, setSsoInitResponse, ssoInitResponse } =
    useMemberAppContext()

  const [showLoading, setShowLoading] = useState(true)
  const [showError, setShowError] = useState(false)
  const [customErrorMessage, setCustomErrorMessage] = useState<
    string | undefined
  >()

  const [additionalErrorDetails, setAdditionalErrorDetails] = useState<string>()

  async function initSsoFromJwt(jwt: string) {
    const parsed: JwtDetails = parseJwt(jwt)
    //init log immediatelly
    if (parsed) {
      LOG.initMember(parsed.id_key as any, parsed.tenant_key as any)
    }

    const ssoResponseFromJwtDetails: SsoInitResponse = {
      //this is the most important new flag - set by backend during exchange
      //tels us if we need to prompt the user to register first
      askToRegister: !parsed.registered_key ? true : false,
      //we carry the jwt as part of this object, so we can use it needed api calls (i.e. complete_registration)
      //we dont want to set it on context here bc rest of the app will think we are logged in
      jwt: jwt,
    }
    setSsoInitResponse(ssoResponseFromJwtDetails)

    setShowError(false)
    setShowLoading(false)
    if (parsed && !parsed.registered_key) {
      setJwtProcessed(true)
      setShowError(false)
    } else {
      //in this case, no need to register, so we can set the jwt on context like the user has fully logged in
      setJwt(jwt)
      setJwtProcessed(true)
      setShowError(false)
    }
  }

  useEffect(() => {
    if (!tokenParam) {
      setSamlHandlerError(t('errorNoTokenFound'))
    } else {
      initSsoFromJwt(tokenParam)
      setIsSsoLogin(true)

      try {
        const jwtDetails: JwtDetails = parseJwt(tokenParam)
        if (
          jwtDetails &&
          jwtDetails.tenant_short_code_key &&
          jwtDetails.tenant_short_code_key.indexOf('amazon') > -1
        ) {
          setIsAmazon(true)
        }
      } catch (e) {
        console.error('Error parsing jwt', e)
      }
    }
  }, [])

  useEffect(() => {}, [ssoInitResponse])

  if (samlHandlerError) {
    return (
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          {samlHandlerError}
        </Typography>
      </Grid>
    )
  }

  //make sure jwt is set before continuing
  if (!jwtProcessed) {
    return (
      <Grid
        item
        xs={12}
        container
        justifyContent={'center'}
        sx={{ padding: '30px' }}
      >
        {showLoading && (
          <>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography align="center">{t('samlProcessStarting')}</Typography>
            </Grid>
            <Grid item sx={{ paddingTop: '30px' }}>
              <CircularProgress variant="indeterminate" size={30} />
            </Grid>
          </>
        )}
        {showError && (
          <>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography align="center">
                Error initializing SSO request.
              </Typography>
            </Grid>
            {customErrorMessage && (
              <Grid
                item
                xs={12}
                sx={{ textAlign: 'center', paddingTop: '30px' }}
              >
                <Typography align="center">{customErrorMessage}</Typography>
              </Grid>
            )}
            {additionalErrorDetails && (
              <Grid
                item
                xs={12}
                sx={{ textAlign: 'center', paddingTop: '30px' }}
              >
                <Typography align="center">{additionalErrorDetails}</Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    )
  }

  // samle login handler also needs registration context for redirecting to errors (bc we dont have normal login component)
  //if initialized to context, check if we need to complete registration
  //this means this sso user has already logged in before, so we continue to the normal login flow
  if (ssoInitResponse && ssoInitResponse.askToRegister === false) {
    return <CompleteLoginHandler />
  }

  if (ssoInitResponse && ssoInitResponse.askToRegister === true) {
    return <Redirect to={REG_ROUTE_REGISTER_SSO_COMPLETEPROFILE} />
  }

  //TODO - when does this happen
  return null

  // return <Redirect to={REG_ROUTE_REGISTER_SSO_FIRSTTIME} />
}

//TODO - loading screen for first ever
export function SsoInitializer() {
  const { ssoInitResponse } = useMemberAppContext()
  const { t } = useSTranslate('sso')

  const [showLoading, setShowLoading] = useState(true)

  if (showLoading) {
    return (
      <>
        <Grid item xs={12}>
          <Typography>{t('profileInitializing')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CircularProgress variant="indeterminate" size={30} />
        </Grid>
      </>
    )
  }

  //this means this sso user has already logged in before, so we continue to the normal login flow
  if (ssoInitResponse && ssoInitResponse.askToRegister === false) {
    return <CompleteLoginHandler />
  }

  return <Redirect to={REG_ROUTE_REGISTER_SSO_COMPLETEPROFILE} />
}
