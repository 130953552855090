import { StatusCode, TermsOfUse } from '../../types'
import { getModulesApiEndpoint } from '../apiexec/portalapiexec'
import { usePublicPortalApi } from '../apiexec/usePortalApi'
import {
  backendConfigModule,
  getPrivacyPolicy,
  getTermsOfUse,
  getdisableFeature,
} from './module-config'
import { PrivacyPolicy, DisabledFeaturesReqData } from '../../types'

//this one is public to get the strategy details
export function useConfigService() {
  const { portalPublicApi } = usePublicPortalApi()

  async function getPrivacyPolicyApi(): Promise<PrivacyPolicy | StatusCode> {
    const url = getModulesApiEndpoint(backendConfigModule, getPrivacyPolicy)
    return portalPublicApi.get<PrivacyPolicy | StatusCode>(url)
  }
  async function getTermsOfUseApi(): Promise<TermsOfUse | StatusCode> {
    const url = getModulesApiEndpoint(backendConfigModule, getTermsOfUse)
    return portalPublicApi.get<TermsOfUse | StatusCode>(url)
  }
  async function getDisabledFeatures(
    reqData: DisabledFeaturesReqData,
  ): Promise<any[] | StatusCode> {
    const url = getModulesApiEndpoint(
      backendConfigModule,
      getdisableFeature(reqData),
    )
    return portalPublicApi.get<string[] | StatusCode>(url)
  }

  return {
    getPrivacyPolicyApi,
    getTermsOfUseApi,
    getDisabledFeatures,
  }
}
