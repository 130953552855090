import { Link } from '@mui/material'
import { IS_NATIVE, nativeApi_openExternalUrl } from '../apiexec/utils'

export default function ExternalLink({
  url,
  children,
}: {
  url: string
  children: any
}) {
  return (
    <Link
      sx={{ cursor: 'pointer' }}
      underline={'none'}
      onClick={() => {
        handleExternalLinkClick(url)
      }}
    >
      {children}
    </Link>
  )
}

export function handleExternalLinkClick(url: string) {
  if (IS_NATIVE()) {
    nativeApi_openExternalUrl(url)
  } else {
    window.open(url, '_blank')
  }
}
