import React from 'react'
import {
  MemberSavingReport,
  RegistrationData,
  Tenant,
  Member,
  RegistrationChallenge,
  RegistrationSetupData,
  PasswordResetData,
} from '../../types'
import { useContext, useState } from 'react'
import history from 'src/app/scripta-browser-history'
import { FindTenantSearchType } from './FindTenantComponent'
import { MatchType } from './NewTenantAccount'
import { TenantIdWithOptions } from '../../types'
import { useHistory } from 'react-router-dom'
import { OtpMethod } from './IdentityValidation'
export type RegistrationContextType = {
  apiResponse: any
  setApiResponse: any
  // history: typeof history
  history: typeof history
  matchedTenant?: TenantIdWithOptions
  setMatchedTenant: any
  registrationData?: RegistrationData
  setRegistrationData: any
  member?: Member
  setMember: any
  notFoundText?: string
  setNotFoundText: any
  regChallenge?: RegistrationChallenge
  setRegChallenge: any
  regSetupData?: RegistrationSetupData
  setRegSetupData: (regData: RegistrationSetupData) => void
  prevValuesCache: PrevValuesCache
  setPrevValuesCache: (prevValues: PrevValuesCache) => void
  pwdResetData: PasswordResetData
  setPwdResetData: (pwdResetData: PasswordResetData) => void
  registrationJwt?: string
  setRegistrationJwt: (registrationJwt: string) => void
  progressBarState: ProgressBarState
  setProgressBarState: (progressBarState: ProgressBarState) => void
  //need to track what method user tried as its needed for retry in OPT verify screen
  identityValidationOtpMethodUsed?: OtpMethod
  setIdentityValidationOtpMethodUsed: (otpMethod: OtpMethod) => void

  isContainerHeightAnimationActive: boolean
  setIsContainerHeightAnimationActive: (isActive: boolean) => void
}
//trigger change

export const RegistrationContext = React.createContext<RegistrationContextType>(
  {
    apiResponse: {},
    setApiResponse: {},
    history: {} as any,
    matchedTenant: {} as any,
    setMatchedTenant: {} as any,
    registrationData: {} as any,
    setRegistrationData: {} as any,
    member: {} as any,
    setMember: {} as any,
    notFoundText: {} as any,
    setNotFoundText: {} as any,
    regChallenge: {} as any,
    setRegChallenge: {} as any,
    regSetupData: undefined,
    setRegSetupData: {} as any,
    prevValuesCache: {} as any,
    setPrevValuesCache: {} as any,
    pwdResetData: {} as any,
    setPwdResetData: {} as any,
    registrationJwt: {} as any,
    setRegistrationJwt: {} as any,
    progressBarState: {} as any,
    setProgressBarState: {} as any,
    identityValidationOtpMethodUsed: {} as any,
    setIdentityValidationOtpMethodUsed: {} as any,
    isContainerHeightAnimationActive: true,
    setIsContainerHeightAnimationActive: {} as any,
  },
)

export interface RegToPreregJumpState {
  //client uuid should always be available by the time we reach step 2
  clientUuid?: string
  firstName: string
  lastName: string
  dob: string
}

export type PrevValuesCache = {
  //findTenant
  findTenantSearchValue?: string
  findTenantEmailValue?: string
  findTenantSearchBy?: FindTenantSearchType

  //create tenant - for correctly prepopulating from existing date value needs separate year montyd ate values
  firstName?: string
  lastName?: string
  matchType?: MatchType
  matchValue?: any
  dateOfBirth?: string
  dob?: {
    year: number
    //bc new Date constructor takes in zero-indexed month number
    monthZeroIndexed: number
    day: number
  }

  contactEmail?: string
  contactConfirmEmail?: string
  contactPhone?: string
  concatSmsOptIn?: boolean
}

const REGISTRATION_TOTAL_STEPS = 6
const PREREGISTRATION_TOTAL_STEPS = 5

export interface ProgressBarState {
  //type is mostly used for number of total steps (at the moment)
  //TODO - when jumping from reg to prereg, is the number different?
  type: RegType
  disabled: boolean
  currentStep: number
}
export type RegType = 'registration' | 'preregistration'
export function getProgressBarPercentValue(state: ProgressBarState) {
  if (state.type === 'registration') {
    return (state.currentStep / REGISTRATION_TOTAL_STEPS) * 100
  } else if (state.type === 'preregistration') {
    return (state.currentStep / PREREGISTRATION_TOTAL_STEPS) * 100
  } else {
    console.error('invalid progress bar type')
    return 0
  }
}

export function RegistrationProvider({ children }: { children: any }) {
  const history = useHistory()
  const [apiResponse, setApiResponse] = useState<any>()
  const [matchedTenant, setMatchedTenant] = useState<TenantIdWithOptions>()
  const [registrationData, setRegistrationData] = useState<RegistrationData>()
  const [member, setMember] = useState<Member>()
  const [regChallenge, setRegChallenge] = useState<RegistrationChallenge>()
  const [notFoundText, setNotFoundText] = useState<string>()
  const [regSetupData, setRegSetupData] = useState<RegistrationSetupData>(
    {} as any,
  )
  const [progressBarState, setProgressBarState] = useState<ProgressBarState>({
    type: 'registration',
    disabled: true,
    currentStep: 1,
  })
  const [identityValidationOtpMethodUsed, setIdentityValidationOtpMethodUsed] =
    useState<OtpMethod>()

  const [pwdResetData, setPwdResetData] = useState<PasswordResetData>({
    emailOrPhone: '',
  })
  const [prevValuesCache, setPrevValuesCache] = useState<PrevValuesCache>({})

  const [registrationJwt, setRegistrationJwt] = useState<string | undefined>()

  const [
    isContainerHeightAnimationActive,
    setIsContainerHeightAnimationActive,
  ] = useState<boolean>(true)

  return (
    <RegistrationContext.Provider
      value={{
        apiResponse,
        setApiResponse,
        history,
        matchedTenant,
        setMatchedTenant,
        registrationData,
        setRegistrationData,
        member,
        setMember,
        notFoundText,
        setNotFoundText,
        regChallenge,
        setRegChallenge,
        regSetupData,
        setRegSetupData,
        prevValuesCache,
        setPrevValuesCache,
        pwdResetData,
        setPwdResetData,
        registrationJwt,
        setRegistrationJwt,
        progressBarState,
        setProgressBarState,
        identityValidationOtpMethodUsed,
        setIdentityValidationOtpMethodUsed,
        isContainerHeightAnimationActive,
        setIsContainerHeightAnimationActive,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  )
}

export function useRegistration() {
  return useContext(RegistrationContext)
}
