import {
  createTheme,
  Theme,
  ThemeOptions,
  useTheme,
} from '@mui/material/styles'
import { CustomColors } from './theme-extender'

export const FOOTER_MAX_WIDTH = 1350
export const FOOTER_HEIGHT = '40px'
export const APPBAR_HEIGHT = '65px'

//params needed for member portal theme
//mui colors
//customized colors
//illustration replacement map (additional - if there are colors in svgs that are not in the above two)
export interface MuiThemeParams {
  colors: {
    PRIMARY_BRAND: string
    SECONDARY_BRAND: string
    SECONDARY_BRAND_ACTIVE: string
    TEXT: string
    ERROR: string
    INFO: string
  }
  fontNames: string[]
}
export interface LogoThemeParams {
  whiteOnDarkLogoUrl: string
  darkOnWhiteLogoUrl: string
}
export const COMMON_BOLD_WEIGHT: number = 600

export type SupportedThemeNames = 'scripta' | 'amazon-blue' | 'amazon-orange'

export function createMemberPortalTheme(
  //force well known theme names
  name: SupportedThemeNames,
  muiParams: MuiThemeParams,
  customColors: CustomColors,
  logoParams: LogoThemeParams,
  additionlSvgColorReplaceMap?: Record<string, string>,
) {
  const baseTheme: ThemeOptions = {
    palette: {
      primary: {
        main: muiParams.colors.PRIMARY_BRAND,
      },
      secondary: {
        main: muiParams.colors.SECONDARY_BRAND,
        // contrastText: BLACK_COLOR,
      },
      info: {
        main: muiParams.colors.INFO,
      },
      text: {
        primary: muiParams.colors.TEXT,
      },
      error: {
        main: muiParams.colors.ERROR,
      },
      //this is where we actually choose light or dark mode
    },
    typography: {
      fontFamily: muiParams.fontNames.join(','),
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              boxShadow: 'none',
            },
            boxShadow: 'none',
            fontWeight: 'bold',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: muiParams.colors.SECONDARY_BRAND,
            '&:hover': {
              color: muiParams.colors.SECONDARY_BRAND_ACTIVE,
            },
          },
        },
      },
      // MuiTextField: {
      //   styleOverrides: {
      //     root: {
      //       'input:placeholder-shown': {
      //         fontStyle: 'italic',
      //       },
      //     },
      //   },
      // },
      // MuiInputBase: {
      //   styleOverrides: {
      //     root: {
      //       'input:placeholder-shown': {
      //         fontStyle: 'italic',
      //         color: '#96939B',
      //         fontWeight: '400',
      //       },
      //     },
      //   },
      // },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1165,
        lg: 1200,
        xl: 1500,
      },
    },
    anotherChange: 'anotherChange',
    customized: {
      name,
      ...logoParams,
      colors: { ...customColors },
    },
  }

  return createTheme(baseTheme)
}

export function useSColors() {
  const theme = useTheme()
  const COLORS = theme.customized?.colors
  return { COLORS }
}
//need to export this so they are also accessed by MUI theme
export const SHARED_ERROR_RED = '#E22C2C'
export const SHARED_BLACK = '#201F22'
//these are shared between scripta and amazon (and additional third if any themes)
export function getSharedColorsBetwenAmazonAndScriptaOrMaybeOtherThemes() {
  return {
    ERROR_RED: SHARED_ERROR_RED,
    BLACK: SHARED_BLACK,
    TEXT_BLACK: SHARED_BLACK,
    BACKGROUND_LIGHT: '#FFFFFF',
    BACKGROUND_BLACK: SHARED_BLACK,
    ERROR_RED_10: '#FCEAEA',
    SUCCESS_GREEN: '#008800',
    SUCCESS_GREEN_10: '#E5F3E5',

    TEXT_GREEN: '#008800',
    TEXT_LIGHT_PRIMARY_WHITE: '#FFFFFF',
    TEXT_GREY_MEDIUM: '#66636C',
    TEXT_GREY_LIGHT: '#96949A',
    TEXT_GREY_LIGHTER: '#C3C1C6',
    TEXT_GREY_DARK: '#4D4B51',

    BACKGROUND_GREY_MEDIUM: '#D0CFD3',
    BACKGROUND_GREY_LIGHT: '#E5E4E7',
    BACKGROUND_GREY_LIGHTER: '#F7F7F8',
    BACKGROUND_GREY_DARKER: '#2C2B2E',
    BACKGROUND_LIGHT_TAG_GREEN: '#E5F3E5',
    BACKGROUND_LIGHT_TAG_BLUE: '#EAF4FF',
    BORDER_GREY_LIGHT: '#D0CFD3',
  }
}
