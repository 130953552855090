import { Trans } from 'react-i18next'
import { useTranslation as useITNextTranslation } from 'react-i18next'

//new hookd to consolidate translation calls
export function useSTranslate(namespace: string | string[]) {
  const { t: originalT, ready } = useITNextTranslation(namespace, {
    useSuspense: false,
  })

  //add a wrapper to prevent runtime fetching of custom objects
  //with the latets i18next, the resources are fetched async on demand, so we need to wait until ready
  const wrapperT = (key: string) => {
    //check if ready, if so use the original T, otherwise return a key as string with capital first letter
    // Check if key is undefined
    if (key === undefined) {
      console.warn('Translation key is undefined')
      return ''
    }
    return ready ? originalT(key) : key.charAt(0).toUpperCase() + key.slice(1)
  }
  return { t: wrapperT, ready }
}

//new dedicated if you need to get objects
export function useSTranlateForObjects_waitUntilReady(
  namespace: string | string[],
) {
  const { t: originalT, ready } = useITNextTranslation(namespace, {
    useSuspense: false,
  })

  return { t: originalT, ready }
}

//todo - can we get rid of this component and just do it via chook?
export function STrans({
  i18nKey,
  namespace,
}: {
  i18nKey: string
  namespace: string | string[]
}) {
  const { t: originalT, ready } = useITNextTranslation(namespace, {
    useSuspense: false,
  })
  if (!ready) {
    return null
  }

  return <Trans i18nKey={i18nKey} t={originalT}></Trans>
}
