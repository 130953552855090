import { Divider, Grid, Link, useMediaQuery, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  isServerErrorResponse,
  isValidResponse,
  Member,
  OneTimePassCode,
  VerifyRegistrationIdentityResponse,
} from '../../types'
import { useNotifier } from '../hooks/useNotify'
import { toDigitsOnly } from '../member/phone-field-utils'
import { useMemberAppContext } from '../MemberAppContext'
import { useSColors } from '../styles/scripta-theme'
import { CustomTextField } from './components/CustomTextField'
import { RegistrationStepCard } from './components/RegistrationStepCard'
import { DualBottomButtonToolbar } from './DualBottomButtonActionToolbar'
import { OtpMethod, ValueOnFile } from './IdentityValidation'
import { useRegistration } from './RegistrationContext'
import {
  RESPONSE_CODE_INVALID_PASSCODE,
  RESPONSE_CODE_PASSCODE_EXPIRED,
} from './RegistrationErrorHandler'
import {
  REG_ROUTE_COMPLETE_PREREG_PROFILE,
  REG_ROUTE_EDIT_CONTACT_INFO,
  REG_ROUTE_ERROR_HANDLER,
  REG_ROUTE_SERVER_ERROR,
} from './RegistrationSubRoutes'
import { useRegistrationService } from './useRegistrationService'
import { LOG } from '../v2/applog'

export function ConfirmOtp() {
  const { COLORS } = useSColors()
  const {
    history,
    member,
    setApiResponse,
    setRegistrationJwt,
    identityValidationOtpMethodUsed,
  } = useRegistration()
  const service = useRegistrationService()
  const { t } = useSTranslate('register')

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  if (!member || !member.tenantId || !member.id)
    throw Error(t('cannotIdentifyWithoutMember'))

  const [otpString, setOtpString] = useState<string>('')
  const [fullOtpCode, setFullOtpCode] = useState<OneTimePassCode>({} as any)

  const [isCodeInvalid, setIsCodeInvalid] = useState<boolean>(false)

  const { isFirstTimePreregMemberAccess } = useMemberAppContext()

  //use notifier
  const { sendMsg } = useNotifier()

  useEffect(() => {
    setFullOtpCode({
      ...fullOtpCode,
      tenantId: member.tenantId,
      memberId: member.id,
      passcode: otpString,
    })
  }, [otpString, member])

  async function asyncRequestAnotherOtp(
    method: OtpMethod,
    memberId: number,
    tenantId: number,
  ) {
    try {
      const resp = await service.sendVerificationOtpApi(
        method,
        memberId,
        tenantId,
      )

      if (isServerErrorResponse<Member>(resp)) {
        LOG.error('registration', 'server error sending otp', resp)
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<Member>(resp)) {
        setApiResponse(resp)
        sendMsg('New code has been sent!', 'success')
      } else {
        setApiResponse(resp)
        sendMsg(
          'There was a problem sending your code. Please contact our member support.',
          'error',
        )
        LOG.error('registration', 'Error sending otp', resp)

        history.push(REG_ROUTE_ERROR_HANDLER)
      }
    } catch (e) {
      LOG.error('registration', 'Error sending otp', e)
      throw new Error('error in api execution')
    }
  }

  async function asyncVerifyOtp(otp: OneTimePassCode) {
    try {
      const resp = await service.verifyRegistrationOtpApi(otp)

      if (isServerErrorResponse<Member>(resp)) {
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<VerifyRegistrationIdentityResponse>(resp)) {
        const registrationJwt = resp.registrationJwt
        setRegistrationJwt(registrationJwt)
        setApiResponse(resp)
        if (isFirstTimePreregMemberAccess) {
          history.push(REG_ROUTE_COMPLETE_PREREG_PROFILE)
        } else {
          history.push(REG_ROUTE_EDIT_CONTACT_INFO)
        }
      } else {
        //check if it is very specific error coce indicating invalid code
        if (
          (resp && resp.code === RESPONSE_CODE_INVALID_PASSCODE) ||
          (resp && resp.code === RESPONSE_CODE_PASSCODE_EXPIRED)
        ) {
          LOG.error('registration', 'invalid or expird passcode resp', resp)
          setIsCodeInvalid(true)
        } else {
          //its a non-standard error, should not happen, so send to generic error handler
          LOG.error('registration', 'unexpected error in verify otp', resp)
          setApiResponse(resp)
          history.push(REG_ROUTE_ERROR_HANDLER)
        }
      }
    } catch (e) {
      LOG.error('registration', 'Error verifying otp', e)
      throw new Error(t('errorInApiExecution'))
    }
  }

  const doVerifyOtp = useCallback(() => {
    if (!otpString) throw Error(t('cannotSendAsyncOTP'))

    asyncVerifyOtp(fullOtpCode)
  }, [otpString, fullOtpCode])

  return (
    <RegistrationStepCard
      registrationType="registration"
      progressBarStep={4}
      //hide the standard toolbar, we are using custom one here with submit buttons
      disableToolbar={true}
      customToolbarComponent={
        <DualBottomButtonToolbar
          noText={t('register:btnBack')}
          yesText={t('register:btnSubmit')}
          onClickNo={() => {
            history.goBack()
          }}
          onClickYes={() => {
            doVerifyOtp()
          }}
        />
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            data-testid="confirm-otp-page-title"
            sx={{
              textAlign: 'center',
              fontSize: isSmall ? '1.125rem' : '1.5rem',
              fontWeight: 700,
              paddingBottom: '20px',
              paddingTop: '0px',
              // marginTop: isSmall ? '-15px' : '0px',
              lineHeight: '21.92px',
            }}
          >
            {t('enter4DigitCode')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '10px' }}>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '20px',
              textAlign: 'left',
              paddingLeft: isSmall ? '10px' : undefined,
              // paddingRight: isSmall ? '20px' : undefined,
            }}
          >
            {identityValidationOtpMethodUsed === 'phone'
              ? t('acodeWasSentToNumber')
              : t('acodeWasSentToEmail')}
          </Typography>
        </Grid>
        {identityValidationOtpMethodUsed && (
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: '20px',
              paddingLeft: isSmall ? '10px' : undefined,
            }}
          >
            <ValueOnFile
              otpMethod={identityValidationOtpMethodUsed}
              member={member}
            />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            paddingTop: '20px',
            paddingLeft: isSmall ? '10px' : undefined,
            paddingRight: isSmall ? '10px' : undefined,
          }}
        >
          <CustomTextField
            data-testid="verify-otp-input"
            autoComplete="off"
            id="otp"
            name="otp"
            // type="number"
            placeholder={t('enterYourCode')}
            label={t('verificationCode')}
            value={otpString}
            // inputProps={{ maxLength: 4 }}
            onChange={(e) => {
              const numOnly = toDigitsOnly(e.target.value)
              if (numOnly.length > 4) {
                setOtpString(numOnly.substring(0, 4))
              } else {
                setOtpString(e.target.value)
              }
            }}
            error={isCodeInvalid}
            helperText={isCodeInvalid ? t('invalidOtpCode') : ''}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '20px' }}>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '20px',
              textAlign: 'left',
              paddingLeft: isSmall ? '10px' : undefined,
              paddingRight: isSmall ? '10px' : undefined,
            }}
          >
            {t('codeExpires')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '30px',
            marginBottom: '20px',
            paddingRight: isSmall ? '20px' : undefined,
            paddingLeft: isSmall ? '20px' : undefined,
          }}
        >
          <Divider
            sx={{
              width: '100%',
              minWidth: '100%',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Link
            // component="button"
            variant="body2"
            sx={{
              paddingLeft: isSmall ? '20px' : undefined,
              cursor: 'pointer',
              fontSize: '1rem',
              fontWeight: 600,
              color: COLORS.TEXT_SECONDARY,
              textDecoration: 'none',
            }}
            onClick={async () => {
              if (identityValidationOtpMethodUsed)
                asyncRequestAnotherOtp(
                  identityValidationOtpMethodUsed,
                  member.id,
                  member.tenantId,
                )
              else {
                LOG.error(
                  'registration',
                  "can't send otp, missing member data or otp method",
                )
              }
            }}
          >
            {t('requestAnotherCode')}
          </Link>
        </Grid>

        {/* <Grid item xs={12} sx={{ minHeight: '10px' }}></Grid> */}
      </Grid>
    </RegistrationStepCard>
  )
}
