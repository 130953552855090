import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material'
import TextField from '@mui/material/TextField'
import { isValidResponse, PasswordResetData } from '../../types'
import { useCallback, useEffect } from 'react'
import { useNotifier } from '../hooks/useNotify'
import { useRegistration } from './RegistrationContext'
import {
  REG_ROUTE_RESET_PASSWORD,
  REG_ROUTE_ERROR_HANDLER,
} from './RegistrationSubRoutes'
import { RegistrationToolbar } from './RegistrationToolbar'
import { useRegistrationService } from './useRegistrationService'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { GAService } from '../application/ga/GAService'

//need a bit more details to properly handle same error response code indifferent flows
//i.e. registration vs forgot password
export const UI_RESPONSE_CODE_DECORATION_NO_MATCHING_MEMBER_FORGOT_PWD_FLOW =
  'nomatchingmemberforgotpwdflow'
export function ForgotPassword() {
  const gaService = new GAService()
  const regService = useRegistrationService()
  const { history, setApiResponse, pwdResetData, setPwdResetData } =
    useRegistration()
  const { sendMsg } = useNotifier()
  const { t } = useSTranslate('register')
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    document.title = 'Forgot Password - Scripta Insights'
  })

  async function asyncForgotPassword(resetPasswordData: PasswordResetData) {
    try {
      const resp = await regService.forgotPasswordApi(resetPasswordData)
      //

      if (isValidResponse<PasswordResetData>(resp)) {
        //
        sendMsg(t('forgotPasswordFlowCodeSent'), 'success')
        //updated password data for the next step
        setPwdResetData(resp)
        history.push(REG_ROUTE_RESET_PASSWORD)
      } else {
        //no need if error they can both go back
        //decorate no matching member so we know we are dealing with
        //forgot password code, not registration
        const decoratedResp = {
          ...(resp as any),
          uiResponseDecoration:
            UI_RESPONSE_CODE_DECORATION_NO_MATCHING_MEMBER_FORGOT_PWD_FLOW,
        }
        setApiResponse(decoratedResp)
        history.push(REG_ROUTE_ERROR_HANDLER)
      }
    } catch (e) {
      console.error('Error in forgot password ', e, 'error')
      //handle in generic page
      throw e
    }
  }

  const doAsyncForgotPassword = useCallback(() => {
    asyncForgotPassword(pwdResetData)
  }, [pwdResetData])

  return (
    <Grid
      item
      xs={12}
      container
      gap={isSmall ? '16px' : '32px'}
      style={{ padding: '30px' }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          {t('forgotPasswordTitle')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: 400,
            textAlign: 'center',
            lineHeight: 'normal',
          }}
        >
          {t('forgotPasswordDisclaimer')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          variant="outlined"
          id="email"
          fullWidth
          name="email"
          placeholder={t('enterEmailPhone')}
          label={t('enterEmailPhone')}
          value={pwdResetData.emailOrPhone}
          required
          onChange={(e) => {
            setPwdResetData({ emailOrPhone: e.target.value })
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <RegistrationToolbar
          onClickNext={() => {
            doAsyncForgotPassword()
            gaService.trackEvent(
              gaService.eventMap.forgot_password_submit_email,
            )
          }}
          nextDisabled={!pwdResetData.emailOrPhone}
          useBackLabelForCancel={false}
          sidePadding={isSmall ? undefined : '20%'}
        />
      </Grid>
    </Grid>
  )
}
