export const getMemberDobAsStringFromCache = (
  year: number,
  monthZeroIndexed: number,
  day: number,
): string => {
  
  const nonZeroMonth = monthZeroIndexed + 1
  
  const properMonth =
    nonZeroMonth.toString().length === 1 ? `0${nonZeroMonth}` : nonZeroMonth
  

  const properDay = day.toString().length === 1 ? `0${day}` : day

  return `${year}-${properMonth}-${properDay}`
}

/**
 * Formats the date into yyyy-mm-dd
 * @param date string
 * @returns {string} formatted date (i.e. 08/05/1990 -> 1990-08-05)
 */
export const dateWithYearMonthDay = (date: string) => {
  const arr = date.split('/')
  const year = arr[2]
  const month = arr[0]
  const day = arr[1]

  return `${year}-${month}-${day}`
}

export const formatPhoneNumber = (value: string) => {
  const input = value.replace(/\D/g, '').substring(0, 10)
  const areaCode = input.substring(0, 3)
  const middle = input.substring(3, 6)
  const last = input.substring(6, 10)

  if (input.length > 6) {
    return `(${areaCode}) ${middle} - ${last}`
  } else if (input.length > 3) {
    return `(${areaCode}) ${middle}`
  } else if (input.length > 0) {
    return `(${areaCode}`
  }
  return ''
}
