import React from 'react'
import {
  InputBaseProps,
  FormControl,
  InputLabel,
  InputBase,
  SxProps,
  Box,
  Typography,
  ClickAwayListener,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { TooltipProps } from '@mui/material/Tooltip'

import { styled } from '@mui/styles'
import { FormHelperText } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

export interface CustomTextFieldProps extends InputBaseProps {
  label?: string
  containerSx?: SxProps
  helperText?: string
  customLabelNode?: React.ReactNode
}

const StyledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2),
  },
  '&.MuiInputBase-adornedStart': {},
  '&.MuiInputBase-root': {
    borderRadius: '4px',
    border: '1px solid ',
    borderColor: '#D2D2D2',
    backgroundColor: '#F9F9F9',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    width: '100%',
    padding: '12px 8px',
    '& .MuiInputBase-input': {
      padding: 0,
    },
  },
  '&.Mui-focused': {
    borderColor: theme.palette.primary.main,
  },
  '&.Mui-error': {
    border: '2px solid',
    borderColor: theme.palette.error.main,
  },
  '&.MuiInputBase-root.Mui-disabled': {
    backgroundColor: 'rgba(0,0,0,.12)',
  },
}))
export const CustomTextField = ({
  label,
  value,
  onChange,
  containerSx,
  customLabelNode,
  ...props
}: CustomTextFieldProps) => {
  const { id } = props
  //styled input doesnt support this prop
  let propsWithoutHelperText = { ...props }
  delete propsWithoutHelperText.helperText

  return (
    <FormControl sx={containerSx} fullWidth variant={'standard'}>
      {!customLabelNode && (
        <InputLabel
          sx={(theme) => ({
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: '400',
          })}
          shrink
          htmlFor={id}
        >
          {label}
        </InputLabel>
      )}
      {customLabelNode && <>{customLabelNode}</>}
      <StyledInput
        value={value}
        onChange={onChange}
        {...propsWithoutHelperText}
      />
      {props.helperText && (
        <FormHelperText
          sx={(theme) => ({
            color: theme.palette.error.main,
          })}
        >
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export function CustomTextFieldLabelNode({
  label,
  instructions,
  toolTipText,
  toolTipPlacement,
}: {
  label: string
  instructions?: string
  toolTipText?: string
  toolTipPlacement?: TooltipProps['placement']
}) {
  const [showTooltip, setShowTooltip] = React.useState(false)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box display="flex" flexDirection={'column'}>
      <ClickAwayListener
        onClickAway={() => {
          setShowTooltip(false)
        }}
      >
        <Box display="flex" sx={{ alignItems: 'center' }}>
          <Typography
            sx={{ fontSize: '0.8rem', fontWeight: 400 }}
            //if we have tooltip text, on mobile trigger its show when clicking on the label
            onClick={() => {
              if (isSmall && toolTipText) {
                setShowTooltip(true)
              }
            }}
          >
            {label}
          </Typography>
          {toolTipText && (
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: '13px',
                    fontWeight: 600,
                    lineHeight: '16px',
                  },
                },
                popper: {
                  sx: {
                    width: '235px',
                    minWidth: '235px',
                    maxWidth: '235px',
                  },
                },
              }}
              title={toolTipText}
              placement={
                toolTipPlacement ? toolTipPlacement : isSmall ? 'right' : 'top'
              }
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              open={showTooltip}
              arrow
            >
              <InfoIcon
                sx={{
                  marginLeft: '5px',
                  marginRight: '5px',
                  marginTop: '-3px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setShowTooltip(!showTooltip)
                }}
                color="primary"
                className="myIcon"
                fontSize="small"
              />
            </Tooltip>
          )}
        </Box>
      </ClickAwayListener>
      {instructions && (
        <Typography
          sx={{
            fontSize: '0.8rem',
            fontStyle: 'italic',
            opacity: 0.5,
          }}
        >
          {instructions}
        </Typography>
      )}
    </Box>
  )
}
