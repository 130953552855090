/**
 * User Defined Type Guard
 */

export function isValidResponse<T>(arg: any): arg is T {
  // 
  return (
    arg !== null &&
    arg !== undefined &&
    arg.status !== 400 &&
    arg.status !== 404 &&
    //TODO - review this more - api responds with 400/404 and status codes to indicate invalid response
    //where we need to redirect to error handler to show it to the user
    //but it seems it also response with arg.code===1 for OK statuses with some vague description='ok'
    (arg.code === undefined || arg.code === 1)
  )
}
