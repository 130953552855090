import { Box, Grid, Link, Typography, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import React from 'react'
import CernerImage from '../../images/cerner_user_agreement_icon.svg'
import { NewPortalDialogDesign } from '../application/NewDialogs'
import {
  useSTranlateForObjects_waitUntilReady,
  useSTranslate,
} from 'src/app/hooks/useSTranslate'

export function CernerLinkWithDialog({
  cernerDialogType,
  customLinkSx,
  shortenedTitle = false,
}: {
  cernerDialogType: 'disclaimer' | 'userAgreement'
  customLinkSx?: any
  shortenedTitle?: boolean
}) {
  //clickable link that opens cerner terms dialog
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <Link
        onClick={(e: any) => {
          setOpen(true)
        }}
        variant="body2"
        sx={{
          // whiteSpace: 'nowrap',
          fontSize: '0.7rem',
          cursor: 'pointer',
          textDecorationColor: 'currentColor',
          ...customLinkSx,
        }}
      >
        {cernerDialogType === 'disclaimer'
          ? 'Disclaimer'
          : shortenedTitle
          ? "Cerner Multum's License"
          : 'Cerner Multum User License Agreement'}
      </Link>

      <CernerDialog
        cernerDialogType={cernerDialogType}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export function CernerDialog({
  cernerDialogType,
  open,
  onClose,
}: {
  cernerDialogType: 'disclaimer' | 'userAgreement'

  open: boolean
  onClose: () => void
}) {
  const handleClose = () => {
    onClose()
  }

  const { t } = useSTranslate('cerner')

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <NewPortalDialogDesign
      open={open}
      onClose={handleClose}
      title={
        cernerDialogType === 'disclaimer'
          ? t('titleDisclaimer')
          : t('titleUserAgreement')
      }
      maxWidth={isDesktop ? 'xl' : 'sm'}
      customPaperSx={{
        padding: 0,
        width: {
          xs: 350,
          sm: 600,
          md: 900,
          lg: cernerDialogType === 'userAgreement' ? 1240 : 950,
        },
      }}
      upperRightX={true}
      singleActionProps={{
        text: 'Close',
        onClick: handleClose,
        buttonSx: {
          width: cernerDialogType === 'userAgreement' ? 100 : 300,
        },
      }}
      headerImageImport={
        cernerDialogType === 'disclaimer' ? undefined : CernerImage
      }
    >
      {cernerDialogType === 'disclaimer' ? (
        <CernerDrugInfoDisclaimerContent />
      ) : (
        <CernerUserLicenseAgreementContent />
      )}
    </NewPortalDialogDesign>
  )
}

function CernerDrugInfoDisclaimerContent() {
  const { t, ready } = useSTranlateForObjects_waitUntilReady('cerner')
  if (!ready) return null

  const disclaimerParas: string[] = t('diclaimerParagraphs', {
    returnObjects: true,
  }) as string[]

  const SX = {
    fontSize: '1rem',
    textAlign: 'justify',
  }

  return (
    <Grid container spacing={2}>
      {disclaimerParas.map((para: string, index: number) => {
        return (
          <Grid item xs={12} key={index}>
            <Typography sx={SX} key={index}>
              {para}
            </Typography>
          </Grid>
        )
      })}
    </Grid>
  )
}
function CernerUserLicenseAgreementContent() {
  const SX = {
    fontSize: '1rem',
    textAlign: 'justify',
  }

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  let MARGIN_LEFT = '20px'
  if (isSmall) {
    MARGIN_LEFT = '10px'
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography sx={SX}>
          The Service is provided to you by Cerner Multum LLC (“Cerner”). The
          Service and the users of the Cerner Multum LLC product are separate
          products provided by separate entities. The Service is intended for
          use by consumers in the United States.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={SX}>
          Every effort has been made to ensure that the information provided in
          the Service is accurate, up-to-date, and complete, but no guarantee is
          made to that effect. In addition, the drug information contained
          herein may be time sensitive.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={SX}>
          The Service does not endorse drugs, diagnose patients, or recommend
          therapy. The Service is an informational resource designed to assist
          licensed healthcare practitioners in caring for their patients and
          provide consumers with drug specific information. Healthcare
          practitioners should use their professional judgment in using the
          information provided. The Service is not a substitute for the care
          provided by licensed healthcare practitioners and consumers are urged
          to consult with their healthcare practitioner in all instances. The
          absence of a warning for a given drug or drug combination in no way
          should be construed to indicate that the drug or drug combination is
          safe, effective, or appropriate for any given patient.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={SX}>
          Cerner does not assume any responsibility for any aspect of healthcare
          administered or not administered with the aid of information the
          Service provides.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '30px', marginLeft: MARGIN_LEFT }}>
            <Typography sx={{ ...SX, fontWeight: 500 }}>{`1.  `}</Typography>
          </Box>
          <Box sx={{ marginLeft: 'auto', paddingLeft: '7px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ ...SX }}>
                  <span style={{ fontWeight: 'bold' }}>
                    Disclaimer of Warranties.
                  </span>
                  THE END USER ACKNOWLEDGES THAT THE SERVICE IS PROVIDED ON AN
                  "AS IS" BASIS. EXCEPT FOR WARRANTIES WHICH MAY NOT BE
                  DISCLAIMED AS A MATTER OF LAW, CERNER MAKES NO REPRESENTATIONS
                  OR WARRANTIES WHATSOEVER, EXPRESS OR IMPLIED, INCLUDING BUT
                  NOT LIMITED TO REPRESENTATIONS OR WARRANTIES REGARDING THE
                  ACCURACY OR NATURE OF THE CONTENT OF THE SERVICE, WARRANTIES
                  OF TITLE, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A
                  PARTICULAR PURPOSE
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={SX}>
                  IN ADDITION, WITHOUT LIMITING THE FOREGOING, THE SERVICE HAS
                  BEEN DESIGNED FOR USE IN THE UNITED STATES ONLY AND COVERS THE
                  DRUG PRODUCTS USED IN PRACTICE IN THE UNITED STATES. CERNER
                  PROVIDES NO CLINICAL INFORMATION OR CHECKS FOR DRUGS NOT
                  AVAILABLE FOR SALE IN THE UNITED STATES AND CLINICAL PRACTICE
                  PATTERNS OUTSIDE THE UNITED STATES MAY DIFFER SUBSTANTIALLY
                  FROM INFORMATION SUPPLIED BY THE SERVICE. CERNER DOES NOT
                  WARRANT THAT USES OUTSIDE THE UNITED STATES ARE APPROPRIATE.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={SX}>
                  The End user acknowledges that updates to the Service are at
                  the sole discretion of Cerner. Cerner makes no representations
                  or warranties whatsoever, express or implied, with respect to
                  the compatibility of the Service, or future releases thereof,
                  with any computer hardware or software, nor does Cerner
                  represent or warrant the continuity of the features or the
                  facilities provided by or through the Service as between
                  various releases thereof.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={SX}>
                  Any warranties expressly provided herein do not apply if: (i)
                  the End user alters, mishandles or improperly uses, stores or
                  installs all, or any part, of the Service, (ii) the End user
                  uses, stores or installs the Service on a computer system
                  which fails to meet the specifications provided by Cerner, or
                  (iii) the breach of warranty arises out of or in connection
                  with acts or omissions of persons other than Cerner.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '30px', marginLeft: '20px' }}>
            <Typography sx={{ ...SX, fontWeight: 500 }}>{`2.  `}</Typography>
          </Box>
          <Box sx={{ marginLeft: 'auto', paddingLeft: '7px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ ...SX }}>
                  <span style={{ fontWeight: 'bold' }}>
                    Assumption of Risk, Disclaimer of Liability, Indemnity
                  </span>
                  THE END USER ASSUMES ALL RISK FOR SELECTION AND USE OF THE
                  SERVICE AND CONTENT PROVIDED THEREON. CERNER SHALL NOT BE
                  RESPONSIBLE FOR ANY ERRORS, MISSTATEMENTS, INACCURACIES OR
                  OMISSIONS REGARDING CONTENT DELIVERED THROUGH THE SERVICE OR
                  ANY DELAYS IN OR INTERRUPTIONS OF SUCH DELIVERY.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={SX}>
                  THE END USER ACKNOWLEDGES THAT CERNER: (A) HAS NO CONTROL OF
                  OR RESPONSIBILITY FOR THE END USER’S USE OF THE SERVICE OR
                  CONTENT PROVIDED THEREON, (B) HAS NO KNOWLEDGE OF THE SPECIFIC
                  OR UNIQUE CIRCUMSTANCES UNDER WHICH THE SERVICE OR CONTENT
                  PROVIDED THEREON MAY BE USED BY THE END USER, (C) UNDERTAKES
                  NO OBLIGATION TO SUPPLEMENT OR UPDATE CONTENT OF THE SERVICE,
                  AND (D) HAS NO LIABILITY TO ANY PERSON FOR ANY DATA OR
                  INFORMATION INPUT ON THE SERVICE BY PERSONS OTHER THAN CERNER.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={SX}>
                  CERNER SHALL NOT BE LIABLE TO ANY PERSON (INCLUDING BUT NOT
                  LIMITED TO THE END USER AND PERSONS TREATED BY OR ON BEHALF OF
                  THE END USER) FOR, AND THE END USER AGREES TO INDEMNIFY AND
                  HOLD CERNER HARMLESS FROM ANY CLAIMS, LAWSUITS, PROCEEDINGS,
                  COSTS, ATTORNEYS’ FEES, DAMAGES OR OTHER LOSSES (COLLECTIVELY,
                  "LOSSES") ARISING OUT OF OR RELATING TO (A) THE END USER'S USE
                  OF THE SERVICE OR CONTENT PROVIDED THEREON OR ANY EQUIPMENT
                  FURNISHED IN CONNECTION THEREWITH AND (B) ANY DATA OR
                  INFORMATION INPUT ON THE SERVICE BY END USER, IN ALL CASES
                  INCLUDING BUT NOT LIMITED TO LOSSES FOR TORT, PERSONAL INJURY,
                  MEDICAL MALPRACTICE OR PRODUCT LIABILITY. FURTHER, WITHOUT
                  LIMITING THE FOREGOING, IN NO EVENT SHALL CERNER BE LIABLE FOR
                  ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR INDIRECT DAMAGES,
                  INCLUDING DAMAGES FOR LOSS OF PROFITS, LOSS OF BUSINESS, OR
                  DOWN TIME, EVEN IF CERNER HAS BEEN ADVISED OF THE POSSIBILITY
                  OF SUCH DAMAGES. THE INFORMATION CONTAINED WITHIN THE SERVICE
                  IS INTENDED FOR USE ONLY AS AN INFORMATIONAL TOOL AND END
                  USERS ARE URGED TO CONSULT WITH A PHYSICIAN OR OTHER
                  HEALTHCARE PROFESSIONAL REGARDING THEIR SPECIFIC SITUATION FOR
                  DIAGNOSIS OR BY PHYSICIANS AND PROFESSIONALS WHO SHOULD RELY
                  ON THEIR CLINICAL DISCRETION AND JUDGMENT IN DIAGNOSIS AND
                  TREATMENT. AS BETWEEN THE END USER AND CERNER, THE END USER
                  HEREBY ASSUMES FULL RESPONSIBILITY FOR INSURING THE
                  APPROPRIATENESS OF USING AND RELYING UPON THE INFORMATION IN
                  VIEW OF ALL ATTENDANT CIRCUMSTANCES, INDICATIONS, AND
                  CONTRAINDICATIONS.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '30px', marginLeft: '20px' }}>
            <Typography sx={{ ...SX, fontWeight: 500 }}>{`3.  `}</Typography>
          </Box>
          <Box sx={{ marginLeft: 'auto', paddingLeft: '7px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ ...SX }}>
                  <span style={{ fontWeight: 'bold' }}>
                    Liability of Cerner to the End user.
                  </span>
                  Under no circumstances shall Cerner be liable to the End user
                  or any other person for any direct, indirect, exemplary,
                  special or consequential damages arising out of or relating to
                  the End user's use of or inability to use the Service or the
                  content of the Service provided thereon or any equipment
                  furnished in connection therewith. Cerner's total maximum
                  cumulative liability hereunder in connection with this
                  Agreement, whether arising under contract or otherwise, are
                  limited to the fees received by Cerner under this Agreement
                  specifically relating to the End user's use of the service or
                  product which is the subject of the claim.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
