import { AlternativeDrugsResponse } from '../../types'
import { ApiUrl } from '../../types/copies/app-types'
import { getMicroserviceApiDetails } from '../apiexec/portalapiexec'
import { usePortalApi } from '../apiexec/usePortalApi'

import { DrugIdParams } from '../drugprofile/types'
import { DosageOptionsResp, DrugSuggestionDataReturn } from './types'
import { RecentSearch, RecentSearchResp } from './useRecentSearches'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { toDigitsOnly } from '../member/phone-field-utils'
import { LOG } from '../v2/applog'
import { DrugProfileRespV2 } from '../v2/NewDrugProfilePage'

//new search related endpoints from member microservice
const SEACH_BY_KEYWORD_DEFAULT_SIZE = 5 //more than 5 really skews out rx-sense results

const resolveDrugId: ApiUrl = {
  endpoint: '/member-service/api/drug-id',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

const searchByKeyword: ApiUrl = {
  endpoint: '/member-service/api/search-drugs',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

const drugProfileV2: ApiUrl = {
  endpoint: '/member-service/api/drug-profile',
  apiType: 'member_micro',
  apiVersion: '2.0',
}

const dosageOptions: ApiUrl = {
  endpoint: '/member-service/api/drugs',
  apiType: 'member_micro',
  apiVersion: '1.0',
  ///1/pricing
}

//TODO - review best paramter here until proper pagination is implemented
//but also should backedn really return 10-15 recommendation of same drug but just a different dosage?

const PAGE_SIZE_DEFAULT_ALTS = 50

const originalPricing: ApiUrl = {
  endpoint: '/member-service/api/drugs',
  apiType: 'member_micro',
  apiVersion: '2.0',
}

const alternativePricing: ApiUrl = {
  endpoint: '/member-service/api/drugs',
  apiType: 'member_micro',
  apiVersion: '2.0',
  //member-service/api/drugs/123/alternate-drugs?drug-id=794&quantity=5&price=60.0
  //?drug-id=1&quantity=5
}

const couponPricing: ApiUrl = {
  endpoint: '/member-service/api/drugs',
  //member-service/api/drugs/1/pharmacy-pricing?drug-id=1&quantity=5&zipcode=94043
  apiType: 'member_micro',
  apiVersion: '1.0',
}

const recentSearches: ApiUrl = {
  endpoint: '/member-service/api/recent-drug-searches',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

export type DrugPriceInfoType =
  | 'COPAY'
  | 'PRE_DEDUCTIBLE'
  | 'POST_DEDUCTIBLE'
  | 'DEFAULT_COST'
  | 'COUPON'
// beforeDed = 'PRE_DEDUCTIBLE',
// afterDed = 'POST_DEDUCTIBLE',
// defaultCost = 'DEFAULT_COST',
// coupon = 'COUPON',/
// }

export interface DrugPriceInfo {
  type: DrugPriceInfoType
  amount: number
  'currency-code'?: string
  'pricing-error-code'?: number
  // 'curren': 'USD'
  // pricingErrorCode: number
}
export interface OriginalPricingResp {
  id: number
  name: string
  strength: string
  'dosage-form'?: string
  'strength-unit-of-measure'?: string
  'pricing-info'?: DrugPriceInfo[]
}
//backend uses code in regular search but seo-code in recent searches so we have to tweak that model here
//we also add a designated flag is-recent-search appropriatelly
export function toUiSearchResult(
  backednResp: RecentSearchResp,
): RecentSearch[] {
  return backednResp['recent-searches'].map((r: any) => {
    return { name: r.name, id: r.id, code: r['seo-code'], isRecentSearch: true }
  })
}

export function useDrugSearchService() {
  const { portalApi } = usePortalApi()

  async function resolveDrugIdFromMmaId(
    mmaId: number,
  ): Promise<number | undefined> {
    //log this event bc we should not be getting them anymore now that we have the alternate ids
    //TODO - except maybe for hiding the alternate drug?
    LOG.error(
      'generic_error',
      'Got request for legacy conversion of mmaId to drug id,which should not be happening anymore',
      { mmaId },
    )
    const { url, ops } = getMicroserviceApiDetails(resolveDrugId)
    const finalUrl = url + '?mma-id=' + mmaId
    try {
      const drugIdResp = await portalApi.getMicro<any>(finalUrl, ops)

      const status = checkMicroServiceResponseStatus(drugIdResp)
      if (!status.isError) {
        const drugId = drugIdResp['drug-id']
        return drugId
        // return new Promise((resolve, reject) => {
        //   resolve((convertResp as any)['drug-id'])
        // })
      } else {
        LOG.error(
          'generic_error',
          'Failed to convert mmaId to drugId,error status from EKS',
          { mmaId },
        )
        return undefined
      }
    } catch (e) {
      return new Promise((resolve, reject) => {
        resolve(undefined)
      })
    }
  }

  async function getRecentSearches(): Promise<RecentSearchResp> {
    const { url, ops } = getMicroserviceApiDetails(recentSearches)

    return portalApi.getMicro<RecentSearchResp>(url, ops)
  }
  async function addNewRecentSearch(s: RecentSearch): Promise<any> {
    //TODO - sync model to one when gcarlo is finished with ui
    const backendModel: any = {}
    backendModel.id = s.id
    backendModel.name = s.name
    backendModel['seo-code'] = s.code
    const { url, ops } = getMicroserviceApiDetails(recentSearches)
    return portalApi.postMicro<RecentSearchResp>(url, backendModel, ops)
  }
  async function deleteALLRecentSearches(): Promise<any> {
    const { url, ops } = getMicroserviceApiDetails(recentSearches)
    return portalApi.deleteMicro<RecentSearchResp>(url, ops)
  }
  async function deleteSingleRecenSearchByDrugId(drugId: number): Promise<any> {
    const { url, ops } = getMicroserviceApiDetails(recentSearches)
    const finalUrl = `${url}/${drugId}`
    return portalApi.deleteMicro<RecentSearchResp>(finalUrl, ops)
  }

  async function getOriginalPricing(
    drugIdParams: DrugIdParams,
    quantity: number,
  ): Promise<OriginalPricingResp> {
    const { url, ops } = getMicroserviceApiDetails(originalPricing)
    const drugId = await resolveDrugIdParams(drugIdParams)
    const finalUrl = `${url}/${drugIdParams.drugId}/pricing?drug-id=${drugId}&quantity=${quantity}`
    return portalApi.getMicro<OriginalPricingResp>(finalUrl, ops)
  }

  async function getDosageOptions(
    drugIdParams: DrugIdParams,
  ): Promise<DosageOptionsResp> {
    //TODO - proper order and checking
    const { url, ops } = getMicroserviceApiDetails(dosageOptions)
    const drugId = await resolveDrugIdParams(drugIdParams)

    const finalUrl = `${url}/${drugId}/dosages?drug-id=` + drugId
    const originalResp: DosageOptionsResp =
      await portalApi.getMicro<OriginalPricingResp>(finalUrl, ops)
    const validation = checkMicroServiceResponseStatus(originalResp)
    //
    if (!validation.isError) {
      //if using rxsense, just return it as is and its most popular drug id values will be selected
      if (drugIdParams.pricingUseRxSenseForDefaultDosages) {
        const withDosages = mergeDosageWithUnit(originalResp)
        return withDosages
      } else {
        //fix(hack) to enable selecting form/dosage of the original drug id in the pricing filters
        //replace the rxsense most popular id with the current drug id
        //this means at least the current drug id has to be in the list of dosages, it happens that it is not in which case we won't do anything
        const originalDrugIdx = originalResp['drug-dosages'].findIndex(
          (x) => x.id === drugId,
        )
        //
        if (originalDrugIdx !== -1) {
          //remove is popular from rxsense
          const rxSensMostPopularIdx = originalResp['drug-dosages'].findIndex(
            (x) => x['is-popular'] === true,
          )
          originalResp['drug-dosages'][rxSensMostPopularIdx]['is-popular'] =
            false

          //and mark the current drug id as most popular one in dosages
          originalResp['drug-dosages'][originalDrugIdx]['is-popular'] = true

          //and add the current quantity as the first item in the list - by convention, that makes it preselected
          if (drugIdParams.pricingQuantityDefault) {
            //make sure its clean quantity number just in case
            const qNumOnly = +toDigitsOnly(
              drugIdParams.pricingQuantityDefault.toString(),
            )
            if (!isNaN(qNumOnly)) {
              originalResp['drug-dosages'][originalDrugIdx].quantity = [
                +qNumOnly,
                ...originalResp['drug-dosages'][originalDrugIdx].quantity,
              ]
              //and make sure its not duplicated
              originalResp['drug-dosages'][originalDrugIdx].quantity = dist(
                originalResp['drug-dosages'][originalDrugIdx].quantity,
              )
            }
          }
        }

        //
        const withDosages = mergeDosageWithUnit(originalResp)

        return withDosages
      }
    } else {
      //error case from the backend we got no dosages. this really should never happen
      LOG.error(
        'search_results_data',
        'No dosage options returned by the API',
        {
          drugIdParams,
          originalResp,
        },
      )
      //return at least empty array - the existing pricing component will disable form / dosage filters and
      //just use original drug id for pricing
      const unknownDosages: DosageOptionsResp = {
        'drug-dosages': [],
        carryOverErrorCode:
          originalResp && (originalResp as any).scriptaErrorCode
            ? (originalResp as any).scriptaErrorCode
            : undefined,
      }
      return unknownDosages
    }
  }
  function dist(arr: number[]) {
    var a = []
    for (var i = 0, l = arr.length; i < l; i++)
      if (a.indexOf(arr[i]) === -1) a.push(arr[i])
    return a
  }

  //backend sens the dose unit i.e. MG outside the dose, which doesnt fit the ui display model
  function mergeDosageWithUnit(
    originalDosagesResp: DosageOptionsResp,
  ): DosageOptionsResp {
    const updated = originalDosagesResp['drug-dosages'].map((d) => {
      return { ...d, dosage: `${d.dosage} ${d['dosage-unit']}` }
    })
    const mergedDosesResp: DosageOptionsResp = {
      'drug-dosages': updated,
    }
    return mergedDosesResp
  }

  async function getAlternativePricingResults(
    drugIdParams: DrugIdParams,
    quantity: number,
    zipCode?: string,
  ): Promise<AlternativeDrugsResponse> {
    const { url, ops } = getMicroserviceApiDetails(alternativePricing)
    //TODO - input as mma?
    if (!drugIdParams.drugId) {
      LOG.error(
        'search_results_data',
        'UI error - no drug id params found - cannot call getalternatvepricing results',
        {
          drugIdParams,
        },
      )
      throw new Error(
        'No drug id params found - cannot call getalternatvepricing results',
      )
    }

    const drugId = drugIdParams.drugId

    let finalUrl = `${url}/${drugId}/alternate-drugs?drug-id=${drugId}`
    if (quantity || quantity === 0) {
      finalUrl = finalUrl + '&quantity=' + quantity
    }

    if (zipCode) {
      finalUrl = finalUrl + '&zipcode=' + zipCode
    }

    finalUrl = finalUrl + '&size=' + PAGE_SIZE_DEFAULT_ALTS

    //123/alternate-drugs?drug-id=794&quantity=5&price=60.0
    return portalApi.getMicro<AlternativeDrugsResponse>(finalUrl, ops)

    // return new Promise((resolve, reject) => {
    //   resolve(MOCK_ALT_RESULTS)
    // })
  }
  async function getCouponPricingResultsForDrugId(
    drugId: number,
    zipCode: string,
    quantity: string,
  ): Promise<any> {
    const { url, ops } = getMicroserviceApiDetails(couponPricing)
    // https://member-int.scriptainsights.io/member-service/api/drugs/14288/pharmacy-pricing?quantity=3&zipcode=60616
    const finalUrl = `${url}/${drugId}/pharmacy-pricing?quantity=${quantity}&zipcode=${zipCode}`
    return portalApi.getMicro<any>(finalUrl, ops)
  }

  async function getDrugNameSuggestionApi(
    searchPrefix: string,
  ): Promise<DrugSuggestionDataReturn> {
    const { url, ops } = getMicroserviceApiDetails(searchByKeyword)
    const finalUrl = `${url}?keyword=${searchPrefix}&size=${SEACH_BY_KEYWORD_DEFAULT_SIZE}`

    return portalApi.getMicro<DrugSuggestionDataReturn>(finalUrl, ops)
    // return new Promise((resolve, reject) => {
    //   resolve(MOCK_SEARCH_RESULTS)
    // })
  }

  async function getDrugProfileApiApiV2(
    drugIdParams: DrugIdParams,
  ): Promise<DrugProfileRespV2> {
    const { url, ops } = getMicroserviceApiDetails(drugProfileV2)
    const drugId = await resolveDrugIdParams(drugIdParams)

    let finalUrl = url + '?drug-id=' + drugId

    //add seo code if available for a better match
    if (drugIdParams.seoCode) {
      finalUrl = finalUrl + '&seo-code=' + drugIdParams.seoCode
    }

    // return new Promise((resolve, reject) => {
    //   resolve(MOCK_DRUG_PROFILE_MULTI_15)
    // })

    return portalApi.getMicro<DrugProfileRespV2>(finalUrl, ops)
  }

  async function resolveDrugIdParams(
    drugIdParams: DrugIdParams,
  ): Promise<number | undefined> {
    if (drugIdParams.drugId) {
      return drugIdParams.drugId
    }
    if (drugIdParams.mmaId) {
      //this should not be triggered any longer since we now have the alternate ids
      //need to convert first
      LOG.error(
        'generic_error',
        'detect drugIdParams resolve that is still using mma id conversion',
        { drugIdParams },
      )
      const convertedDrugId = await resolveDrugIdFromMmaId(drugIdParams.mmaId)
      return convertedDrugId
    }

    throw new Error('Invalid drug id params object')
  }

  return {
    getDrugNameSuggestionApi,
    getDrugProfileApiApiV2,
    getAlternativePricingResults,
    getCouponPricingResultsForDrugId,
    getOriginalPricing,
    getDosageOptions,
    getRecentSearches,
    addNewRecentSearch,
    deleteSingleRecenSearchByDrugId,
    deleteALLRecentSearches,
    resolveDrugIdParams,
  }
}
