import { ApiUrl, DisabledFeaturesReqData, ModuleConfig } from '../../types'

export const getLogo: ApiUrl = {
  endpoint: '/logo',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = <None>
   *           HttpHeaders.AUTHORIZATION: jwtToken
   * response = HttpHeaders.Content-Disposition = fileName=logo.png
   *          = HttpHeaders.AUTHORIZATION: jwtToken
   * error: 500 / Status Code: 332 - No Such User
   */
}

export const getBackendConfig: ApiUrl = {
  //its at the top of the modules rootApiUrl
  endpoint: '/',
  apiType: 'memberapp',
  verb: 'GET',
  /**
   * request = <None>
   * response = BackendConfig
   * error: TODO
   */
}

export const getPrivacyPolicy: ApiUrl = {
  //its at the top of the modules rootApiUrl
  endpoint: '/privacypolicy',
  apiType: 'memberapp',
  verb: 'GET',
  /**
   * request = <None>
   * response = PrivacyPolicy
   */
}

export const getTermsOfUse: ApiUrl = {
  //its at the top of the modules rootApiUrl
  endpoint: '/tncs',
  apiType: 'memberapp',
  verb: 'GET',
  /**
   * request = <None>
   * response = PrivacyPolicy
   */
}

export const getdisableFeature = (param: DisabledFeaturesReqData): ApiUrl => {
  const { tenantId } = param
  const disableStatus = 'disabled'
  return {
    endpoint: `/feature?clientId=${tenantId}&status=${disableStatus}`,
    apiType: 'memberapp',
    verb: 'GET',
    /**
     * request = void
     * response = Switches[]
     */
  }
}

export const PORTAL_MODULE_BACKEND_CONFIG = '@scripta-ui/portal/backendconfig'

export const backendConfigModule: ModuleConfig = {
  name: PORTAL_MODULE_BACKEND_CONFIG,
  rootUrl: '/config',
  rootApiUrl: '/members/application/config',
  uiTitle: 'Config',
  order: 3,
}
