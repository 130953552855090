//called to format existing backend number or user textfield input
//to display friendly string i.e. NNNNNNNNNNN -> (NNN) NNN - NNNNN
//note we don't use country code
export function toDisplayFriendlyString(textFieldInputValue?: string) {
  if (!textFieldInputValue) {
    return ''
  }

  //get just the digits
  let input = toDigitsOnly(textFieldInputValue)
  //remove country code from digits, if any
  if (input.length === 11) {
    input = input.substring(1, 11)
  }

  const areaCode = input.substring(0, 3)
  const middle = input.substring(3, 6)
  const last = input.substring(6, 10)

  let displayedNumber = ''
  if (input.length > 6) {
    displayedNumber = `(${areaCode}) ${middle} - ${last}`
  } else if (input.length > 3) {
    displayedNumber = `(${areaCode}) ${middle}`
  } else if (input.length > 0) {
    displayedNumber = `(${areaCode}`
  }
  return displayedNumber
}
//called to validate a current input value of a text field as user is interacting i.e. (312) 4
//to enable save button at the right time
export function isDisplayFriendlyStringValidNumber(
  phoneAsDisplayString: string,
) {
  const numsOnly = toDigitsOnly(phoneAsDisplayString)
  return numsOnly.length === 10
}

export function toFullNumberFromDisplayFriendlyString(displayedString: string) {
  return 1 + toDigitsOnly(displayedString)
}
export function toDigitsOnly(num: string) {
  if (!num) return ''
  return num.replace(/\D/g, '')
}
