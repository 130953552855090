import { Grid, Typography } from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import EmailLink from '../widgets/EmailLink'
import PhoneLink from '../widgets/PhoneLink'
import { LOG } from 'src/app/v2/applog'
import { useEffect, useState } from 'react'
import { getUrlParameter } from '../apiexec/utils'
import { useAmazon } from 'src/app/hooks/useAmazon'
import {
  SSO_URL_PARAM_ERROR_CODE,
  SSO_URL_PARAM_SHORTCODE,
} from './SamlLoginHandler'

export default function SamlLoginError() {
  const { t } = useSTranslate(['sso', 'common'])

  const [errorCode, setErrorCode] = useState<string | undefined>(undefined)

  const { setIsAmazon } = useAmazon()
  useEffect(() => {
    const shortCode = getUrlParameter(SSO_URL_PARAM_SHORTCODE)
    if (
      shortCode &&
      (shortCode.indexOf('amazon') > -1 || shortCode.indexOf('okta') > -1)
    ) {
      console.log(
        'amazon (or okta) shortcode detected, setting isAmazon to true',
      )
      setIsAmazon(true)
    }
  }, [setIsAmazon])

  useEffect(() => {
    const errorCode = getUrlParameter(SSO_URL_PARAM_ERROR_CODE)
    if (errorCode) {
      setErrorCode(errorCode)
    }
  }, [])

  useEffect(() => {
    LOG.error(
      'sso',
      'Detect a request to show the generic SSO login error page. This means user was not able to SSO in. Please contact API team for more details and server side logs.Invoked params?:  ',
      window.location.search,
    )
  }, [])

  return (
    <Grid
      container
      alignItems="center"
      style={{ padding: 30, paddingBottom: 20, gap: 16 }}
    >
      <Grid item xs={12} style={{ textAlign: 'center', fontWeight: 600 }}>
        <Typography variant="h5" style={{ fontWeight: 700 }}>
          {t('sso:profileInitErrorMemberNotFoundTitle')}
        </Typography>
      </Grid>
      {errorCode && (
        <Grid item xs={12} style={{ textAlign: 'center', fontWeight: 500 }}>
          <Typography variant="h5" style={{ fontWeight: 700 }}>
            Code: {errorCode}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography>{t('sso:memberNotFoundDesc1')}</Typography>
        <br />
        <Typography>{t('sso:memberNotFoundDesc2')}</Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'center', marginTop: 16 }}>
        <Typography variant="caption">
          {t('sso:contactMemberSupportAt')}
        </Typography>{' '}
        <Typography variant="caption">
          <span style={{ whiteSpace: 'nowrap' }}>
            <EmailLink shouldShowUnderline={true} />
          </span>{' '}
          or call at{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            <PhoneLink shouldShowUnderline={true} />
          </span>
        </Typography>
      </Grid>
    </Grid>
  )
}
