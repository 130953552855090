import { Grid, useMediaQuery, useTheme } from '@mui/material'
import SButton from '../system/customcomponents/SButton'

export function DualBottomButtonToolbar({
  onClickYes,
  onClickNo,
  yesText,
  noText,
  noHidden = false,
  yesDisabled = false,
}: {
  onClickYes: () => void
  onClickNo: () => void
  yesText: string
  noText: string
  noHidden?: boolean
  yesDisabled?: boolean
}) {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      item
      xs={12}
      container
      sx={{ padding: isSmall ? '20px 20px 10px 20px' : '30px' }}
      justifyContent={'flex-end'}
    >
      {!noHidden ? (
        <Grid item>
          <SButton
            data-testid="dual-bottom-button-toolbar-no"
            sx={{
              fontWeight: 700,
              fontSize: '1rem',
              width: '91px',
            }}
            onClick={() => {
              onClickNo()
            }}
            variant="outlined"
          >
            {noText}
          </SButton>
        </Grid>
      ) : null}
      <Grid item>
        <SButton
          data-testid="dual-bottom-button-toolbar-yes"
          sx={{
            marginLeft: '10px',
            width: '130px',
            fontSize: '1rem',
            fontWeight: 700,
          }}
          onClick={async () => {
            onClickYes()
          }}
          variant="contained"
          disabled={yesDisabled}
        >
          {yesText}
        </SButton>
      </Grid>
    </Grid>
  )
}
