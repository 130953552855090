import { isValidResponse, PrivacyPolicy, TermsOfUse } from '../../types'

import { isDisplayFriendlyStringValidNumber } from '../member/phone-field-utils'

export async function ssoInitPrivayPolicy(
  configService: any,
  setPrivacyPolicy: any,
  setError: any,
) {
  try {
    const privacyPolicy = await configService.getPrivacyPolicyApi()

    if (isValidResponse<PrivacyPolicy>(privacyPolicy)) {
      setPrivacyPolicy(privacyPolicy)
    } else {
      console.error('Not a valid reponse from get privacy policy api.')
      setError('Not a valid reponse from get privacy policy api.')
    }
  } catch (error) {
    console.error('Error loading privacy policy config.') //, error)
    setError('Error loading privacy policy config')
  }
}

export async function ssoInitTermsOfUse(
  configService: any,
  setTermsOfUse: any,
  setError: any,
) {
  try {
    const termsOfUse = await configService.getTermsOfUseApi()

    if (isValidResponse<TermsOfUse>(termsOfUse)) {
      setTermsOfUse(termsOfUse)
    } else {
      console.error('Not a valid reponse from get terms of use api.')
      setError('Not a valid reponse from get terms of use api.')
    }
  } catch (error) {
    console.error('Error loading terms of use.') //, error)
    setError('Error loading terms of use.')
  }
}

// export function getOptionalInitEmail() {
//   const email = getUrlParameter(SSO_URL_PARAM_INIT_EMAIL)
//   return email
// }
export function isCellvalid(numberString?: string) {
  return isDisplayFriendlyStringValidNumber(numberString ? numberString : '')
}

// export function getOptionalInitMobileNumber() {
//   const mobile = getUrlParameter(SSO_URL_PARAM_INIT_MOBILE)
//   const cleanedPhoneNumber = mobile
//     ? mobile
//         .replace(/\D/g, '') // Remove non-numeric characters
//         .replace(/^1/, '') // Remove USA country code in case
//     : undefined
//   return cleanedPhoneNumber
// }
