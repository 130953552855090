import { ApiUrl, ModuleConfig } from '../../types'

export const findTenant: ApiUrl = {
  endpoint: '/find/tenant',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * parameters = RegistrationData (companyName, primaryEmail)
   * response = Tenant|StatusCode
   * error: 404 / Status Code: 211
   */
}

export const findAccount: ApiUrl = {
  endpoint: '/find/account',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * parameters = RegistrationData (tenantId={Tenant.Id}, memberFirstName, memberLastName,memberDOB
   *   matchType,
   *   oneOf(last4SSN, employeeId, memberId)
   * )
   * response = Member|StatusCode
   * error: 404 / Status Code: 225 - No Matching Member
   * error: 400 / Status Code: 243 - No Verification Information
   */
}

export const sendVerificationOtp: ApiUrl = {
  endpoint: 'verify/challenge/opt/send/{method}',
  apiType: 'memberapp',
  verb: 'PUT',
  /**
   * method : 'phone'|'email'
   * parameters: (RegistrationData)
   *
   *
   * response = Member|StatusCode
   * error: 404 / Status Code: 225 - No Matching Member
   * error: 400 / Status Code: 243 - No Verification Information
   */
}

export const verifyOtp: ApiUrl = {
  endpoint: 'verify/challenge/opt/verify',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * parameter: (OneTimePassCode)
   *
   *
   * response = Member|StatusCode
   * error: 400 / Status Code: 230 - Invalid Passcode
   */
}

export const verificationChallengeQuestions: ApiUrl = {
  endpoint: '/verify/challenge/questions',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = Member
   * response = RegistrationChallenge|StatusCode
   * error: 400 / Status Code: 244 - No Verification Challenge Available
   */
}

export const verifyChallengeQuestionsResponse: ApiUrl = {
  endpoint: '/verify/challenge/questions/verify',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = RegistrationChallengeResponse
   * response = None|StatusCode
   * error: 400 / Status Code: 235 - Challenge Failed
   */
}

export const captureEmail: ApiUrl = {
  endpoint: '/mandatory/email',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = RegistrationSetupData
   * response = None|StatusCode
   * error: 400 / No Status Codes - returned only if member and tenant id not provided
   */
}

export const smsOptIn: ApiUrl = {
  endpoint: '/sms/optin',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = RegistrationSetupData
   * response = None|StatusCode
   * error: 400 / No Status Codes - returned only if member and tenant id not provided
   */
}

export const createMemberAccount: ApiUrl = {
  endpoint: '/password/setup',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = RegistrationSetupData
   * response = None|StatusCode
   * error: 400 / Status Code: 303 - User Name Not Available
   * error: 400 / Status Code: 310 - Password does not meet requirements - message includes password requirements
   */
}

export const findAccountFromQuickLink: ApiUrl = {
  endpoint: '/find/account_from_quick_link',
  apiType: 'memberapp',
  verb: 'GET',
}
export const verifyQuickLinkOtp: ApiUrl = {
  endpoint: '/verify/challenge/quicklink_paired_otp',
  apiType: 'memberapp',
  verb: 'GET',
}
export const findMigratedMemberProfileFromQuickLink: ApiUrl = {
  endpoint: '/migration/get_profile_from_quicklink',
  apiType: 'memberapp',
  verb: 'GET',
}
export const completeProfile: ApiUrl = {
  endpoint: '/v1/additional/info',
  apiType: 'memberapp',
  verb: 'POST',
}

// export const completePreregUserProfileEndpoint: ApiUrl = {
//   endpoint: '/temporary_prereg_complete_profile_post_sec_validation',
//   apiType: 'memberapp',
//   verb: 'POST',
// }

export const PORTAL_MODULE_REGISTRATION = '@scripta-ui/portal/registration'

export const registrationModule: ModuleConfig = {
  name: PORTAL_MODULE_REGISTRATION,
  rootUrl: '/register',
  rootApiUrl: '/register',
  uiTitle: 'Register Member',
  order: 0,
}
