import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { handleExternalLinkClick } from './ExternalLink'
import { Link, SxProps, Typography } from '@mui/material'
import { DEFAULT_PORTAL_APP_CONFIG } from '../config/appconfig'

export function PrivacyOrTermsLink({
  type,
  isBold = false,
  isUnderlined = false,
  customSx,
}: {
  type: 'privacy' | 'terms'
  isBold?: boolean
  isUnderlined?: boolean
  customSx?: SxProps
}) {
  const { t } = useSTranslate('application')
  const url =
    type === 'privacy'
      ? DEFAULT_PORTAL_APP_CONFIG.PRIVACY_POLICY_URL
      : DEFAULT_PORTAL_APP_CONFIG.TERMS_AND_CONDITIONS_URL
  const label =
    type === 'privacy' ? t('privacyPolicyTitle') : t('termsOfUseTitle')

  return (
    <>
      <Link
        onClick={() => {
          handleExternalLinkClick(url)
        }}
        sx={{
          cursor: 'pointer',
          textDecoration: isUnderlined ? 'underline' : 'none',
        }}
      >
        <Typography
          sx={{ ...customSx, fontWeight: isBold ? 'bold' : 'normal' }}
        >
          {label}
        </Typography>
      </Link>
    </>
  )
}
