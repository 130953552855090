import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useEffect } from 'react'

import { useSColors } from '../styles/scripta-theme'
import SButton from '../system/customcomponents/SButton'

export interface SingleActionProps {
  text?: string
  onClick?: () => void | Promise<void>
  buttonSx?: any
}
export interface DualButtonActionsProps {
  onYes: () => void | Promise<void>
  onNo: () => void | Promise<void>
  yesText?: string
  noText?: string
  yesButtonSx?: any
  noButtonSx?: any
  yesDisabled?: boolean
  // position?: 'centered''right'
}

export function NewPortalDialogDesign({
  //upper right close button
  upperRightX = true,
  singleActionProps,
  dualActionProps,
  headerImageImport,
  headerImageIconNode,
  headerImageImageProps,
  title,
  customTitleSx,
  open,
  onClose,
  children,
  dividers = false,
  //need these two for ridicilious request to overly image on top of dialog for agreement change, see PolicyAgreementChangeDialog.tsx
  customPaperSx,
  customHeaderImageBoxSx,
  customContentSx,
  maxWidth,
}: {
  upperRightX?: boolean
  singleActionProps?: SingleActionProps
  dualActionProps?: DualButtonActionsProps
  headerImageImport?: string
  headerImageIconNode?: React.ReactElement
  headerImageImageProps?: any
  title?: string | React.ReactElement
  customTitleSx?: any
  open: boolean
  onClose?: () => void
  children?: any
  dividers?: boolean
  customPaperSx?: any
  customHeaderImageBoxSx?: any
  customContentSx?: any
  maxWidth?: Breakpoint
}) {
  const { COLORS } = useSColors()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [isOpen, setIsOpen] = React.useState(open)
  useEffect(() => {
    setIsOpen(open)
  }, [open])

  function handleClose() {
    setIsOpen(false)
    if (onClose) onClose()
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth={maxWidth ? maxWidth : 'sm'}
      // maxWidth={isSmallDisplay ? 'xs' : ''}
      fullWidth={true}
      PaperProps={{
        sx: {
          borderRadius: '15px',
          marginLeft: '10px',
          marginRight: '10px',
          ...customPaperSx,
        },
      }}
    >
      {upperRightX && (
        <Box sx={{ display: 'flex', marginBottom: '0px', marginTop: '16px' }}>
          <Box sx={{ marginLeft: 'auto' }}>
            <CloseIcon
              style={{
                marginLeft: 'auto',
                marginRight: '20px',
                // color: (theme) => theme.palette.grey[500],
              }}
              height={'20px'}
              width={'20px'}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                handleClose()
              }}
            />
          </Box>
        </Box>
      )}

      <DialogContent
        dividers={dividers}
        sx={{
          paddingTop: '0px',
          paddingLeft: isSmall ? '15px' : '30px',
          paddingRight: isSmall ? '15px' : '30px',
          ...customContentSx,
        }}
      >
        {headerImageImport && (
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              ...customHeaderImageBoxSx,
              paddingTop: upperRightX ? '10px' : '20px',
            }}
          >
            <img
              src={headerImageImport}
              alt={title}
              {...headerImageImageProps}
              // width="125px"
            />
          </Box>
        )}
        {headerImageIconNode && (
          <Box
            sx={{
              width: '100%',
              textAlign: 'center',
              ...customHeaderImageBoxSx,
              paddingTop: '10px',
            }}
          >
            {headerImageIconNode}
          </Box>
        )}
        <Box sx={{ width: '100%', margin: '0px 0px 0px 0px' }}>
          <Typography
            textAlign="center"
            sx={{
              paddingBottom: '15px',
              fontWeight: 700,
              fontStyle: 'underline',
              fontSize: '1.5rem',
              paddingRight: '20px',
              paddingLeft: '20px',
              color: COLORS.PRIMARY_BRAND,
              ...customTitleSx,
            }}
          >
            {title}
          </Typography>
        </Box>

        {children}
      </DialogContent>
      <DialogActions>
        {singleActionProps && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginBottom: '20px',
              // backgroundColor: 'red',
            }}
          >
            <SButton
              sx={{
                width: '300px',
                height: '35px',
                padding: '0px',
                fontSize: '1rem',
                fontWeight: 700,
                ...singleActionProps.buttonSx,
              }}
              onClick={() => {
                if (singleActionProps.onClick) {
                  singleActionProps.onClick()
                }
              }}
              variant="contained"
            >
              {singleActionProps.text ? singleActionProps.text : 'OK'}
            </SButton>
          </Box>
        )}
        {dualActionProps && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100px',
              marginBottom: '20px',
              marginRight: '30px',
              // backgroundColor: 'red',
            }}
          >
            <SButton
              sx={{
                height: '35px',
                minWidth: '100px',
                padding: '0px',
                fontSize: '0.9rem',
                // backgroundColor: 'white',
                // color: COLORS.BACKGROUND_SECONDARY_ACTIVE,
                ...dualActionProps.noButtonSx,
              }}
              onClick={() => {
                if (dualActionProps.onNo) {
                  dualActionProps.onNo()
                }
              }}
              variant="outlined"
            >
              {dualActionProps.noText ? dualActionProps.noText : 'No'}
            </SButton>
            <SButton
              sx={{
                minWidth: '125px',
                height: '35px',
                padding: '0px',
                fontSize: '0.9rem',
                marginLeft: '10px',

                ...dualActionProps.yesButtonSx,
              }}
              onClick={() => {
                if (dualActionProps.onYes) {
                  dualActionProps.onYes()
                }
              }}
              variant="contained"
              disabled={dualActionProps.yesDisabled ? true : false}
            >
              {dualActionProps.yesText ? dualActionProps.yesText : 'Yes'}
            </SButton>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}
