import { useEffect, useState } from 'react'
import { useMemberAppContext } from '../MemberAppContext'
import { usePortalThemeContext } from 'src/app/styles/PortalThemeContext'
//should we rely on hooks? or just get it via vindow?
//probably both bc for ex. sso error case will not know jwtdetails
export function useAmazon() {
  const { jwtDetails, isAmazon, setIsAmazon } = useMemberAppContext()
  const { setSelectedThemeByName } = usePortalThemeContext()
  useEffect(() => {
    if (
      jwtDetails &&
      jwtDetails.tenant_short_code_key &&
      jwtDetails.tenant_short_code_key.indexOf('amazon') > -1
    ) {
      setIsAmazon(true)
      setSelectedThemeByName('amazon-blue')
    }
    //temp users for testing
    if (
      jwtDetails &&
      jwtDetails.user_name_key &&
      (jwtDetails.user_name_key.indexOf('uat_az') > -1 ||
        jwtDetails.user_name_key.indexOf('uat_az1') > -1 ||
        jwtDetails.user_name_key.indexOf('uat_az2') > -1 ||
        jwtDetails.user_name_key.indexOf('uat_az3') > -1)
    ) {
      setIsAmazon(true)
      setSelectedThemeByName('amazon-blue')
    }
  }, [jwtDetails])

  //can be set by saml login based on passed in short code
  useEffect(() => {
    if (isAmazon) {
      setSelectedThemeByName('amazon-blue')
    }
  }, [isAmazon])

  return { isAmazon, setIsAmazon }
}
