import { useEffect, useState } from 'react'
import { useMemberAppContext } from '../MemberAppContext'
import { PortalApiEx } from './portalapiexec'

export function usePublicPortalApi() {
  const portalPublicApi = new PortalApiEx()

  return { portalPublicApi }
}

export function usePortalApi() {
  const { jwt } = useMemberAppContext()

  if (!jwt) {
    throw Error(
      'cannot instantiate userPortalApi, user not authenticated or authentication expired',
    )
  }

  const portalApi = new PortalApiEx(jwt)
  portalApi.setJwt(jwt)

  return { portalApi }
}
