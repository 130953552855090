import { CircularProgress, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useMemberAppContext } from '../MemberAppContext'
import { useRegistration } from './RegistrationContext'
import { REG_ROUTE_LOGIN } from './RegistrationSubRoutes'
import MemberSupportFooter from './MemberSupportFooter'
import { LOG } from '../v2/applog'

export function CompletePreregFirstTimeProfile() {
  
  const { registrationJwt, member, history } = useRegistration()
  const {
    preRegCredentials,
  } = useMemberAppContext()
  const [isLoading, setIsLoading] = useState(true)
  const [completeProfileError, setCompleteProfileError] = useState<
    string | undefined
  >(undefined)

  async function completePreregMemberProfile(
    registrationJwt: string,
    preRegCredentials: any,
  ) {
    
    setIsLoading(true)
    try {
      if (!registrationJwt)
        throw new Error(
          'No registrationJwt - unable to complete the preregistration profile',
        )
     
      const autoLogin: any = {
        justRegisteredUserName: preRegCredentials.userName,
        justRegisteredPassword: preRegCredentials.password,
      }
 
      history.push(REG_ROUTE_LOGIN, { autoLogin: autoLogin })

    } catch (e) {
      console.error(e)
      console.error('Unable to complete preregistered member profile')
      LOG.error('preregistration', 'Complete Prereg First Time profile, Unable to complete,Error=', e)
      setCompleteProfileError(
        'Unfortunatelly, we are unable to validate your identity at this moment. Please contact our member support.',
      )
    }
    setIsLoading(false)
  }
  useEffect(() => {
    if (!registrationJwt) return
    if (!member) return
    if (registrationJwt && member && member.id && member.tenantId) {
      completePreregMemberProfile(registrationJwt, preRegCredentials)
    }
  }, [registrationJwt, member, preRegCredentials])

  return (
    <Grid container>
      <Grid item xs={12} sx={{ padding: '20px' }}>
        {isLoading && <CircularProgress />}
      </Grid>
      <Grid item xs={12} container sx={{ padding: '20px' }}>
        {completeProfileError && (
          <>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontColor: 'red',
                  textAlign: 'center',
                  fontSize: '1.2rem',
                }}
              >
                {completeProfileError}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: '20px' }}>
              <MemberSupportFooter
                customFontSx={{ fontSize: '1.1rem', color: '#201F22' }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}
