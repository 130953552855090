import {
  MemberPrescription,
  MemberPrescriptionAlternative,
  MemberPrescriptionSaving,
} from 'src/types'
import { SavingStrategy } from '../strategies/types'
import {
  isDisplayFriendlyStringValidNumber,
  toDigitsOnly,
} from '../member/phone-field-utils'
import { validateEmail } from '../registration/EditContactInfo'
import { dateWithYearMonthDay } from '../registration/utils/Formaters'

function isDOBIncorrect(dateOfBirth: string) {
  return (
    !!dateOfBirth &&
    !/^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(
      dateOfBirth,
    )
  )
}
//relax rules for user input a bit since its a manual process
export function isDOBIncorrectWithDashForwardOrBackSlash(dateOfBirth: string) {
  return (
    !!dateOfBirth &&
    !/^(0?[1-9]|1[0-2])(\/|-|\\)(0?[1-9]|1\d|2\d|3[01])(\/|-|\\)(19|20)\d{2}$/.test(
      dateOfBirth,
    )
  )
}
export function isValidZipCode(zipCode: string) {
  if (!zipCode) return false
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode)
}

export function validateUiData(data: UiFormData): ValidationResult {
  let updatedValidation: ValidationResult = {} as ValidationResult
  if (!data.fullName) {
    updatedValidation = { ...updatedValidation, validName: false }
  } else {
    updatedValidation = { ...updatedValidation, validName: true }
  }

  if (!data.dob) {
    updatedValidation = { ...updatedValidation, validDob: false }
  } else {
    const isInvalid = isDOBIncorrectWithDashForwardOrBackSlash(data.dob)
    updatedValidation = { ...updatedValidation, validDob: !isInvalid }
  }

  if (!data.email) {
    updatedValidation = { ...updatedValidation, validEmail: false }
  } else {
    const isValid = validateEmail(data.email)
    updatedValidation = { ...updatedValidation, validEmail: isValid }
  }

  // phone is optional, but if provided it needs to be valid
  if (!data.phone) {
    updatedValidation = { ...updatedValidation, validPhone: true }
  } else {
    //make sure its valid
    const phone = toDigitsOnly(data.phone)
    if (!isDisplayFriendlyStringValidNumber(phone)) {
      updatedValidation = { ...updatedValidation, validPhone: false }
    } else {
      updatedValidation = { ...updatedValidation, validPhone: true }
    }
  }

  if (!data.pharmacyName) {
    updatedValidation = { ...updatedValidation, validPharmacyName: false }
  } else {
    updatedValidation = { ...updatedValidation, validPharmacyName: true }
  }

  if (!data.pharmacyAddress) {
    updatedValidation = { ...updatedValidation, validPharmacyAddress: false }
  } else {
    updatedValidation = { ...updatedValidation, validPharmacyAddress: true }
  }

  if (!data.pharmacyZip) {
    updatedValidation = { ...updatedValidation, validPharmacyZip: false }
  } else {
    const isValidZip = isValidZipCode(data.pharmacyZip)
    updatedValidation = { ...updatedValidation, validPharmacyZip: isValidZip }
  }

  if (!data.pharmacyPhone) {
    updatedValidation = { ...updatedValidation, validPharmacyPhone: false }
  } else {
    //make sure its valid
    const phone = toDigitsOnly(data.pharmacyPhone)
    if (!isDisplayFriendlyStringValidNumber(phone)) {
      updatedValidation = { ...updatedValidation, validPharmacyPhone: false }
    } else {
      updatedValidation = { ...updatedValidation, validPharmacyPhone: true }
    }
  }

  if (!data.prescriberName) {
    updatedValidation = { ...updatedValidation, validPrescriberName: false }
  } else {
    updatedValidation = { ...updatedValidation, validPrescriberName: true }
  }

  if (!data.prescriberPhone) {
    updatedValidation = { ...updatedValidation, validPrescriberPhone: false }
  } else {
    const isValidPhoen = isDisplayFriendlyStringValidNumber(
      data.prescriberPhone,
    )
    updatedValidation = {
      ...updatedValidation,
      validPrescriberPhone: isValidPhoen,
    }
  }

  if (!data.emailOk && !data.phoneOk) {
    updatedValidation = {
      ...updatedValidation,
      validAtLeastPhoneOrEmailOk: false,
    }
  } else {
    updatedValidation = {
      ...updatedValidation,
      validAtLeastPhoneOrEmailOk: true,
    }
  }

  return updatedValidation
}

export function isFullyValid(ValidationResult: ValidationResult) {
  //function to check if all boolean fields are true on the object
  const values = Object.values(ValidationResult)
  return values.every((value) => value === true)
}

export interface UiFormData {
  fullName?: string
  email?: string
  phone?: string
  dob?: string
  emailOk?: boolean
  phoneOk?: boolean
  prescriberPhone?: string
  prescriberName?: string
  pharmacyName?: string
  pharmacyPhone?: string
  pharmacyAddress?: string
  pharmacyZip?: string
  authorityFullName?: string
  authorityType?: string
  authorityOtherDesc?: string
}
export interface ValidationResult {
  validName: boolean
  validEmail: boolean
  validDob: boolean
  validPhone: boolean

  validPrescriberName: boolean
  validPrescriberPhone: boolean

  validPharmacyName: boolean
  validPharmacyAddress: boolean
  validPharmacyZip: boolean
  validPharmacyPhone: boolean

  validAtLeastPhoneOrEmailOk: boolean
}

export interface HelpMeSwitchBackendPayload {
  'member-full-name': string
  'member-email': string
  'member-phone': string
  'member-dob': string
  'original-drug-id': number
  'original-name': string
  'original-dosage': string
  'original-form': string
  strategy: string
  coupon: boolean
  'alternate-mma-id': number
  'alternate-name': string
  'alternate-dosage': string
  'alternate-form': string
  'prescriber-full-name': string
  'member-contact-method': string
  'prescriber-phone': string
  'preferred-pharmacy-name': string
  'preferred-pharmacy-phone': string
  'preferred-pharmacy-address': string
  'preferred-pharmacy-zip': string
  'authority-full-name'?: string
  'authority-type'?: string
  'authority-other-desc'?: string
}

// name all steps as constants with index
export const STEP_INTRO = 0
export const STEP_LEGAL_RELEASE = 1
export const STEP_PATIENT_DETAILS = 2
export const STEP_PREF_CONTACT_METHOD = 3
export const STEP_PRESCRIBER_DETAILS = 4
export const STEP_PHARMACY_DETAILS = 5

export interface SwitchData {
  'original-id': number
  'original-name': string
  'original-dosage': string
  'original-form': string
  'alternate-mma-id': number
  'alternate-name': string
  'alternate-dosage': string
  'alternate-form': string
  strategy: string
  isAlternativeCoupon: boolean
  //this really means just savings strategy = Coupons
  // isOriginalCoupon: boolean
}

export function buildHelpMeSwitchDrugData(
  prescription: MemberPrescriptionSaving,
  alt: MemberPrescriptionAlternative,
): SwitchData {
  const switchData: SwitchData = {
    'original-id': prescription.prescription
      ? prescription.prescription.drug.drugIdValidOnlyUnderPrescription!
      : 0,
    'original-name': prescription.prescription
      ? prescription.prescription.drug.simpleName!
      : 'Original Name NA',
    'original-dosage': prescription.prescription
      ? prescription.prescription.drug.dosage!
      : 'Original Dosage NA',
    'original-form': prescription.prescription
      ? prescription.prescription.drug.form!
      : 'Original Form NA',
    'alternate-name': alt.drug.simpleName!,
    'alternate-dosage': alt.drug.dosage!,
    'alternate-form': alt.drug.form!,
    'alternate-mma-id': alt.drug.mmaIdValidOnlyUnderAlternatives!,
    strategy: alt.strategy,
    isAlternativeCoupon: alt.coupon ? true : false,
  }
  return switchData
}

export const AUTHORITY_TYPE_PARENT = 'Parent'
export const AUTHORITY_TYPE_GUARDIAN = 'Legal Guardian'
export const AUTHORITY_TYPE_POWER_OF_ATTORNEY =
  'Activated Health Care Power of Attorney'
export const AUTHORITY_TYPE_OTHER = 'Other'

export function toBackendPayload(
  uiData: UiFormData,
  switchData: SwitchData,
): HelpMeSwitchBackendPayload {
  return {
    'member-full-name': uiData.fullName!,
    'member-email': uiData.email!,
    'member-phone': uiData.phone!,
    'member-dob': toServerDate(uiData.dob!),
    'member-contact-method': getMethod(uiData)!,
    'authority-full-name': uiData.authorityFullName,
    'authority-type': uiData.authorityType,
    'authority-other-desc': uiData.authorityOtherDesc,
    'prescriber-full-name': uiData.prescriberName!,
    'prescriber-phone': uiData.prescriberPhone!,
    'preferred-pharmacy-name': uiData.pharmacyName!,
    'preferred-pharmacy-phone': uiData.pharmacyPhone!,
    'preferred-pharmacy-address': uiData.pharmacyAddress!,
    'preferred-pharmacy-zip': uiData.pharmacyZip!,
    strategy: switchData.strategy,
    coupon: switchData.isAlternativeCoupon,
    'alternate-dosage': switchData['alternate-dosage'],
    'alternate-form': switchData['alternate-form'],
    'alternate-mma-id': switchData['alternate-mma-id'],
    'alternate-name': switchData['alternate-name'],
    'original-dosage': switchData['original-dosage'],
    'original-form': switchData['original-form'],
    'original-drug-id': switchData['original-id'],
    'original-name': switchData['original-name'],
  }
}
function getMethod(patientData: UiFormData) {
  if (patientData.emailOk && patientData.phoneOk) {
    return 'both'
  } else if (patientData.emailOk) {
    return 'email'
  } else if (patientData.phoneOk) {
    return 'phone'
  }
}

//we allow user to enter mm- or mm/ or mm\
export function toServerDate(date: string) {
  let arr = undefined

  if (date.indexOf('-') > -1) {
    arr = date.split('-')
  } else if (date.indexOf('/') > -1) {
    arr = date.split('/')
  } else if (date.indexOf('\\') > -1) {
    arr = date.split('\\')
  }

  if (arr && arr.length === 3) {
    const year = arr[2]
    const month = arr[0]
    const day = arr[1]
    return `${year}-${month}-${day}`
  } else {
    console.warn(
      'unable to convert the input data to server date format,input date=' +
        date,
    )
    return date
  }
}

export function autoformatDob(value: string) {
  const date = value.replace(/\D/g, '') || ''
  const length = date.length
  if (length < 3) return date
  if (length < 5) return `${date.slice(0, 2)}/${date.slice(2)}`
  return `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4)}`
}
