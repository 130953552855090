import { Button, ButtonProps } from '@mui/material'
import { SPACING } from '../theme2'
import { useSColors } from 'src/app/styles/scripta-theme'

export type SButtonColor = 'primary' | 'error'
export type SButtonVariant = 'contained' | 'outlined'
export interface SButtonProps
  extends Omit<ButtonProps, 'size' | 'variant' | 'color'> {
  children?: React.ReactNode
  size?: 'small' | 'default'
  variant?: SButtonVariant
  noCaps?: boolean
  color?: SButtonColor
}

export default function SButton({
  children,
  size = 'default',
  variant = 'contained',
  noCaps = false,
  // color = 'primary',
  ...props
}: SButtonProps) {
  const { COLORS } = useSColors()

  let colorSx = undefined
  if (variant === 'contained') {
    colorSx = {
      color: COLORS.BUTTON_PRIMARY_TEXT,
      backgroundColor: COLORS.BUTTON_PRIMARY_BACKGROUND,

      '&:hover': {
        color: COLORS.BUTTON_PRIMARY_TEXT_ACTIVE,
        backgroundColor: COLORS.BUTTON_PRIMARY_BACKGROUND_ACTIVE,
        boxShadow: `inset 0 0 0 2px ${COLORS.BUTTON_PRIMARY_BACKGROUND_ACTIVE}`,
      },
      '&.Mui-disabled': {
        color: COLORS.TEXT_GREY_LIGHT,
        backgroundColor: COLORS.BACKGROUND_GREY_MEDIUM,
      },
    }
  } else if (variant === 'outlined') {
    colorSx = {
      color: COLORS.BUTTON_SECONDARY_TEXT,
      backgroundColor: COLORS.BUTTON_SECONDARY_BACKGROUND,

      '&:hover': {
        color: COLORS.BUTTON_SECONDARY_TEXT_ACTIVE,
        backgroundColor: COLORS.BUTTON_SECONDARY_BACKGROUND_ACTIVE,
        boxShadow: `inset 0 0 0 2px ${COLORS.BUTTON_PRIMARY_BACKGROUND_ACTIVE}`,
      },
      '&.Mui-disabled': {
        color: COLORS.TEXT_GREY_LIGHTER,
        backgroundColor: 'none',
      },
    }
  }

  const { color } = props
  if (color === 'error') {
    colorSx = {
      color:
        variant === 'contained'
          ? COLORS.TEXT_LIGHT_PRIMARY_WHITE
          : COLORS.ERROR_RED,
      backgroundColor:
        variant === 'contained'
          ? COLORS.ERROR_RED
          : COLORS.TEXT_LIGHT_PRIMARY_WHITE,
      '&:hover': {
        backgroundColor:
          variant === 'contained'
            ? COLORS.ERROR_RED
            : COLORS.TEXT_LIGHT_PRIMARY_WHITE,
        color:
          variant === 'outlined'
            ? COLORS.ERROR_RED
            : COLORS.TEXT_LIGHT_PRIMARY_WHITE,
        boxShadow: `inset 0 0 0 2px ${COLORS.ERROR_RED}`,
      },
    }
  }

  return (
    <Button
      {...props}
      sx={{
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '24px',
        padding:
          size === 'small'
            ? `${SPACING._05} ${SPACING._15}`
            : `${SPACING._075} ${SPACING._25}`,
        ...colorSx,
        ...(noCaps && { textTransform: 'none' }),
        ...props.sx,
      }}
    >
      {children}
    </Button>
  )
}
