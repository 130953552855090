import {
  LogoThemeParams,
  MuiThemeParams,
  createMemberPortalTheme,
  getSharedColorsBetwenAmazonAndScriptaOrMaybeOtherThemes,
  SHARED_BLACK,
  SHARED_ERROR_RED,
} from './scripta-theme'
import { CustomColors } from './theme-extender'
import { GRADIENT_LONG_SCRIPTA } from 'src/app/styles/theme-config-scripta'

const NAME = 'amazon-orange'
const PRIMARY_BRAND = '#FF9900'
const PRIMARY_10 = '#FFE5B4'
const PRIMARY_20 = '#FFD18A'
const PRIMARY_40 = '#FFC26A'
const PRIMARY_20_ILLUSTRATIONS = '#FFD18A'

// const SECONDARY_BRAND = '#2D8DFF'
// const SECONDARY_BRAND_10 = '#EAF4FF'
// //THIS IS AKA SECONDARY_90
// const SECONDARY_BRAND_ACTIVE = '#006EF5'
// //AKA SECONDARY_90 in table

const SECONDARY_BRAND = '#008080' // Changed to teal
const SECONDARY_BRAND_10 = '#E6F3F3' // Lighter teal
const SECONDARY_BRAND_ACTIVE = '#006666' // Darker teal

const GRADIENT_LONG = `
linear-gradient(
  to right,
  #FF9900 0%,
  #F39C12 10%,
  #E67E22 21%,
  #D35400 32%,
  #C0392B 45%,
  #A04000 62%,
  #922B21 75%,
  #873600 90%,
  #7B241C 100%
)`

const FONT_NAMES = ['Proxima Nova', 'Roboto', 'Sans-Serif']

const ORANGE_BUTTONS = {
  BUTTON_PRIMARY_BACKGROUND: '#FF9900',
  BUTTON_PRIMARY_BACKGROUND_ACTIVE: '#E67E22',
  // BUTTON_PRIMARY_TEXT: '#FFFFFF',
  // BUTTON_PRIMARY_TEXT_ACTIVE: '#FFFFFF',

  BUTTON_PRIMARY_TEXT: '#000000',
  BUTTON_PRIMARY_TEXT_ACTIVE: '#000000',

  BUTTON_SECONDARY_BACKGROUND: '#FFFFFF',
  BUTTON_SECONDARY_BACKGROUND_ACTIVE: '#FFFFFF',

  // BUTTON_SECONDARY_TEXT: '#FF9900',
  // BUTTON_SECONDARY_TEXT_ACTIVE: '#FF9900',

  BUTTON_SECONDARY_TEXT: '#000000',
  BUTTON_SECONDARY_TEXT_ACTIVE: '#000000',
}

export function getAmazonOrangeTheme() {
  const muiParams: MuiThemeParams = {
    colors: {
      PRIMARY_BRAND: PRIMARY_BRAND,
      SECONDARY_BRAND: SECONDARY_BRAND,
      SECONDARY_BRAND_ACTIVE: SECONDARY_BRAND_ACTIVE,
      TEXT: SHARED_BLACK,
      ERROR: SHARED_ERROR_RED,
      //TODO - do we use this mui info color?
      INFO: SHARED_BLACK,
    },
    fontNames: FONT_NAMES,
  }

  const customColors: CustomColors = {
    PRIMARY_BRAND: PRIMARY_BRAND,
    PRIMARY_10: PRIMARY_10,
    PRIMARY_20: PRIMARY_20,
    PRIMARY_20_ILLUSTRATIONS: PRIMARY_20_ILLUSTRATIONS,
    PRIMARY_40: PRIMARY_40,

    DESKTOP_RIGHT_CONTENT_PRIMARY_TO_USE: PRIMARY_BRAND,

    RING_SAVINGS_BACKGROUND: PRIMARY_40,
    RING_SAVINGS_BORDER: PRIMARY_40,
    RING_SAVINGS_TEXT: PRIMARY_BRAND,

    DESKTOP_LEFT_CONTAINER_BG: PRIMARY_20,

    DESKTOP_LEFT_MENU_BG: PRIMARY_10,
    DESKTOP_LEFT_MENU_TEXT: PRIMARY_BRAND,
    //dedicated for right desktop menu
    DESKTOP_LEFT_MENU_ACTIVE_BG: PRIMARY_BRAND,
    DESKTOP_LEFT_MENU_ACTIVE_TEXT: '#FFFFFF',

    BORDER_BRAND: PRIMARY_BRAND,
    ...ORANGE_BUTTONS,

    BACKGROUND_SECONDARY: SECONDARY_BRAND,
    BACKGROUND_SECONDARY_10: SECONDARY_BRAND_10,

    BACKGROUND_SECONDARY_ACTIVE: SECONDARY_BRAND_ACTIVE,

    TEXT_BRAND: PRIMARY_BRAND,
    TEXT_SECONDARY: SECONDARY_BRAND,

    BACKGROUND_GRADIENT: GRADIENT_LONG_SCRIPTA,
    ...getSharedColorsBetwenAmazonAndScriptaOrMaybeOtherThemes(),
  }
  const logoParams: LogoThemeParams = {
    whiteOnDarkLogoUrl:
      'https://scripta-public-assets.s3.amazonaws.com/memberapp/temp_white_az.png',
    darkOnWhiteLogoUrl:
      'https://scripta-public-assets.s3.amazonaws.com/memberapp/temp_dark_az.svg',
  }

  return createMemberPortalTheme(NAME, muiParams, customColors, logoParams)
}
