import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
// import { usePortalStyles } from '../styles/portal-styles'
import { useCommonRadioStyles } from './radio-styles'

export function PasswordHints() {
  const classes = useCommonRadioStyles()
  const { t } = useSTranslate('settings')
  return (
    <Grid container>
      <Grid item xs={12} sx={{ paddingTop: '20px' }}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            textAlign: 'center',
            lineHeight: 'normal',
          }}
        >
          {t('passwordHintsHeader')}
        </Typography>
      </Grid>
    </Grid>
  )
}
