import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { useMemberAppContext } from '../MemberAppContext'
import { REG_ROUTE_LOGIN } from '../registration/RegistrationSubRoutes'

export function JwtExpiredHandler() {
  const {
    clearJwt,
    setCurrentMemberProfile,
    setSavingReport,
    setLogoutMsg,
  } = useMemberAppContext()
  const [renderRedirect, setRenderRedirect] = useState(false)

  useEffect(() => {
    clearJwt()
    setCurrentMemberProfile(undefined)
    setSavingReport(undefined)
    setLogoutMsg('Your session has expired. Please login again.')
    setRenderRedirect(true)
  }, [])

  if (renderRedirect) {
    return <Redirect to={REG_ROUTE_LOGIN} />
  }

  return null
}
