import { AnimateChangeInHeight } from '../shared/AnimateChangeInHeight'
import { useRegistration } from './RegistrationContext'

export default function RegistrationAnimateChangeInHeight({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  const { isContainerHeightAnimationActive } = useRegistration()

  if (isContainerHeightAnimationActive) {
    return (
      <AnimateChangeInHeight className={className}>
        {children}
      </AnimateChangeInHeight>
    )
  }

  return <>{children}</>
}
